@import "../../common/shared";

.modal-body {
  .icons-container,
  .selected-icons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    svg {
      cursor: pointer;
      width: 42px;
      height: 42px;
    }
  }

  .icons-container {
    margin-bottom: 54px;
  }
}
