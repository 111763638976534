/**
 * Material icon styling.
 */
.material-icons {
  -webkit-user-select: none;
  user-select: none;
}

/**
 * Popover styling.
 */
.popover {
  background-color: #475055;
}

.popover-body {
  color: #fff;
}

.popover-header {
  background-color: #4bb3d8;
  color: #fff;
}

.popover-arrow::before {
  border-top-color: #475055 !important;
}

.popover-arrow::after {
  border-top-color: #475055 !important;
}

/**
 * Generic table styling.
 */
.center-table-td, .center-table-th {
  text-align: center !important;
}

.center-table-td {
  vertical-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.sf-loader-position {
  position: absolute;
  right: 1%;
  top: 90px;
}

.disable-none {
  display: none;
}

.loader-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.event-line {
  background-color: #988dec;
  margin: 11px 10px;
  width: calc(100% - 20px);
  height: 2px;
}
.event-line > div {
  position: relative;
}
.event-line .event-dot {
  position: absolute;
  width: 18px;
  height: 18px;
  background-color: #988dec;
  border-radius: 9px;
  text-align: center;
  font-size: small;
  top: -9px;
  margin-left: -9px;
  float: left;
}
.event-line .event-dot-empty {
  background: none;
}
.event-line .event-dot-empty > div {
  background-color: #988dec;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-top: 5px;
  margin-left: 5px;
}
.event-line .event-dot-menu {
  background-color: #272e34;
  color: #fff;
}
.event-line .event-dot-menu .react-contextmenu-item--selected {
  background: none;
}
.event-line .event-dot-menu button {
  width: 100%;
}
.event-line .unresolved {
  background-color: orange;
}
.event-line .selected {
  background-color: #4bb3d8;
}
.event-line .event-line-unresolved {
  position: absolute;
  background-color: orange;
  height: 2px;
  float: left;
}
.event-line .event-line-highlight {
  position: absolute;
  display: inline;
  background-color: #4bb3d8;
  top: 15px;
}
.event-line .event-line-highlight .event-start {
  position: absolute;
  left: -5px;
  background-color: #4bb3d8;
  width: 10px;
  border-radius: 5px;
  height: 10px;
  margin-top: -5px;
}
.event-line .event-line-highlight .event-end {
  position: absolute;
  right: -5px;
  background-color: #4bb3d8;
  width: 10px;
  border-radius: 5px;
  height: 10px;
  margin-top: -5px;
}

.event-dot-tooltip {
  color: #fff;
  max-width: 50%;
  max-height: 50%;
  pointer-events: none;
}
.event-dot-tooltip .tooltip-inner {
  max-width: 800px;
  max-height: 50%;
}
.event-dot-tooltip p {
  margin: 0;
  padding: 0;
  color: #fff;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.event-dot-tooltip p strong {
  color: orange;
}
.event-dot-tooltip .title .path {
  color: #4bb3d8;
  margin-right: 100px;
}
.event-dot-tooltip .title .date {
  float: right;
}

.event-dot-popover {
  width: 50%;
  max-width: 800px;
  color: #fff;
}
.event-dot-popover > .popover {
  width: 50%;
  max-width: 800px;
  color: #fff;
}
.event-dot-popover .popover-header {
  background-color: #000;
  color: #fff;
  max-height: 100px;
  vertical-align: middle;
  padding: 20px;
}
.event-dot-popover .popover-header .event-actions {
  float: right;
  margin-top: -10px;
}
.event-dot-popover .popover-header .event-actions a {
  float: left;
  padding-right: 20px;
}
.event-dot-popover .popover-header .event-actions .material-icons {
  color: #fff;
  font-size: 30pt;
}
.event-dot-popover .popover-body {
  padding: 0;
  background-color: #272e34;
  color: #fff;
  max-height: calc(100vh - 400px);
  overflow: auto;
}
.event-dot-popover .popover-body > div {
  border-bottom: #fff solid 1px;
}
.event-dot-popover .popover-body p {
  margin: 3px 0;
  max-height: 50px;
  text-overflow: ellipsis;
}
.event-dot-popover .popover-body p strong {
  color: orange;
}
.event-dot-popover .popover-body .event {
  padding: 5px 10px;
}
.event-dot-popover .popover-body .description {
  color: #dae0e7;
  overflow: auto;
}
.event-dot-popover .popover-body .event-actions {
  float: right;
}
.event-dot-popover .popover-body .path {
  font-weight: bold;
  color: #4bb3d8;
}

