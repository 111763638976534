/**
 * Material icon styling.
 */
.material-icons {
  -webkit-user-select: none;
  user-select: none;
}

/**
 * Popover styling.
 */
.popover {
  background-color: #475055;
}

.popover-body {
  color: #fff;
}

.popover-header {
  background-color: #4bb3d8;
  color: #fff;
}

.popover-arrow::before {
  border-top-color: #475055 !important;
}

.popover-arrow::after {
  border-top-color: #475055 !important;
}

/**
 * Generic table styling.
 */
.center-table-td, .center-table-th {
  text-align: center !important;
}

.center-table-td {
  vertical-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.sf-loader-position {
  position: absolute;
  right: 1%;
  top: 90px;
}

.disable-none {
  display: none;
}

.loader-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.card {
  color: #fff;
  background-color: #272e34;
}

.future-date input {
  color: #0088fe;
  font-weight: 600;
}

.future-date input:focus {
  color: #0088fe;
  font-weight: 600;
}

.project-side-panel {
  margin-left: -15px;
  margin-right: 0;
  padding-right: 0;
  height: 100%;
}
.project-side-panel .side-panel {
  padding: 20px;
}
.project-side-panel .side-panel .project-reports button {
  background-color: #fff;
  border-color: #fff;
  color: #4bb3d8;
}
.project-side-panel .side-panel .project-reports .date-range-menu {
  width: auto;
  height: auto;
}
.project-side-panel .side-panel .project-reports .date-range-menu .custom-date-range {
  width: 325px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.project-side-panel .side-panel .project-reports .date-range-menu .custom-date-range .custom-date {
  width: 100%;
  height: 250px;
  padding-right: 5px;
}
.project-side-panel .side-panel .project-reports .date-range-menu .custom-date-range .custom-date th {
  text-align: center;
}
.project-side-panel .side-panel .project-reports .date-range-menu .custom-date-range .custom-date .rdt .rdtPicker {
  width: 100%;
}
.project-side-panel .side-panel .project-reports .date-range-menu .custom-date-range .date-range-button {
  margin: 0;
}
.project-side-panel .side-panel .project-reports .report {
  padding: 5px;
  clear: both;
}
.project-side-panel .side-panel .project-reports .report .document {
  min-height: 20px;
  line-height: 42.5px;
}
.project-side-panel .project-side-panel-content {
  background-color: #4bb3d8;
  color: #fff;
  border-radius: 10px;
  padding: 20px;
}
.project-side-panel .project-side-panel-content table {
  width: 100%;
}
.project-side-panel .project-side-panel-content a {
  color: #fff;
}
.project-side-panel .project-side-panel-content > div {
  padding-bottom: 20px;
}
.project-side-panel .project-side-panel-content .download {
  border-color: #fff;
  background-color: #fff;
  color: #4bb3d8;
  margin-bottom: 2px;
  width: 100%;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.project-side-panel .project-side-panel-content .download .material-icons {
  color: #4bb3d8;
}
.project-side-panel .project-side-panel-content .missing {
  color: #e22006;
}
.project-side-panel .project-side-panel-content .payments {
  width: 100%;
}
.project-side-panel .project-side-panel-content .payments .past {
  color: #dae0e7;
}

.project-content {
  height: calc(100% - 20px);
  width: 100%;
  overflow: auto;
  padding-left: 20px;
  padding-right: 20px;
}
.project-content .ecogy-form {
  margin-top: 0;
}

.project-finance > div > div {
  margin: 20px 0;
}
.project-finance .ecogy-form {
  margin-bottom: 0;
}

.project-tabs {
  height: 100%;
}
.project-tabs .tab-content {
  height: calc(100% - 58px);
  overflow-x: hidden;
  overflow-y: auto;
}
.project-tabs .tab-content .active {
  height: 100%;
  margin-left: calc(10px * 2);
}
.project-tabs .ecogy-form {
  margin-top: 0;
}
.project-tabs .new-button {
  background-color: #4bb3d8;
  border-color: #4bb3d8;
  float: right;
}
.project-tabs .nav-tabs {
  border-bottom: 1px solid #1f1f1f;
  margin-bottom: 20px;
}
.project-tabs .nav-tabs .nav-item {
  cursor: pointer;
}
.project-tabs .nav-tabs .nav-item a {
  color: #fff;
  background-color: #353c44;
  border-bottom: none;
}
.project-tabs .nav-tabs .nav-item a.active {
  color: #4bb3d8;
  font-weight: bold;
  background-color: #272e34;
  border: 1px solid #4bb3d8;
  border-bottom: none;
  margin-left: 0px;
}
.project-tabs .nav-tabs .nav-item a:hover {
  border: 1px solid #4bb3d8;
  border-bottom: none;
}
.project-tabs .nav-tabs .new a {
  color: #fff;
  background-color: #4bb3d8;
}
.project-tabs .nav-tabs .new a.active {
  color: #4bb3d8;
  background-color: #fff;
}
.project-tabs .nav-tabs .verified {
  top: 3px;
  margin: 0 -5px 0 5px;
}
.project-tabs .new-card {
  color: #fff;
  background-color: #4bb3d8;
}
.project-tabs .new-card .loading {
  background-color: #fff;
}
.project-tabs .new-card .icon-button {
  border-color: #fff;
}
.project-tabs .new-card .icon-button .material-icons {
  color: #fff;
}
.project-tabs .new-card .icon-button.btn-danger {
  border-color: #e22006;
}
.project-tabs .new-card .icon-button.btn-danger .material-icons {
  color: #e22006;
}
.project-tabs .address-line {
  display: block;
}
.project-tabs .project-tab-content-area {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.project-tabs .project-tabs .tab-content {
  overflow-x: hidden;
  overflow-y: auto;
}
.project-tabs .project-tabs .project-site .project-site-system {
  background-color: #fff;
}
.project-tabs .project-site-tabs .tab-content {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.project-tabs .project-site-tabs .tab-content .ecogy-form-buttons {
  margin-left: calc(-10px * 2 * 2);
}
.project-tabs .site-system-tabs .tab-content {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.project-tabs .site-system-tabs .tab-content .ecogy-form-buttons {
  margin-left: calc(-10px * 2 * 3);
}
.project-tabs .field-group .card {
  padding: 20px;
}
.project-tabs .field-group .field-group-title {
  font-weight: bold;
}
.project-tabs .field-group .field-group-toggle {
  text-align: right;
  float: right;
}
.project-tabs .card {
  margin-bottom: 10px;
}
.project-tabs .project-finance-category {
  margin-bottom: 20px;
}

.project-table .mutli-line-cell {
  text-transform: capitalize;
  white-space: pre-wrap;
}
.project-table .project-table-milestones-container {
  padding: 0.5rem 0;
  height: 4rem;
}
.project-table .project-table-milestones-container .project-table-milestones {
  display: flex;
  justify-content: space-between;
}

table thead.sticky th {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #fff;
}
table td {
  color: #fff;
}

.project-contacts {
  padding: 10px;
  background-color: #1f1f1f;
}
.project-contacts h2,
.project-contacts h3,
.project-contacts h4 {
  color: #4bb3d8;
  text-align: center;
}
.project-contacts h5 {
  text-align: center;
}
.project-contacts .site-contacts {
  margin: 10px;
  padding: 10px;
  background-color: #272e34;
}
.project-contacts .site-contacts .system-contacts {
  margin: 10px;
  padding: 10px;
  background-color: #475055;
}

.no-contacts {
  text-align: center;
}

legend[id$=_ecogyContact__title] {
  display: none;
}

.irradiance-input {
  display: inline-block;
  width: 50%;
}
.irradiance-input > * {
  float: left;
}
.irradiance-input input {
  width: 70%;
}
.irradiance-input .units {
  width: 30%;
  padding-left: 10px;
  padding-top: 10px;
}

.verified-in-table {
  text-align: center;
}

.field-with-help {
  display: flex;
  align-items: center;
}

