.listbox-container {
  display: flex;
  width: 100%;
}

.listbox-left {
  flex: 1;
}

.listbox-middle {
  flex: 0;
}

.listbox-right {
  flex: 1;
}

.listbox-table {
  background-color: #353c44;
  padding: 1px;
}

.mid-container {
  height: 100%;
  padding: 0;
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.mid-button {
  margin-right: 0;
}

.listbox-search {
  font-size: 14px;
}

.td-field {
  min-width: 5em;
}
