@import "../common/shared";

.EcogyTerminal {
  .terminal-header {
    background-color: $ecogy-color-info;
    color: $ecogy-white;
    font-size: large;
    text-align: center;

    a {
      color: $ecogy-white;
    }

    label {
      color: $ecogy-palegrey;
      margin: 0;
      padding: 5px 10px;
    }
  }

  .terminal-loading {
    color: $ecogy-color-info;
    text-align: center;
  }

  .ecogy-terminal {
    height: 100%;
    width: 100%;

    .alert {
      position: absolute;
      bottom: 50px;
      width: 100%;
    }

    .footer {
      background-color: $ecogy-black;
      position: absolute;
      bottom: 0;
      height: 50px;
      width: 100%;
      padding: 5px;
    }
  }

  .solarnode-login {
    height: 100vh;
    margin: auto;
    padding-top: 100px;
    width: 400px;
  }

  .centre-form {
    display: flex;
    align-items: center;
  }

  .input-wrapper {
    margin-right: 8px;
  }
}
