/**
 * Material icon styling.
 */
.material-icons {
  -webkit-user-select: none;
  user-select: none;
}

/**
 * Popover styling.
 */
.popover {
  background-color: #475055;
}

.popover-body {
  color: #fff;
}

.popover-header {
  background-color: #4bb3d8;
  color: #fff;
}

.popover-arrow::before {
  border-top-color: #475055 !important;
}

.popover-arrow::after {
  border-top-color: #475055 !important;
}

/**
 * Generic table styling.
 */
.center-table-td, .center-table-th {
  text-align: center !important;
}

.center-table-td {
  vertical-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.sf-loader-position {
  position: absolute;
  right: 1%;
  top: 90px;
}

.disable-none {
  display: none;
}

.loader-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.Module .module-content .ecogy-form .ecogy-form-buttons .button-section button {
  float: right;
  margin-right: 10px;
}

.ecogy-form {
  margin-top: 20px;
  width: 100%;
  padding-bottom: 20px;
}
.ecogy-form .field-message {
  padding-top: 7px;
  font-style: italic;
}
.ecogy-form a {
  color: #4bb3d8;
}
.ecogy-form button:disabled {
  display: none;
}
.ecogy-form .disabled {
  background-color: #272e34;
  color: #aaaeb3;
  border-color: #1f1f1f;
}
.ecogy-form select:disabled,
.ecogy-form input:disabled,
.ecogy-form textarea:disabled {
  background-color: #272e34;
  color: #aaaeb3;
  border-color: #1f1f1f;
}
.ecogy-form input[type=date]::-webkit-calendar-picker-indicator {
  margin-left: -10px;
}
.ecogy-form input[type=date] {
  flex-direction: row-reverse;
}
.ecogy-form .ecogy-form-buttons {
  position: fixed;
  bottom: 0;
  width: calc(100% - 190px - 10px * 2);
  height: calc(50px);
  background-color: #1f1f1f;
  z-index: 100;
  margin-left: -20px;
}
.ecogy-form .ecogy-form-buttons .button-section {
  position: fixed;
  width: calc(100% - 190px);
  bottom: 10px;
  right: 0;
}
.ecogy-form .ecogy-form-buttons .button-section button {
  float: right;
  margin-right: 10px;
}
.ecogy-form .ecogy-form-buttons .audit-section {
  margin-bottom: 20px;
}
.ecogy-form .ecogy-form-buttons .audit-section > * {
  vertical-align: middle;
  display: inline;
}
.ecogy-form .ecogy-form-buttons .message-section {
  padding: 0 20px;
}
.ecogy-form .ecogy-form-buttons .message-section button:hover:not(disabled, .disabled) {
  border: none;
}
.ecogy-form .ecogy-button {
  color: #fff;
  background-color: #4bb3d8;
  border-color: #4bb3d8;
}
.ecogy-form .icon-button .material-icons {
  transform: scale(1.25);
  font-size: 24px;
  padding: 0 5px;
  top: 4px;
}
.ecogy-form .btn {
  margin-right: 10px;
}
.ecogy-form .btn .material-icons {
  position: relative;
  top: 2px;
  padding-right: 5px;
  font-size: 16px;
  color: #fff;
}
.ecogy-form .btn:last-child {
  margin-right: 0;
}
.ecogy-form input[readonly] {
  border: none;
  background-color: #272e34;
  color: #fff;
}
.ecogy-form .readonly-label {
  padding-top: 0.75rem;
}
.ecogy-form .readonly-field {
  padding: 0.75rem;
  font-size: 1rem;
}
.ecogy-form .timezone-picker {
  width: 100%;
  z-index: 100;
}
.ecogy-form .timezone-picker a {
  background-color: #fff;
  color: #000;
}
.ecogy-form .timezone-picker a mark {
  padding: 0;
}
.ecogy-form .timezone-picker ul {
  background-color: #fff;
  z-index: 100;
}
.ecogy-form .timezone-picker input {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
}
.ecogy-form form .date-warning,
.ecogy-form form .size-warning {
  padding: 5px 7px 10px;
  transform: scale(1.5);
  color: orange;
}
.ecogy-form form .copy-icon {
  cursor: pointer;
  transform: scale(1.5);
  display: flex;
  align-items: center;
  margin-left: 16px;
  padding-top: 8px;
}
.ecogy-form form .multi-system-warning {
  cursor: help;
  padding: 0.55rem 5px 0 0;
  margin-left: -1.5rem;
  transform: scale(2);
  color: orange;
}
.ecogy-form form .btn {
  margin-right: 10px;
}
.ecogy-form form .form-group {
  margin-bottom: 0.25rem;
  clear: both;
}
.ecogy-form form legend {
  font-size: inherit;
}
.ecogy-form form .right-column {
  display: flex;
  float: right;
  clear: none;
  padding-right: 10px;
  padding-left: 10px;
  width: 50%;
}
.ecogy-form form .left-column {
  display: flex;
  float: left;
  clear: none;
  width: 50%;
  padding-right: 10px;
  padding-left: 10px;
}
.ecogy-form form .checkboxes {
  width: 800px;
}
.ecogy-form form .checkboxes .checkbox-inline {
  width: 25%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ecogy-form form .checkboxes input {
  margin-right: 5px;
}
.ecogy-form form .field-array .search-hide {
  display: none;
}
.ecogy-form form .field-array .collapse-show {
  display: none;
}
.ecogy-form form .field-array .collapse-hide {
  display: block;
}
.ecogy-form form .field-array .collapsed .field-array-entry-content {
  display: none;
}
.ecogy-form form .field-array .collapsed .collapse-show {
  display: block;
}
.ecogy-form form .field-array .collapsed .collapse-hide {
  display: none;
}
.ecogy-form form .field-array .field-array-of-object .field-array-entry {
  background-color: #272e34;
  border: 2px solid #000;
  border-radius: 5px;
  margin-bottom: 5px;
}
.ecogy-form form .field-array .field-array-of-object .field-array-entry > .form-group {
  margin: 5px;
}
.ecogy-form form .field-array .field-array-of-object .new-entry .field-array-entry-collapse {
  background-color: #4bb3d8;
}
.ecogy-form form .field-array .field-array-entry-collapse {
  background-color: #000;
  max-height: 45px;
  overflow: hidden;
}
.ecogy-form form .field-array .field-array-entry-collapse h1,
.ecogy-form form .field-array .field-array-entry-collapse h2,
.ecogy-form form .field-array .field-array-entry-collapse h3,
.ecogy-form form .field-array .field-array-entry-collapse h4,
.ecogy-form form .field-array .field-array-entry-collapse h5 {
  display: inline-block;
}
.ecogy-form form .field-array .field-array-entry-collapse aside {
  color: #aaaeb3;
  display: inline-block;
}
.ecogy-form form .field-array .field-array-add {
  background-color: #272e34;
  border: 2px solid #000;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
  display: flex;
}
.ecogy-form form .field-array .field-array-buttons {
  display: flex;
  background-color: #1f1f1f;
  padding: 5px;
}
.ecogy-form form .field-array .field-object-inline {
  display: inline-block;
  margin-bottom: 0;
}
.ecogy-form form .field-array .field-inline {
  vertical-align: top;
  display: inline-block;
  margin: 3px 10px 3px 0;
}
.ecogy-form form .field-array .field-inline .form-control {
  display: inline-block;
}
.ecogy-form form .field-array .field-inline div.property-wrapper {
  display: inline-block;
}
.ecogy-form form .field-array .field-array-inline {
  margin-bottom: 0;
  position: relative;
}
.ecogy-form form .field-array .field-array-inline > div {
  display: inline-block;
}
.ecogy-form form .field-array .field-array-inline .form-control {
  display: inline-block;
}
.ecogy-form form .field-array .field-array-inline div.property-wrapper {
  display: inline-block;
}
.ecogy-form form .field-array .field-array-inline .field-array-field-buttons {
  display: inline-flex;
  padding-left: 10px;
  position: absolute;
  bottom: 8px;
}
.ecogy-form form .field-array .field-array-inline .field-array-field-buttons button,
.ecogy-form form .field-array .field-array-inline .field-array-field-buttons .btn:last-child {
  margin-right: 10px;
}
.ecogy-form form .field-array .field-array-inline .field-array-object-buttons {
  display: flex;
  background-color: #1f1f1f;
  padding: 5px;
}
.ecogy-form form .field-array .field-array-inline .field-array-object-buttons button,
.ecogy-form form .field-array .field-array-inline .field-array-object-buttons .btn:last-child {
  margin-right: 10px;
}
.ecogy-form form .field-array .field-array-inline hr {
  margin: 5px;
  clear: both;
}
.ecogy-form form .inline-properties .property-wrapper {
  display: inline-block;
  width: 8.33%;
}
.ecogy-form form .inline-form-group {
  display: flex;
}
.ecogy-form form .inline-form-group label {
  padding-top: 10px;
  padding-right: 5px;
  font-size: 1rem;
}
.ecogy-form form .inline-form-group .inline-form-control {
  display: inline-block;
  width: 100%;
}
.ecogy-form form .panel {
  margin-bottom: 10px;
}
.ecogy-form form .panel-danger .panel-title {
  color: #e22006;
}
.ecogy-form form.split .form-group {
  clear: none;
}
.ecogy-form .alert {
  margin-top: 20px;
}
.ecogy-form .alert ul {
  margin-bottom: 0;
}
.ecogy-form .ecogy-form-panels {
  background-color: #dae0e7;
  padding: 20px;
}
.ecogy-form .hidden-fields-form-template {
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
}
.ecogy-form .hidden-fields-form-template label {
  padding-right: 0;
  padding-bottom: 0;
}
.ecogy-form .hidden-fields-form-template .hide-toggle {
  height: 38px;
  width: 2rem;
  border-radius: 0;
  margin-left: -1.5rem;
  align-self: flex-start;
  z-index: 3;
}
.ecogy-form .accordion-button {
  color: #4bb3d8;
  background-color: #000;
  border: 1px solid black;
  padding: 1rem;
  background-image: none;
}
.ecogy-form .accordion-button .record-form__required-hidden {
  margin-top: 0.25rem;
  margin-bottom: 0;
  padding: 0.5rem;
}
.ecogy-form .accordion-button .record-form__required-hidden i {
  padding-right: 0.25rem;
  color: #ec6732;
}
.ecogy-form .accordion-button::after {
  background-image: none;
}
.ecogy-form .accordion-button:focus {
  z-index: 3;
  box-shadow: none;
}
.ecogy-form .accordion-body {
  padding-left: 0;
  padding-right: 0;
}
.ecogy-form .accordion-item {
  background-color: transparent;
}
.ecogy-form .error {
  color: #e22006;
}
.ecogy-form .strike-through * {
  text-decoration: line-through;
}

.ecogy-form-card {
  height: 100%;
}

.ecogy-form-panels {
  margin-top: 20px;
}
.ecogy-form-panels .loading {
  margin-top: 55px;
}
.ecogy-form-panels .card {
  min-height: 350px;
}

.info.alert-info {
  padding-left: 40px;
}
.info.alert-info .material-icons {
  color: #4bb3d8;
  position: absolute;
  top: 10px;
  left: 10px;
}

.copy-field-col-flex {
  display: flex;
}

.copy-field-col-fullwidth {
  width: 100%;
}

