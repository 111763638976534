@import "../../../common/shared";

.capitalize-buttons {
  button {
    text-transform: capitalize;
  }
}

.Module .module-content {
  .item-details {
    .event-content {
      height: calc(
        100vh - #{$height-header} - #{$height-content-header} - #{$height-form-controls} - #{$height-form-header}
      );
      width: 100%;
      overflow: auto;

      .ecogy-form {
        margin-top: 0;
      }
    }

    .event-priority-1 {
      background-color: $ecogy-color-blue;
    }

    .event-priority-5 {
      background-color: $ecogy-color-green;
    }

    .event-priority-10 {
      background-color: $ecogy-color-yellow;
    }

    .event-due {
      background-color: $ecogy-status-red;
    }

    .event-unresolved {
      background-color: $ecogy-status-orange;
      color: $ecogy-white;
    }
  }

  .events {
    tr.selected {
      td {
        color: $ecogy-color-info;

        .event-time-local {
          color: $ecogy-color-info;
        }
      }

      .file-type {
        border: 1px solid $ecogy-white;
      }
    }

    tr {
      td.event-priority {
        background-color: $ecogy-color-blue;
        color: $ecogy-white;
      }

      td.event-priority-5 {
        background-color: $ecogy-color-green;
        color: $ecogy-white;
      }

      td.event-priority-10 {
        background-color: $ecogy-color-yellow;
        color: $ecogy-white;
      }
    }

    .due {
      background-color: $ecogy-status-red;
    }

    .unresolved {
      background-color: $ecogy-status-orange;
      color: $ecogy-white;
    }

    table.events-table {
      .event-cause {
        width: 30%;

        a {
          color: $ecogy-color-controls;
        }
      }

      td.event-type {
        // width: 10%;
        text-transform: capitalize;
      }

      td.selected {
        border-radius: 0;
      }

      td {
        .btn-link {
          color: $ecogy-white;
        }
      }

      td.resolve {
        text-align: center;
        width: 1px;
      }

      tr.selected {
        a {
          color: $ecogy-white;
        }
      }

      .event-type-energy {
        color: $ecogy-color-green;
      }

      .event-type-finance {
        color: $ecogy-color-blue;
      }

      .event-type-other {
        color: $ecogy-color-yellow;
      }

      .event-time-local {
        color: $ecogy-palegrey;
        float: right;
      }
    }
  }

  .event-tooltip {
    // background-color: $ecogy-darkgrey;
    color: $ecogy-white;
    width: 50%;
    max-width: 600px;
    max-height: 50%;

    .tooltip-inner {
      max-width: 800px;
      max-height: 50%;
    }

    p {
      margin: 0;
      padding: 0;
      color: $ecogy-white;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .event-description {
      padding-bottom: 10px;
    }

    .note {
      color: $ecogy-palegrey;

      em {
        color: $ecogy-color-info;
      }
    }
  }

  .errors {
    .panel-title {
      color: $ecogy-status-red;
    }
  }

  .rdt {
    .rdt-input-container {
      display: flex;
      align-items: center;

      .rdt-input-clear-button {
        right: 0;
        position: absolute;
        height: 2rem;
        width: 2rem;
        padding: 0;
        border-radius: 50%;
        border-color: transparent;
        background-color: transparent;

        i {
          padding: 0;
          color: black;
        }
      }
    }
  }
}
