@import "../common/shared";

$height-profile-header: 70px;

.user-profile {
  padding: 10px;

  h1 {
    text-align: center;
  }

  .message {
    text-align: center;
    font-size: large;
  }

  .user-notifications {
    overflow: auto;
    height: calc(100vh - #{$height-header} - #{$height-profile-header});
  }

  .notification-content {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 60px);
  }

  .notification-footer {
    position: absolute;
    bottom: 0;
    padding-bottom: 10px;
    padding-top: 10px;
    width: calc(50% - 20px);
  }

  .userlist-content {
    height: calc(100vh - 70px - 56px);
    overflow: auto;
  }
}
