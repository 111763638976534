/**
 * Material icon styling.
 */
.material-icons {
  -webkit-user-select: none;
  user-select: none;
}

/**
 * Popover styling.
 */
.popover {
  background-color: #475055;
}

.popover-body {
  color: #fff;
}

.popover-header {
  background-color: #4bb3d8;
  color: #fff;
}

.popover-arrow::before {
  border-top-color: #475055 !important;
}

.popover-arrow::after {
  border-top-color: #475055 !important;
}

/**
 * Generic table styling.
 */
.center-table-td, .center-table-th {
  text-align: center !important;
}

.center-table-td {
  vertical-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.sf-loader-position {
  position: absolute;
  right: 1%;
  top: 90px;
}

.disable-none {
  display: none;
}

.loader-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.App .app-mode {
  position: absolute;
  left: 190px;
  top: 20px;
}
.App .app-mode .nav-item {
  color: #fff;
  background-color: #353c44;
  border: #1f1f1f 1px solid;
  border-bottom: #272e34 1px solid;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
}
.App .app-mode .nav-item a {
  color: #fff;
  background-color: #353c44;
  border: #1f1f1f 1px solid;
  border-bottom: #272e34 1px solid;
}
.App .app-mode .nav-item a:hover {
  border: #4bb3d8 1px solid;
  border-bottom: none;
}
.App .app-mode .nav-item span.nav-link {
  color: #aaaeb3;
}
.App .app-mode .nav-item span.nav-link:hover {
  border: #272e34 1px solid;
}
.App .app-mode .selected a {
  color: #4bb3d8;
  background-color: #272e34;
  font-weight: bold;
}

.btn-ecogy {
  color: #fff;
  background-color: #4bb3d8;
  border-color: #4bb3d8;
}

.App-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1200px) {
  .marquee {
    display: none;
  }
  .maquee div {
    display: none;
  }
}
.App-stage-dev .App-header {
  background-color: #e22006;
}

.App-stage-int .App-header {
  background-color: orange;
}

.App-stage-test .App-header {
  background-color: #4caf50;
}

.App-stage-prod .App-header {
  background-color: #1f1f1f;
}

.App-header {
  background-color: #4bb3d8;
  height: 60px;
  color: white;
  position: relative;
}
.App-header .logo {
  height: 50px;
  max-height: 100px;
  width: auto;
  max-width: 100%;
  margin-top: 5px;
}
.App-header .controls {
  float: right;
  margin-top: 10px;
}
.App-header .controls .app-update {
  float: left;
  margin: 3px 10px 0 0;
  color: orange;
}
.App-header .controls .app-update button,
.App-header .controls .app-update button:hover,
.App-header .controls .app-update button:not(disabled, .disabled),
.App-header .controls .app-update button:focus {
  background-color: orange;
  color: #fff;
  border: solid 2px #fff;
}
.App-header .controls .stage {
  float: left;
  padding-right: 20px;
  font-size: 1rem;
  margin-top: 11px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
}
.App-header .controls .marquee {
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}
.App-header .controls .marquee div {
  display: inline-block;
  animation: marquee 24s linear infinite;
}
@keyframes marquee {
  0% {
    transform: translateX(600px);
  }
  100% {
    transform: translateX(-100%);
  }
}
.App-header .controls .clock {
  float: left;
  padding-right: 20px;
  font-size: 1rem;
  margin-top: 11px;
}
.App-header .controls .help {
  display: inline-block;
  vertical-align: middle;
  padding-right: 12px;
  padding-bottom: 2px;
  user-select: none;
}
.App-header .controls .help i {
  color: #dae0e7;
  transform: scale(1.3);
}
.App-header .controls .help i:hover {
  color: #aaaeb3;
}
.App-header .controls .settings {
  display: inline-block;
  margin-right: 20px;
  position: relative;
  top: 2px;
}
.App-header .controls .settings .material-icons {
  color: #aaaeb3;
}
.App-header .controls .settings button {
  background: none;
  border: none;
  color: #aaaeb3;
}
.App-header .controls .settings button::after {
  vertical-align: 0.45em;
}
.App-header .controls .settings button:focus {
  outline: none;
  box-shadow: none;
}
.App-header .controls .settings .dropdown-item {
  color: #4bb3d8;
}
.App-header .controls .settings .dropdown-item a {
  color: #4bb3d8;
  text-decoration: none;
}
.App-header .controls .settings .dropdown-item:hover {
  background-color: #dae0e7;
}
.App-header .controls .settings .dropdown-item:hover a {
  color: #4bb3d8;
  text-decoration: none;
}
.App-header .controls .settings a {
  color: #4bb3d8;
}
.App-header .controls .settings a:hover {
  color: #4bb3d8;
  text-decoration: none;
}
.App-header .controls .admin-menu {
  display: inline-block;
  position: relative;
  padding: 5px 0 0 3px;
  top: 0;
  color: #fff;
}
.App-header .controls .admin-menu .btn {
  background: #272e34;
  border: none;
  border-radius: 10px;
}
.App-header .controls .admin-menu .dropdown-divider {
  border-top: 1px solid #272e34;
}
.App-header .controls .admin-menu .dropdown-menu {
  margin-top: 6px;
}

.App-sidebar {
  background-color: #272e34;
  width: 190px;
  height: calc(100vh - 60px);
  float: left;
}
.App-sidebar .build {
  text-align: center;
  font-size: small;
  cursor: pointer;
  color: #aaaeb3;
  width: 100%;
  margin-top: 0.25rem;
}
.App-sidebar .build .build__new-version {
  position: absolute;
  bottom: 0.5rem;
}
.App-sidebar .dropdown-menu-dark .header-menu {
  background-color: #272e34;
}
.App-sidebar .dropdown-menu-dark .header-menu .selected.dropdown-item {
  border-radius: 0;
}
.App-sidebar .dropdown-menu-dark .dropdown-header {
  color: #aaaeb3;
}
.App-sidebar .dropdown-menu-dark button {
  color: #fff;
}
.App-sidebar .dropdown-menu-dark > button {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.App-sidebar .dropdown-menu-dark > button.selected {
  background-color: #4bb3d8;
}
.App-sidebar button.menu-back,
.App-sidebar button.menu-back:hover:not(disabled, .disabled),
.App-sidebar button.menu-back:focus {
  width: 100%;
  background-color: #000;
  border-color: #000;
  text-align: left;
  padding-left: 5px;
}
.App-sidebar button.menu-back:hover:not(disabled, .disabled) {
  background-color: #4bb3d8;
  border-color: #000;
}
.App-sidebar .selector .project-filter {
  width: 100%;
  display: inline-flex;
  position: relative;
  background-color: #4bb3d8;
  align-items: center;
  padding-right: 2px;
  padding-left: 2px;
}
.App-sidebar .selector .project-filter .project-filter-title {
  width: 100%;
  flex: auto;
  color: #fff;
  border-color: #000;
  padding: 2px;
  justify-content: center;
}
.App-sidebar .selector .project-filter .project-filter-title .project-count {
  display: inline-block;
  padding-top: 5px;
}
.App-sidebar .selector .project-filter a {
  padding-top: 2px;
}
.App-sidebar .selector .project-filter i.material-icons {
  color: #fff;
  height: 18px;
}
.App-sidebar .selector .project-filter .save-icon {
  top: 2px;
  padding: 0 1px;
  margin-inline: 0px;
}
.App-sidebar .selector .project-filter .project-filter-selected {
  background-color: #4bb3d8;
}
.App-sidebar .selector .project-filter .project-filter-toggle {
  border: none;
  padding: 0 1px;
  margin-inline: 0px;
}
.App-sidebar .selector .project-filter .project-filter-toggle i {
  margin-top: -8px;
}
.App-sidebar .selector .project-filter .dropdown-menu-dark > button {
  border-radius: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.App-sidebar .selector .project-filter .dropdown-menu-dark .header-menu {
  border: 2px solid #000;
  margin: 0;
  width: 300px;
}
.App-sidebar .selector .project-filter .dropdown-menu-dark .header-menu .menu-info {
  float: right;
}
.App-sidebar .selector .project-filter .dropdown-menu-dark .header-menu .sort-method input {
  margin-right: 5px;
}
.App-sidebar .selector .project-filter .dropdown-menu-dark .header-menu .refinement button {
  padding-left: 5px;
}
.App-sidebar .selector .project-filter .dropdown-menu-dark .header-menu .refinement button input {
  margin-right: 5px;
}
.App-sidebar .selector .project-filter .dropdown-menu-dark .header-menu .portfolio-select {
  padding: 6px;
}
.App-sidebar .selector .project-filter .dropdown-menu-dark .header-menu select {
  background-color: #000;
  color: #fff;
}
.App-sidebar .selector .project-filter .dropdown-menu-dark .header-menu .selected select {
  background-color: #4bb3d8;
}
.App-sidebar .selector .project-filter .dropdown-menu-dark .header-menu .badge-ecogy {
  margin-top: 5px;
  background-color: #4bb3d8;
}
.App-sidebar .selector .project-filter .dropdown-menu-dark .header-menu .dropend {
  width: 100%;
}
.App-sidebar .selector .project-filter .dropdown-menu-dark .header-menu .dropend button {
  text-align: left;
  width: 100%;
}
.App-sidebar .selector .project-filter .dropdown-menu-dark .header-menu .dropdown-menu {
  margin: 0;
  max-height: calc(100vh - 60px);
  overflow: initial;
}
.App-sidebar .selector .project-filter .dropdown-menu-dark .header-menu .dropdown-item {
  background-color: #000;
}
.App-sidebar .selector .project-filter .dropdown-menu-dark .header-menu .header-selected {
  background-color: #4bb3d8;
}
.App-sidebar .selector .project-selector {
  height: calc(100% - 100px);
  overflow-y: auto;
  overflow-x: hidden;
}
.App-sidebar .selector .project-selector .list-group {
  height: 100%;
}
.App-sidebar .selector .project-selector .list-group .active .material-icons {
  color: #fff;
}
.App-sidebar .selector .project-selector .list-group .list-group-item {
  padding: 0;
  padding-left: 5px;
  margin: 0;
  min-height: 30px;
  background-color: #475055;
  border-color: #475055;
  color: #fff;
  border-radius: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.App-sidebar .selector .project-selector .list-group .list-group-item .form-check-input {
  margin-right: 2px;
}
.App-sidebar .selector .project-selector .list-group .list-group-item:hover:not(disabled, .disabled) {
  background-color: #4bb3d8;
}
.App-sidebar .selector .project-selector .list-group .list-group-item.active {
  background-color: #4bb3d8;
  border-color: #4bb3d8;
  border-top-width: 0;
}
.App-sidebar .selector .project-selector .portfolio-selected button.list-group-item {
  border-bottom: 0;
  border-left-color: #4bb3d8;
  border-left-width: 4px;
  padding-left: 0;
}
.App-sidebar .selector .project-selector .portfolio-selected button.list-group-item input {
  border: 1px;
  border-radius: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-color: #4bb3d8;
  background-color: #4bb3d8;
  height: 20px;
  width: 20px;
  margin: 0;
}
.App-sidebar .selector .project-selector .portfolio-selected button.list-group-item input[type=checkbox]:not(:checked) {
  background-color: #272e34;
}
.App-sidebar .selector .project-selector .portfolio-selected button.unchecked {
  color: #aaaeb3;
}
.App-sidebar .selector .project-selector .portfolio-selected button.unchecked:hover {
  color: #fff;
}
.App-sidebar .selector .active .title {
  color: #fff;
}
.App-sidebar .selector .active .title i.material-icons {
  color: #4bb3d8;
}
.App-sidebar .selector .active .title .open {
  position: absolute;
  right: 0;
  display: inline-block;
  margin-right: 10px;
}
.App-sidebar .selector .active .title .open i.material-icons {
  color: #fff;
}
.App-sidebar .selector .active .title .close {
  display: none;
}
.App-sidebar .selector .active .title .sidebar-back {
  width: 100%;
  color: #fff;
  text-align: left;
}
.App-sidebar .selector .active .title .sidebar-back i.material-icons {
  color: #fff;
}
.App-sidebar .nav {
  background-color: #272e34;
  width: 190px;
  margin-top: 10px 0;
  padding-left: 10px;
  padding-top: 10px;
  bottom: 0;
  position: fixed;
}
.App-sidebar .nav a {
  text-decoration: none;
  width: 100%;
  display: inline-block;
  position: relative;
}
.App-sidebar .nav a:hover {
  text-decoration: none;
}
.App-sidebar .nav a:active {
  text-decoration: none;
}
.App-sidebar .nav .content-selector {
  border: solid 1px #475055;
  background-color: #475055;
  color: #fff;
  border-radius: 10px;
  width: 100%;
  padding: 5px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 5px;
}
.App-sidebar .nav .content-selector:hover {
  border: solid 1px #fff;
  color: #fff;
}
.App-sidebar .nav .active {
  border: solid 1px #4bb3d8;
  background-color: #4bb3d8;
  color: #fff;
}
.App-sidebar .nav .active:hover {
  color: #fff;
}

.App-module {
  background-color: #272e34;
  width: calc(100vw - 190px);
  height: calc(100vh - 60px);
  float: left;
}
.App-module .type-filter .dropdown-toggle {
  color: #fff;
}
.App-module .type-filter .dropdown-menu button:hover {
  background-color: #4bb3d8;
  border-radius: 0;
}
.App-module .type-filter > .btn-group > button:hover:not(disabled, .disabled) {
  border: none;
}
.App-module .type-filter button {
  border: none;
}

.rdt .rdtPicker {
  background-color: #1f1f1f;
  border-radius: 5px;
  border: none;
  font-size: small;
  padding: 0;
  margin: 5px 0;
}
.rdt .rdtPicker .rdtDays {
  border-radius: 5px;
}
.rdt .rdtPicker .rdtActive {
  background-color: #4bb3d8;
  border-radius: 4px;
}
.rdt .rdtPicker .rdtTimeToggle:hover {
  background-color: #4bb3d8;
}
.rdt .rdtPicker thead {
  background-color: #272e34;
  border-bottom: 1px solid #fff;
}
.rdt .rdtPicker thead tr:first-child th:hover {
  background-color: #4bb3d8;
}
.rdt .rdtPicker tbody {
  background-color: #353c44;
  border: none;
}
.rdt .rdtPicker th {
  border-bottom: none;
}
.rdt .rdtPicker td.rdtDay:hover {
  background-color: #4bb3d8;
  color: #fff;
  border-radius: 4px;
}
.rdt .rdtPicker td.rdtToday::before {
  border-bottom-color: #4bb3d8;
}

@keyframes App-logo-spin {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}
.App-loading {
  text-align: center;
  margin-top: 200px;
  color: #4bb3d8;
  font-size: xx-large;
}

.App-todo {
  text-align: center;
  margin-top: 200px;
  color: #4bb3d8;
  font-size: xx-large;
}

.App-logo {
  animation: App-logo-spin infinite 5s linear;
  height: 200px;
  margin: auto !important;
}

.loading {
  text-align: center;
  color: #4bb3d8;
  font-size: xx-large;
}

.loading-inline {
  position: fixed;
  bottom: 50px;
  right: 30px;
}
.loading-inline .App-logo {
  animation: App-logo-spin infinite 5s linear;
  height: 40px;
  display: inline-block;
}
.loading-inline .loading-message {
  display: inline-block;
}

.load-view {
  margin-top: 200px;
  text-align: center;
  color: #4bb3d8;
  font-size: xx-large;
  vertical-align: middle;
}

.Info {
  text-align: center;
}
.Info .info-title {
  padding-top: 10px;
}
.Info .info-title .material-icons {
  position: relative;
  top: 10px;
  color: #4bb3d8;
  font-size: 48px;
}
.Info .info-message {
  color: white;
}

.Error {
  text-align: center;
}
.Error .error-title {
  padding-top: 10px;
}
.Error .error-title .material-icons {
  position: relative;
  top: 10px;
  color: #e22006;
  font-size: 48px;
}
.Error .error-message {
  color: white;
}

.Warning {
  text-align: center;
}
.Warning .warning-title {
  padding-top: 10px;
}
.Warning .warning-title .material-icons {
  position: relative;
  top: 10px;
  color: #ffbb28;
  font-size: 48px;
}
.Warning .error-message {
  color: white;
}

.Message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 200px);
  font-size: 20;
  color: white;
  width: 100%;
}

