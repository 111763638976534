@import "../../../../../common/shared";
@import "./irr/IRRReportContent";

$height-project-header: 260px;
$height-table-head: 0;

.Module {
  .module-content {
    .IRRReport {
      height: calc(100vh - #{$height-header});

      table {
        tr.cashflow-types th {
          text-align: center;
        }

        .cash-flow-latestBestEstimate {
          color: $color-latestBestEstimate;

          th {
            color: $ecogy-black;
          }
        }

        .cash-flow-actual {
          color: $color-actual;

          th {
            color: $ecogy-black;
          }
        }

        .cash-flow-expected {
          color: $color-expected;

          th {
            color: $ecogy-black;
          }
        }

        .cash-flow-forecast {
          color: $color-forecast;

          th {
            color: $ecogy-black;
          }
        }

        .cash-flow-difference {
          color: $color-difference;

          th {
            color: $ecogy-black;
          }
        }
      }

      .error {
        color: $ecogy-status-red;
      }

      h1 {
        color: $ecogy-color-info;
        text-align: center;
      }

      .IRRReport-controls {
        float: left;
        padding-top: 5px;

        > * {
          float: left;
          width: auto;
        }
      }

      .IRRReport-options {
        position: absolute;
        right: 50px;
        padding-top: 5px;

        > * {
          float: right;
        }

        * {
          display: inline-block;
          width: auto;
        }

        span {
          padding: 8px;
          margin-right: 20px;
        }
      }

      .IRRReport-content {
        // overflow: auto; // using overflow makes the header non sticky
        // height: calc(100vh - #{$height-header} - #{$height-project-header} - #{$height-table-head});

        .col {
          padding: 0;
        }

        .scroller {
          padding-bottom: 5px;
        }

        table {
          overflow: auto;

          tr.years th {
            color: $ecogy-white;
            text-align: center;
            background-color: $ecogy-darkgrey;
            border: 1px solid #585c61;
          }

          tr.cashflow-types th {
            background-color: $ecogy-darkgrey;
          }

          th {
            border: 1px solid #585c61;
            color: $ecogy-white;
          }
        }

        .table-striped tbody {
          tr.profit-loss:nth-of-type(odd) {
            background-color: $ecogy-darkgrey;

            th {
              background-color: $ecogy-darkgrey;
              color: $ecogy-color-info;
            }
          }

          tr.account:nth-of-type(odd) {
            th {
              color: $ecogy-white;
            }
          }

          tr.cumulative:nth-of-type(odd) {
            th {
              color: $ecogy-lightgrey;
            }
          }

          tr.profit-loss:nth-of-type(even) {
            background-color: $ecogy-dark;

            th {
              background-color: $ecogy-dark;
              color: $ecogy-color-info;
            }
          }

          tr.account:nth-of-type(even) {
            th {
              color: $ecogy-white;
            }
          }

          tr.cumulative:nth-of-type(even) {
            th {
              color: $ecogy-lightgrey;
            }
          }
        }

        .irr-report table tbody tr.category th {
          background-color: $ecogy-mediumgrey;
          color: $ecogy-white;
        }

        .irr-report table tbody .category {
          background-color: $ecogy-mediumgrey;
          color: $ecogy-white;
        }

        .irr-report {
          select {
            color: $ecogy-white;
            background: $ecogy-dark;
          }
        }

        .multi-report {
          table {
            tbody {
              tr.account {
                th {
                  border-bottom: $ecogy-darkgrey;
                }
              }

              tr.project {
                th {
                  background-color: $ecogy-darkgrey;
                  border-top: $ecogy-darkgrey;
                  border-bottom: $ecogy-darkgrey;
                }
              }

              tr.retained {
                th,
                td {
                  background-color: $color-forecast;
                  border-color: $color-forecast;
                }
              }
            }
          }

          .table-striped {
            tbody {
              tr.project,
              tr.account {
                th,
                th:nth-of-type(even) {
                  background-color: $ecogy-darkgrey;
                }
              }

              tr.retained {
                th,
                th:nth-of-type(even) {
                  background-color: $color-forecast;
                  border-color: $color-forecast;
                }
              }
            }
          }
        }
      }
    }
  }

  form.irr-report-column-controls {
    float: left;
    border: 1px transparent solid;

    .form-check {
      margin-bottom: 0px
    }

    .form-check-inline {
      padding-left: 5px;
    }

    // .form-check-inline > * {
    //   display: flex;
    //   // padding-left: 5px;
    // }

    .form-check-label-color {
      margin-top: -7px;
    }

    .form-check-input {
      margin-left: 2px;
    }

    .control-forecast-group > * {
      display: flex;
    }

    .control-forecast-label {
      margin-top: 5px;
      padding: 0 2px;
    }

    .control-latestBestEstimate {
      background-color: $color-latestBestEstimate;
    }

    .control-actual {
      background-color: $color-actual;
    }

    .control-expected {
      background-color: $color-expected;
    }

    .control-forecast {
      background-color: $color-forecast;

      // width: 200px;
      // display: inline-block;
    }

    .control-difference {
      background-color: $color-difference;
    }
  }

  .module-content .content-view-controls {
    label.irr-report-account-controls {
      margin-top: 3px;
    }

    .report-flag-controls {
      .dropdown-menu {
        width: 230px;
        padding: 10px;
      }
    }

    .control-forecast__proforma-check {
      margin-top: 0.5rem;
    }

    .control-forecast__proforma-select {
      // width: 100%;
      // float: right;
    }
  }

  .hidden {
    display: none;
  }
}
