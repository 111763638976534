/**
 * Material icon styling.
 */
@import "@aws-amplify/ui-react/styles.css";
.material-icons {
  -webkit-user-select: none;
  user-select: none;
}

/**
 * Popover styling.
 */
.popover {
  background-color: #475055;
}

.popover-body {
  color: #fff;
}

.popover-header {
  background-color: #4bb3d8;
  color: #fff;
}

.popover-arrow::before {
  border-top-color: #475055 !important;
}

.popover-arrow::after {
  border-top-color: #475055 !important;
}

/**
 * Generic table styling.
 */
.center-table-td, .center-table-th {
  text-align: center !important;
}

.center-table-td {
  vertical-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.sf-loader-position {
  position: absolute;
  right: 1%;
  top: 90px;
}

.disable-none {
  display: none;
}

.loader-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.login-mfa {
  display: flex;
  justify-content: center;
  width: 100%;
}
.login-mfa .login-mfa-wrapper {
  max-width: 768px;
  margin-top: 16px;
  height: calc(100vh - 90px) !important;
  background-color: white;
  color: black;
  padding: 32px;
  border-radius: 8px;
  overflow-y: auto;
}
.login-mfa .login-mfa-body {
  margin-top: 32px;
  margin-bottom: 16px;
}
.login-mfa .mfa-code {
  color: #4bb3d8;
}
.login-mfa .error {
  color: #e22006;
}
.login-mfa .login-mfa-confirm-button {
  width: 100%;
}

[data-amplify-authenticator] {
  background-color: #272e34 !important;
}

[data-amplify-router] {
  border-radius: 8px;
}

.amplify-button[data-variation=primary] {
  background: #4bb3d8;
}

.auth-header {
  top: 0;
  position: absolute;
  width: 100%;
  left: 0;
}

.auth-signin-header {
  padding: 32px 32px 0;
}

.auth-confirm-resetpassword-header {
  margin-bottom: 16px;
}

.auth-resetpassword-header {
  margin-bottom: 16px;
}

