/**
 * Material icon styling.
 */
.material-icons {
  -webkit-user-select: none;
  user-select: none;
}

/**
 * Popover styling.
 */
.popover {
  background-color: #475055;
}

.popover-body {
  color: #fff;
}

.popover-header {
  background-color: #4bb3d8;
  color: #fff;
}

.popover-arrow::before {
  border-top-color: #475055 !important;
}

.popover-arrow::after {
  border-top-color: #475055 !important;
}

/**
 * Generic table styling.
 */
.center-table-td, .center-table-th {
  text-align: center !important;
}

.center-table-td {
  vertical-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.sf-loader-position {
  position: absolute;
  right: 1%;
  top: 90px;
}

.disable-none {
  display: none;
}

.loader-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
body {
  margin: 0;
  padding: 0;
  background-color: #272e34;
  color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  overflow: hidden;
  font-size: 14px;
}

.slide-pane__content {
  z-index: 10;
}

.Toastify__toast-body {
  font-family: "Inter";
}

[data-amplify-router] {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 500px;
}

#root button[class^=Button__button___],
#root div[class*=" Button__button___"] {
  background-color: #4bb3d8;
}

.numerical {
  text-align: right;
}

.material-icons {
  top: 4px;
  position: relative;
  font-size: 18px;
}

button.btn-primary,
button.btn-primary:active,
button.btn-primary:focus {
  background-color: #4bb3d8;
  border: 1px solid #475055;
}

button.btn-primary:hover:not(disabled, .disabled) {
  background-color: #4bb3d8;
  border: 1px solid #fff;
}

button.btn-secondary,
button.btn-secondary:active,
button.btn-secondary:focus {
  background-color: #475055;
  border: 1px solid #475055;
}

button.btn-secondary:hover:not(disabled, .disabled) {
  border: 1px solid #fff;
}

button.selected,
button.selected:active,
button.selected:focus {
  background-color: #4bb3d8;
  border: 1px solid #475055;
}

button.selected:hover:not(disabled, .disabled) {
  background-color: #4bb3d8;
  border: 1px solid #fff;
}

.btn:hover {
  color: #fff;
}

.btn-group > button {
  border-radius: 10px;
  border: 1px solid #272e34;
}
.btn-group .btn-secondary.disabled,
.btn-group .btn-secondary:disabled {
  border: 1px solid #272e34;
}

.btn-group-sm .btn {
  border: 1px solid #272e34;
  border-radius: 10px;
}

.dropdown-menu {
  background-color: #000;
  color: #fff;
}
.dropdown-menu button {
  border-radius: 0;
}
.dropdown-menu .dropdown-item {
  border: 1px solid transparent;
  color: #4bb3d8;
}
.dropdown-menu .dropdown-item a {
  color: #4bb3d8;
  text-decoration: none;
}
.dropdown-menu .dropdown-item:hover:not(disabled, .disabled) {
  color: #fff;
  background-color: #4bb3d8;
  border-color: #4bb3d8;
}
.dropdown-menu .dropdown-item:hover:not(disabled, .disabled) a {
  color: #fff;
  text-decoration: none;
}

button.list-group-item,
button.active.list-group-item {
  border-top-width: 0;
}

button.list-group-item:hover:not(disabled, .disabled) {
  border-top-width: 0;
  background-color: #4bb3d8;
}

.tooltip-inner {
  max-width: 400px;
}

.nav-tabs {
  border-bottom: 1px solid #272e34;
}

