@import "../../../../../common/shared";

h1 {
  text-align: center;
}

.empty-message {
  text-align: center;
  font-size: large;
}

.billing-table {
  .billing-line-item {
    background-color: $ecogy-grey;
  }

  .due {
    background-color: $ecogy-blue;
  }

  .billing-line-item-readings {
    h4 {
      text-align: center;
    }

    td {
      > div {
        background-color: $ecogy-grey;
        height: 100%;
      }
    }

    .historic-rates {
      float: left;
      width: 40%;
    }

    .billing-generation {
      margin-left: 10px;
      float: left;
      width: 30%;
    }

    .reading-status {
      margin-left: 10px;
      float: left;
      width: calc(30% - 20px);
      height: 186px;
      overflow-y: auto;

      ul {
        padding-left: 10px;
        list-style-type: none;
      }
    }
  }
}
