/**
 * Material icon styling.
 */
.material-icons {
  -webkit-user-select: none;
  user-select: none;
}

/**
 * Popover styling.
 */
.popover {
  background-color: #475055;
}

.popover-body {
  color: #fff;
}

.popover-header {
  background-color: #4bb3d8;
  color: #fff;
}

.popover-arrow::before {
  border-top-color: #475055 !important;
}

.popover-arrow::after {
  border-top-color: #475055 !important;
}

/**
 * Generic table styling.
 */
.center-table-td, .center-table-th {
  text-align: center !important;
}

.center-table-td {
  vertical-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.sf-loader-position {
  position: absolute;
  right: 1%;
  top: 90px;
}

.disable-none {
  display: none;
}

.loader-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
h1 {
  text-align: center;
}

.empty-message {
  text-align: center;
  font-size: large;
}

.billing-table .billing-line-item {
  background-color: #475055;
}
.billing-table .due {
  background-color: #4bb3d8;
}
.billing-table .billing-line-item-readings h4 {
  text-align: center;
}
.billing-table .billing-line-item-readings td > div {
  background-color: #475055;
  height: 100%;
}
.billing-table .billing-line-item-readings .historic-rates {
  float: left;
  width: 40%;
}
.billing-table .billing-line-item-readings .billing-generation {
  margin-left: 10px;
  float: left;
  width: 30%;
}
.billing-table .billing-line-item-readings .reading-status {
  margin-left: 10px;
  float: left;
  width: calc(30% - 20px);
  height: 186px;
  overflow-y: auto;
}
.billing-table .billing-line-item-readings .reading-status ul {
  padding-left: 10px;
  list-style-type: none;
}

