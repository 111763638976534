/**
 * Material icon styling.
 */
.material-icons {
  -webkit-user-select: none;
  user-select: none;
}

/**
 * Popover styling.
 */
.popover {
  background-color: #475055;
}

.popover-body {
  color: #fff;
}

.popover-header {
  background-color: #4bb3d8;
  color: #fff;
}

.popover-arrow::before {
  border-top-color: #475055 !important;
}

.popover-arrow::after {
  border-top-color: #475055 !important;
}

/**
 * Generic table styling.
 */
.center-table-td, .center-table-th {
  text-align: center !important;
}

.center-table-td {
  vertical-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.sf-loader-position {
  position: absolute;
  right: 1%;
  top: 90px;
}

.disable-none {
  display: none;
}

.loader-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.Econode .App-sidebar .selector {
  height: calc(100% - 45px);
}
.Econode .content table.econode-info {
  text-align: center;
}
.Econode .content table.econode-info td {
  width: 33%;
  border: none;
}
.Econode .content table.econode-info td p {
  font-size: large;
  color: #4bb3d8;
  margin: 0;
}
.Econode .content table.econode-info td p.highlight {
  font-size: xx-large;
  margin-top: 20px;
}
.Econode .content .econode-info {
  clear: both;
  overflow: auto;
}
.Econode .content .econode-info .econode-info-spot {
  width: calc(50% - 40px);
  margin: 20px;
  padding: 2px;
  float: left;
  background-color: #4bb3d8;
  color: #fff;
  border-radius: 20px;
  font-weight: bold;
}
.Econode .content .econode-info .econode-info-spot .econode-info-spot-label {
  padding-top: 2px;
  padding-left: 20px;
  font-size: large;
  display: inline-block;
}
.Econode .content .econode-info .econode-info-spot .econode-info-spot-label .econode-info-spot-tooltip {
  margin-right: 8px;
}
.Econode .content .econode-info .econode-info-spot .econode-info-spot-content {
  float: right;
  background-color: #272e34;
  border-radius: 20px;
  padding: 5px 50px;
}
.Econode .content .econode-info .econode-info-spot-3 {
  width: calc(33.33% - 40px);
}
.Econode .content .econode-info .econode-info-spot-3 .econode-info-spot-label {
  font-size: medium;
}
.Econode .content .econode-toggles {
  margin: 0 20px 10px;
  padding: 2px;
}
.Econode .content .econode-toggles button {
  border-radius: 10px;
}
.Econode .content .econode-toggles button.btn.active {
  background-color: #4bb3d8;
}
.Econode .content table.econode-readings {
  padding: 0 20px;
  border-collapse: separate;
  border-spacing: 0 20px;
}
.Econode .content table.econode-readings .label {
  text-align: left;
  background-color: #4bb3d8;
  border-radius: 20px 0 0 20px;
}
.Econode .content table.econode-readings tr th {
  color: #fff;
  text-align: center;
}
.Econode .content table.econode-readings tr td {
  background-color: #272e34;
  text-align: center;
  width: 25%;
  border: 0;
  padding: 7px 20px;
}
.Econode .content table.econode-readings tr td:last-child {
  border-radius: 0 20px 20px 0;
}

