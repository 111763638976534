/**
 * Material icon styling.
 */
.material-icons {
  -webkit-user-select: none;
  user-select: none;
}

/**
 * Popover styling.
 */
.popover {
  background-color: #475055;
}

.popover-body {
  color: #fff;
}

.popover-header {
  background-color: #4bb3d8;
  color: #fff;
}

.popover-arrow::before {
  border-top-color: #475055 !important;
}

.popover-arrow::after {
  border-top-color: #475055 !important;
}

/**
 * Generic table styling.
 */
.center-table-td, .center-table-th {
  text-align: center !important;
}

.center-table-td {
  vertical-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.sf-loader-position {
  position: absolute;
  right: 1%;
  top: 90px;
}

.disable-none {
  display: none;
}

.loader-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ghost-record {
  text-transform: capitalize;
  opacity: 0.4;
}

.status-in-progress {
  text-transform: uppercase;
  background-color: #317133;
  text-align: center;
}

.status-planned {
  text-transform: uppercase;
  background-color: #316a71;
  text-align: center;
}

.status-completed {
  text-transform: uppercase;
  background-color: orange;
  text-align: center;
}

th,
td {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.content-view-controls {
  padding: 10px;
  height: 50px;
}

.btn-create-event {
  width: 100px;
}

.dr-event-details-content {
  overflow: auto;
  height: calc(100% - 100px);
  background-color: #272e34;
  padding: 20px;
}
.dr-event-details-content label {
  color: #4bb3d8;
  margin-right: 5px;
}
.dr-event-details-content .ecogy-form label {
  margin-right: 0;
}
.dr-event-details-content .type {
  text-transform: capitalize;
}
.dr-event-details-content hr {
  margin: 2px;
  border-top-color: #dae0e7;
}
.dr-event-details-content .remove-button {
  margin-bottom: 20px;
}
.dr-event-details-content form > .form-group fieldset > .form-group {
  width: 100%;
  padding-right: 10px;
}
.dr-event-details-content form > .form-group > div > div > fieldset > .form-group {
  width: 50%;
  padding-right: 10px;
}
.dr-event-details-content .right-column {
  float: right;
  clear: both;
  padding-right: 0;
  padding-left: 10px;
}

.help-box {
  background-color: #4bb3d8;
  color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.priority-low {
  background-color: #0088fe;
  color: white;
  min-width: 7em;
  text-transform: capitalize;
}

.priority-medium {
  background-color: #00c49f;
  color: white;
  min-width: 7em;
  text-transform: capitalize;
}

.priority-high {
  background-color: #ffbb28;
  color: white;
  min-width: 7em;
  text-transform: capitalize;
}

.participants {
  padding-bottom: 50px;
}

.events {
  padding-bottom: 50px;
}

.notifications {
  padding-bottom: 50px;
}

