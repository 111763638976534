@import "../common/shared";

$height-project-header: 56px;

.Admin {
  background-color: $background-content-header;

  // color: $ecogy-dark;
  height: calc(100vh - #{$height-header});

  .list-group-item.active {
    background-color: $ecogy-color-active;
    border-color: $ecogy-color-active;
  }

  h1 {
    color: $ecogy-color-info;
    text-align: center;
  }

  .Admin-footer {
    position: absolute;
    bottom: 0;
    padding-bottom: 10px;

    .footer-button {
      float: left;
      background-color: $ecogy-color-active;
      border-color: $ecogy-color-active;
      margin-top: 10px;
    }
  }

  .Admin-content {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - #{$height-header});

    .admin-footer {
      background-color: $ecogy-darkgrey;
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 10px;
      z-index: 10;

      .user-audit-view-controls__toggle {
        text-transform: capitalize;

        i {
          padding-left: 0.25rem;
        }
      }

      .user-audit-view-controls__dropdown-item {
        display: flex;
        justify-content: space-between;
      }
    }

    .admin-footer__form-buttons {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    .float-end {
      right: 0;
    }

    .admin-menu {
      overflow: auto;
      height: calc(100vh - #{$height-header} - #{$height-project-header});
      padding-bottom: 20px;

      .list-group-item {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 10px;
        background-color: $ecogy-dark;
        color: $ecogy-white;

        strong {
          color: $ecogy-color-info;
        }
      }

      .active {
        background-color: $ecogy-color-active;

        strong {
          color: $ecogy-white;
        }
      }
    }

    .ecogy-form {
      .ecogy-form__portfolios__dropdown-toggle {
        margin: 0;
        padding: 0 1rem 0 0.75rem;
        background-color: $ecogy-darkgrey;
        box-shadow: none;
        border: 1px solid $ecogy-lightgrey;
        border-radius: 0.2rem;

        &:hover,
        &:focus {
          border: 1px solid #fff;
        }
      }

      .ecogy-form__portfolios__list-group {
        position: absolute;
        z-index: 1;

        .ecogy-form__portfolios__list-group-item {
          border-radius: 0.25rem;

          &:hover {
            color: #fff;
            background-color: $ecogy-blue;
          }
        }
      }

      .ecogy-form__user-type__dropdown-toggle {
        margin: 0 0 0.25rem;
        padding: 0 0.5rem 0 0.25rem;
        background-color: $ecogy-darkgrey;
        box-shadow: none;
        border-radius: 0;
      }

      #root_modules_ams {
        display: grid;
      }

      #root_modules_ams_finance_irrControls,
      #root_modules_econode_finance_irrControls,
      #root_modules_ams_data_recordTypes,
      #root_modules_ams_process_checklistControls {
        padding-left: 30px;

        #root_modules_ams_finance_irrControls__title,
        #root_modules_econode_finance_irrControls__title,
        #root_modules_ams_data_recordTypes__title {
          font-weight: bold;
          font-size: large;
        #root_modules_ams_data_recordTypes__title,
        #root_modules_ams_process_checklistControls__title
        {
          font-size: 1.25rem;
          margin: 5px 0 0 -12px;
        }
      }

        #root_modules_ams_finance_irrControls__description,
        #root_modules_econode_finance_irrControls__description,
        #root_modules_ams_data_recordTypes__description
        #root_modules_ams_process_checklistControls__description
         {
          font-size: 0.9rem;
          margin: 0 0 10px -10px;
        }
      }

      #root_modules_ams__title, #root_modules_econode__title, #root_modules__title {
        margin-top: 25px;
        margin-bottom: 5px;
        font-weight: bold;
        font-size: xx-large
      }

      #root_modules_ams_event__title, #root_modules_ams_connectivity__title,
      #root_modules_ams_energy__title, #root_modules_ams_demandresponse__title,
      #root_modules_ams_finance__title, #root_modules_ams_data__title, #root_modules_ams_process__title,
      #root_modules_econode_event__title, #root_modules_econode_energy__title, #root_modules_econode_finance__title
      {
        margin-top: 25px;
        margin-bottom: 5px;
        font-weight: bold;
        font-size: x-large
      }

      #root_modules__description, #root_modules_ams__description, #root_modules_econode__description {
        font-size: medium;
        margin-left: 10px;
      }

      .ecogy-form__default-permissions__info {
        margin-top: 0;
        white-space: pre-wrap;

        i {
          top: 27px;
          scale: 1.5;
        }
      }

      .field-array {
        margin-left: 10px;
      }
    }

    .ecogy-form-buttons {
      width: 100%;
    }

    .header-id {
      font-size: small;
      color: $ecogy-lightgrey;
      padding-left: 10px;
    }

    .close-button {
      top: 0;
      font-size: 48px;
    }

    table {
      thead.sticky th {
        position: sticky;
        top: 0;
        z-index: 10;
        background-color: $ecogy-white;
      }

      width: 100%;
      border-collapse: collapse;
      overflow: hidden;
      margin-bottom: 20px;

      th {
        color: $ecogy-color-info;
        text-align: left;
      }

      th.ranged {
        color: $ecogy-white;
        background-color: $ecogy-color-info;
      }

      td {
        // background-color: $background-content;
        border: solid 1px $background-app;
        color: $ecogy-white;

        a {
          color: $ecogy-white;
        }

        .btn-link {
          padding: 0;
          padding-left: 5px;

          .material-icons {
            color: $ecogy-color-active;
          }
        }
      }

      button.user-button {
        width: 100%;
        text-align: left;
        padding: 0 5px;
      }

      td.active {
        background-color: $ecogy-blue;

        button.user-button {
          background-color: $ecogy-blue;
        }
      }
    }

    .audit-log {
      overflow-y: auto;
      background-color: $ecogy-mediumgrey;

      th {
        top: 0;
        position: sticky;
        z-index: 1;
        background-color: $ecogy-mediumgrey;
        box-shadow: 0 4px 2px -2px $ecogy-darkgrey;
      }

      th:first-child,
      td:first-child {
        padding-left: 5px;
      }

      th:last-child {
        text-align: center;
      }

      td {
        border: solid 2px $ecogy-darkgrey;
      }

      .audit-changes {
        width: 50%;
      }
    }

    .audit-diff {
      table-layout: fixed;
      overflow-wrap: break-word;
      margin-bottom: 0;

      th {
        font-weight: normal;
        text-align: center;
      }

      td {
        border-left: none;
        border-bottom: none;
        padding: 0 0.25rem;
      }

      pre {
        white-space: pre-wrap;
      }

      .original {
        width: 40%;
        color: $ecogy-status-red;
        font-size: 1rem;
      }

      .new {
        width: 40%;
        color: $ecogy-status-green;
        font-size: 1rem;
      }
    }

    .user-table {
      th.user-name {
        width: 25%;
      }

      td.user-name {
        padding-right: 20px;
      }
    }

    .user-registration-expired {
      color: $ecogy-color-error;
    }

    .card {
      color: $ecogy-white;
      background-color: $ecogy-darkgrey;
    }

    .admin-side-panel {
      margin-left: -15px;
      margin-right: 0;
      padding-right: 0;
      margin-bottom: 60px;

      .admin-side-panel-content {
        // border: $ecogy-palegrey solid 1px;
        background-color: $ecogy-color-info;
        color: $ecogy-white;
        border-radius: 10px;
        padding: 20px;
        height: 100%;

        table {
          width: 100%;
        }

        a {
          color: $ecogy-white;
        }

        > div {
          padding-bottom: 20px;
        }

        .download {
          border-color: $ecogy-white;
          background-color: $ecogy-white;
          color: $ecogy-color-active;
          margin-bottom: 2px;
          width: 100%;
          text-align: left;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          .material-icons {
            color: $ecogy-color-active;
          }
        }

        .missing {
          color: $ecogy-status-red;
        }

        .payments {
          width: 100%;

          .past {
            color: $ecogy-palegrey;
          }
        }
      }
    }

    .date-range-menu {
      // Temporary until we implement report for range of months
      width: auto;
      height: auto;

      .custom-date-range {
        width: 325px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .custom-date {
          width: 100%;
          height: 275px;
          padding-right: 5px;

          th {
            text-align: center;
          }

          .rdt {
            .rdtPicker {
              width: 100%;
            }
          }
        }
      }
    }

    .report {
      .document {
        min-height: 20px;
        line-height: 42.5px;
      }
    }

    .admin-content-area {
      height: calc(100vh - #{$height-header} - #{$height-project-header});
      overflow: auto;
    }

    .admin-tabs {
      .new-button {
        background-color: $ecogy-color-active;
        border-color: $ecogy-color-active;
        float: right;
      }

      .nav-tabs {
        border-bottom: 1px solid $ecogy-dark;
        margin-bottom: 20px;

        .nav-item {
          cursor: pointer;

          a {
            color: $ecogy-white;
            background-color: $ecogy-darkgrey;
            border: 1px solid $ecogy-dark;
          }

          a.active {
            background-color: $ecogy-dark;
            border: none;
          }
        }

        .new {
          a {
            color: $ecogy-white;
            background-color: $ecogy-color-active;
          }

          a.active {
            color: $ecogy-color-active;
            background-color: $ecogy-white;
          }
        }
      }

      .new-card {
        color: $ecogy-white;
        background-color: $ecogy-color-info;

        .loading {
          background-color: $ecogy-white;
        }

        .icon-button {
          border-color: $ecogy-white;

          .material-icons {
            color: $ecogy-white;
          }
        }

        .icon-button.btn-danger {
          border-color: $ecogy-status-red;

          .material-icons {
            color: $ecogy-status-red;
          }
        }
      }

      .address-line {
        display: block;
      }

      .admin-tab__conflict-warning {
        padding-left: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .warning-wrapper {
          display: flex;
          align-items: center;
        }

        i {
          color: $ecogy-color-orange;
          position: absolute;
          top: 10px;
          left: 10px;
          margin-top: 0.5rem;
        }
      }

      .admin-tab-content-area {
        height: calc(100vh - #{$height-header} - #{$height-project-header} - 100px);
        overflow-x: hidden;
        overflow-y: auto;

        hr {
          margin-top: 0;
        }

        .info {
          margin-top: 0;
          margin-bottom: 1rem;

          i {
            margin-top: 0.5rem;
          }
        }
      }

      .field-group {
        .card {
          padding: 20px;
        }

        .field-group-title {
          font-weight: bold;
        }

        .field-group-toggle {
          text-align: right;
          float: right;
        }
      }

      .card {
        margin-bottom: 10px;
      }

      .project-finance-category {
        margin-bottom: 20px;
      }
    }

    .user-groups {
      margin-bottom: 20px;

      .user-group-name {
        width: 200px;
      }

      h4 {
        font-weight: 500; 
        margin-right: 5px; 
        text-transform: capitalize; 
        font-size: 1.5rem; 
        display: inline; 
        vertical-align: middle;
      }
    }
  }

  .loading {
    text-align: center;
    margin-top: 200px;
    color: $ecogy-color-info;
    font-size: xx-large;
  }

  .Admin-error {
    text-align: center;
    margin-top: 200px;
    color: $ecogy-status-red;
    font-size: xx-large;
  }

  .Admin__parameter-store {
    height: calc(100vh - 56px);
    padding: 0 1rem;
    padding-top: 1rem;
    overflow-y: scroll;

    .Admin__parameter-store__param-container {
      .Admin__parameter-store__param-fields {
        width: 100%;
        display: grid;
        gap: 1rem;
        margin-top: 20px;

        .Admin__parameter-store__param-field {
          width: 100%;
          display: flex;
          flex-direction: column;
          position: relative;
          margin-top: 0.5rem;

          input:read-only {
            background-color: $ecogy-darkgrey;
            color: $ecogy-white;
          }

          span {
            position: absolute;
            font-size: 1rem;
            top: -20px;
            left: 5px;
          }

          li {
            color: $ecogy-color-error;
            margin-left: 1rem;
          }
        }
      }

      .Admin__parameter-store__param-controls {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;

        span {
          font-size: 1rem;
          padding-left: 1rem;
        }
      }
    }
  }
}
