/**
 * Material icon styling.
 */
.material-icons {
  -webkit-user-select: none;
  user-select: none;
}

/**
 * Popover styling.
 */
.popover {
  background-color: #475055;
}

.popover-body {
  color: #fff;
}

.popover-header {
  background-color: #4bb3d8;
  color: #fff;
}

.popover-arrow::before {
  border-top-color: #475055 !important;
}

.popover-arrow::after {
  border-top-color: #475055 !important;
}

/**
 * Generic table styling.
 */
.center-table-td, .center-table-th {
  text-align: center !important;
}

.center-table-td {
  vertical-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.sf-loader-position {
  position: absolute;
  right: 1%;
  top: 90px;
}

.disable-none {
  display: none;
}

.loader-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.Module {
  height: calc(100vh - 60px);
}
.Module .module-header {
  background-color: #272e34;
  min-height: 62px;
  clear: both;
}
.Module .module-header .wrap {
  display: inline-flex;
  max-width: 100%;
  position: relative;
  top: 0px;
  left: 0px;
}
.Module .module-header .module-header-info {
  height: 62px;
}
.Module .module-header .module-header-info .module-header-banner {
  font-size: x-large;
  text-align: center;
}
.Module .module-header .module-header-info .module-header-title {
  text-align: center;
}
.Module .module-header .module-header-info .module-header-title i,
.Module .module-header .module-header-info .module-header-title a {
  color: #0df;
}
.Module .module-header .module-header-info .module-header-title a {
  font-size: 1rem;
}
.Module .module-header .module-header-info .module-header-title i {
  transform: scale(0.9);
}
.Module .module-header .header-selected {
  border-radius: 0;
}
.Module .module-header .view-exit {
  float: left;
  margin-top: 10px;
  margin-left: 10px;
}
.Module .module-header .view-title {
  float: left;
  padding-top: 10px;
  padding-left: 10px;
}
.Module .module-header .view-title .material-icons {
  color: #fff;
  font-size: 24px;
  padding-left: 20px;
}
.Module .module-header .header-bar {
  float: left;
  margin-left: 10px;
}
.Module .module-header .header-bar a .btn {
  margin-right: 10px;
}
.Module .module-header .header-bar .form-inline {
  float: left;
  padding-top: 11px;
  font-size: 1rem;
}
.Module .module-header .header-bar .form-inline .input-group {
  margin-right: 20px;
}
.Module .module-header .header-bar .form-inline input {
  display: inline-block;
}
.Module .module-header .header-bar .form-inline label {
  padding-right: 5px;
  text-transform: capitalize;
}
.Module .module-header .header-bar .form-inline ::placeholder {
  color: #dae0e7;
}
.Module .module-header .secondary-header-toolbar {
  float: right;
  margin-right: 10px;
}
.Module .module-header .header-menu {
  background-color: #272e34;
}
.Module .module-header .header-selector {
  padding: 10px;
}
.Module .module-header .header-selector label {
  padding-top: 10px;
  padding-right: 10px;
  margin: 0;
}
.Module .module-header .header-selector .material-icons {
  padding-top: 5px;
}
.Module .module-header .header-selector .dropdown-menu-dark button {
  color: #aaaeb3;
  background-color: #272e34;
}
.Module .module-header .header-disabled {
  visibility: hidden;
}
.Module .module-header .btn-toolbar .header-button-group .material-icons {
  position: relative;
  top: 2px;
  color: #aaaeb3;
  padding-right: 5px;
  font-size: 16px;
}
.Module .module-header .btn-toolbar .header-button-group .selected .material-icons {
  color: #4bb3d8;
}
.Module .module-header .btn-toolbar .btn-group {
  margin-right: 10px;
}
.Module .module-header .btn-toolbar .btn-group .btn-secondary {
  outline: none;
}
.Module .module-header .btn-toolbar .btn-group .btn-secondary:focus {
  outline: none;
  box-shadow: none;
}
.Module .module-header .btn-toolbar .btn-group .btn {
  margin-right: 0;
}
.Module .module-header .btn-toolbar .date-range-group {
  margin-right: 0;
}
.Module .module-header .btn-toolbar .date-range-group .selected {
  color: #fff;
  background-color: #4bb3d8;
}
.Module .module-header .btn-toolbar .date-range-group .selected .material-icons {
  color: #fff;
}
.Module .module-content {
  margin-left: 10px;
  margin-right: 10px;
  background-color: #272e34;
  height: calc(100% - 62px);
}
.Module .module-content .header-id {
  font-size: small;
  color: #aaaeb3;
  padding-left: 10px;
}
.Module .module-content .content-view {
  height: 100%;
}
.Module .module-content .force {
  position: fixed;
  bottom: 0;
  margin-right: 20px;
  width: calc(100vw - 190px - 10px);
}
.Module .module-content .content-view-controls {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 0;
  padding-left: 0;
  height: 50px;
  background-color: #272e34;
}
.Module .module-content .content-view-controls a .btn:last-child {
  margin-right: 10px;
}
.Module .module-content .content-view-controls .header-button-group .btn {
  margin: 0;
}
.Module .module-content .content-view-controls label {
  margin-top: 5px;
  margin-right: 5px;
}
.Module .module-content .content-view-controls form {
  margin-right: 10px;
}
.Module .module-content .content-view-controls form .form-check-label-color {
  border-radius: 10px;
  padding: 0.25rem 0.5rem;
  margin-top: 0;
}
.Module .module-content .content-view-controls .form-check-inline {
  margin-right: 0;
}
.Module .module-content .search-bar {
  position: fixed;
  width: calc(100% - 190px);
  padding-right: 20px;
}
.Module .module-content .search-bar .input-group {
  padding-right: 5px;
}
.Module .module-content .search-bar .input-group .input-group-text {
  background-color: #272e34;
  color: #fff;
}
.Module .module-content .search-bar .input-group button,
.Module .module-content .search-bar .input-group input {
  background-color: #1f1f1f;
  color: #fff;
  border-color: #fff;
}
.Module .module-content .search-bar .input-group-sm {
  padding-right: 5px;
}
.Module .module-content .search-bar .input-group-sm .input-group-text {
  background-color: #272e34;
  color: #fff;
  border-radius: 10px;
  padding-inline: 0.5rem;
  padding-block: 0.25rem;
  font-size: 14px;
}
.Module .module-content .search-bar .input-group-sm button,
.Module .module-content .search-bar .input-group-sm input {
  background-color: #1f1f1f;
  color: #fff;
  border-color: #fff;
  border-radius: 10px;
  padding-inline: 0.5rem;
  padding-block: 0.25rem;
  font-size: 14px;
}
.Module .module-content .search-bar .form-check-label {
  padding: 0 5px;
  margin-right: 10px;
  border-radius: 5px;
}
.Module .module-content .content {
  float: left;
  width: calc(100% - 330px - 10px - 2px);
  height: 100%;
  background-color: #353c44;
}
.Module .module-content .content .content-with-title {
  height: 100%;
  border-bottom: solid 1px #272e34;
  overflow: auto;
}
.Module .module-content .content .content-main {
  height: calc(100% - 300px);
  border-bottom: solid 1px #272e34;
  overflow: auto;
}
.Module .module-content .content .content-main-full {
  height: 100%;
}
.Module .module-content .content .content-footer {
  height: 300px;
  width: 100%;
}
.Module .module-content .content .ok {
  background-color: #4caf50;
}
.Module .module-content .content .warning {
  background-color: orange;
}
.Module .module-content .content .error {
  background-color: #e22006;
}
.Module .module-content .content .no-status {
  background-color: #272e34;
}
.Module .module-content .content .no-match {
  text-align: center;
}
.Module .module-content .content .content-title {
  text-align: center;
  padding: 10px;
  margin-bottom: 0;
}
.Module .module-content .content .content-subtitle {
  text-align: center;
  padding: 10px;
  margin: 0;
}
.Module .module-content .content .site-content {
  padding-bottom: 50px;
  background-color: #1f1f1f;
}
.Module .module-content .content .site-content td.ok {
  color: #4caf50;
  background-color: #1f1f1f;
}
.Module .module-content .content .site-content td.warning {
  color: orange;
  background-color: #1f1f1f;
}
.Module .module-content .content .site-content td.error {
  color: #e22006;
  background-color: #1f1f1f;
}
.Module .module-content .content .site-content td.no-status {
  color: #fff;
}
.Module .module-content .content .site-content td.ranged {
  background-color: #272e34;
}
.Module .module-content .content .system-content {
  clear: both;
}
.Module .module-content .content table {
  width: 100%;
  border-collapse: separate;
  /* Don't collapse as this makes sticky headers see through */
  border-spacing: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.Module .module-content .content table th {
  top: 0;
  border-bottom: 2px solid #272e34;
  background-color: #353c44;
  z-index: 1;
  color: #4bb3d8;
  text-align: left;
}
.Module .module-content .content table th.ranged {
  color: #fff;
  background-color: #4bb3d8;
}
.Module .module-content .content table td {
  border: solid 1px #272e34;
}
.Module .module-content .content table td a {
  color: #fff;
}
.Module .module-content .content table td .btn-link {
  padding: 0;
  padding-left: 5px;
}
.Module .module-content .content table td .btn-link .material-icons {
  color: #4bb3d8;
}
.Module .module-content .content table td.danger {
  background-color: #e22006;
}
.Module .module-content .content table td.ranged {
  background-color: #353c44;
}
.Module .module-content .content table th.edit {
  width: 20px;
}
.Module .module-content .content .content-map {
  height: 100%;
  width: 100%;
}
.Module .module-content .content nav {
  background-color: #272e34;
  z-index: 100;
  color: #fff;
}
.Module .module-content .content nav .react-contextmenu-item {
  color: #fff;
}
.Module .module-content .content nav .ok > .react-contextmenu-item {
  background-color: #4caf50;
}
.Module .module-content .content nav .warning > .react-contextmenu-item {
  background-color: orange;
}
.Module .module-content .content nav .error > .react-contextmenu-item {
  background-color: #e22006;
}
.Module .module-content .content nav .no-nodes > .react-contextmenu-item {
  padding: 0 10px;
}
.Module .module-content .content .content-graph-menu {
  text-align: center;
  background-color: #272e34;
  clear: both;
}
.Module .module-content .content .content-graph-menu .content-graph-menu-left {
  font-weight: bold;
  float: left;
  margin-left: 10px;
}
.Module .module-content .content .content-graph-menu .content-graph-menu-right {
  float: right;
  margin-right: 10px;
}
.Module .module-content .content .content-graph-menu .dropdown {
  display: inline;
  z-index: 10;
}
.Module .module-content .content .content-graph-menu .dropdown .dropdown-menu {
  background-color: #1f1f1f;
}
.Module .module-content .content .content-graph-menu .dropdown button {
  color: #fff;
  background-color: #272e34;
  border: none;
}
.Module .module-content .content .content-graph-menu .dropdown .dropdown-item:hover {
  color: #fff;
  background-color: #4bb3d8;
}
.Module .module-content .content .content-graph-menu .dropdown .dropdown-toggle {
  color: #fff;
  box-shadow: none;
}
.Module .module-content .content .content-graph-menu .dropdown .selected {
  color: #4bb3d8;
}
.Module .module-content .content .content-graph-empty {
  text-align: center;
  font-size: large;
  padding-top: 100px;
}
.Module .module-content .content .content-graph,
.Module .module-content .content .content-source-graph {
  width: 100%;
}
.Module .module-content .content .content-graph .recharts-tooltip-wrapper,
.Module .module-content .content .content-source-graph .recharts-tooltip-wrapper {
  pointer-events: auto;
  margin-left: -5px;
}
.Module .module-content .content .content-graph .recharts-tooltip-wrapper .recharts-default-tooltip,
.Module .module-content .content .content-source-graph .recharts-tooltip-wrapper .recharts-default-tooltip {
  overflow-y: auto;
  overflow-x: hidden;
}
.Module .module-content .content .content-graph .recharts-tooltip-wrapper .recharts-default-tooltip .recharts-tooltip-item-list,
.Module .module-content .content .content-source-graph .recharts-tooltip-wrapper .recharts-default-tooltip .recharts-tooltip-item-list {
  max-height: 165px;
}
.Module .module-content .content .content-graph {
  height: calc(300px - 30px);
}
.Module .module-content .content .content-source-graph {
  height: calc(300px + 230px);
}
.Module .module-content .content-full {
  width: 100%;
  overflow-y: auto;
}
.Module .module-content .content-full-with-title {
  width: 100%;
  height: calc(100% - 66px);
  overflow-y: auto;
}
.Module .module-content .close-button {
  top: 0;
  font-size: 48px;
}
.Module .module-content .item-details {
  height: 100%;
}
.Module .module-content .item-details .item-details-title {
  padding: 10px 20px;
  font-size: large;
}
.Module .module-content .item-details .item-details-title h2 {
  padding: 0;
}
.Module .module-content .item-details .item-details-title h2 .right-title {
  float: right;
  font-size: medium;
  padding-top: 14px;
}
.Module .module-content .item-details .item-create-title {
  background-color: #4bb3d8;
}
.Module .module-content .item-details .item-details-content {
  overflow: auto;
  height: calc(100% - 66px);
  background-color: #272e34;
  padding: 20px;
}
.Module .module-content .item-details .item-details-content label {
  color: #4bb3d8;
  margin-right: 5px;
}
.Module .module-content .item-details .item-details-content .ecogy-form label {
  margin-right: 0;
}
.Module .module-content .item-details .item-details-content .type {
  text-transform: capitalize;
}
.Module .module-content .item-details .item-details-content hr {
  margin: 2px;
  border-top-color: #dae0e7;
}
.Module .module-content .item-details .item-details-content .remove-button {
  margin-bottom: 20px;
}
.Module .module-content .item-details .item-details-content form > .form-group fieldset > .form-group {
  width: 50%;
  padding-right: 10px;
}
.Module .module-content .item-details .item-details-content form > .form-group > div > div > fieldset > .form-group {
  width: 50%;
  padding-right: 10px;
}
.Module .module-content .item-details .item-details-content .right-column {
  float: right;
  clear: both;
  padding-right: 0;
  padding-left: 10px;
}
.Module .module-content .content-sidebar {
  margin-left: 10px;
  float: left;
  width: 330px;
  height: 100%;
  overflow: auto;
}
.Module .module-content .content-sidebar .sidebar-section {
  margin-top: 10px;
  background-color: #353c44;
  width: 100%;
  padding: 10px;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-title-wrapper {
  display: flex;
  justify-content: space-between;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-title {
  width: 100%;
  color: #fff;
  text-transform: uppercase;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-subtitle {
  color: #aaaeb3;
  float: right;
  margin: 0;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-range {
  color: #aaaeb3;
  margin: 0;
  font-size: small;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content {
  width: 100%;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .sidebar-heading {
  font-size: xx-large;
  font-weight: bold;
  color: #4bb3d8;
  text-align: center;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .positive {
  color: #00c4a0;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .negative {
  color: #ff9b91;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .sidebar-text {
  text-align: center;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .sidebar-text .material-icons {
  float: right;
  padding-top: 10px;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .siderbar-reading {
  color: #4bb3d8;
  font-size: x-large;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .warning {
  color: orange;
}
.Module .module-content .content-sidebar .sidebar-section .sidebar-content .siderbar-error {
  color: #4bb3d8;
  font-size: large;
  text-align: center;
  padding: 10px 0;
}
.Module .module-content .content-sidebar :first-child {
  margin: 0;
}
.Module .module-content .content-sidebar .date-range {
  color: #aaaeb3;
}
.Module .module-content .content-sidebar .date-range .start-date {
  color: #fff;
}
.Module .module-content .content-sidebar .date-range .end-date {
  color: #fff;
}
.Module .module-content .content-sidebar .date-range .date-label {
  width: 50px;
}
.Module .module-content .recharts-tooltip-cursor {
  fill-opacity: 25%;
}
.Module .module-content .content-with-controls {
  height: calc(100% - 50px);
}
.Module .collapsed {
  height: calc(100% - 62px - 41px);
}

.App-portfolio .module-header {
  background-color: #4bb3d8;
  min-height: 61px;
  border-bottom: 1px solid black;
}
.App-portfolio .module-content {
  margin-top: 10px;
  height: calc(100% - 62px - 10px);
}
.App-portfolio .collapsed {
  height: calc(100% - 62px - 41px - 10px);
}

.area-graph-tooltip {
  background: #fff;
  padding: 10px;
  color: #272e34;
  text-transform: capitalize;
}
.area-graph-tooltip p {
  margin: 0;
}

.econode-tooltip .area-graph-tooltip,
.ecogy-tooltip .area-graph-tooltip {
  text-transform: none;
}

.modal {
  color: #000;
}
.modal .error {
  color: #e22006;
}

.custom-range-button {
  padding-top: 0;
  padding-bottom: 0;
}

.custom-range-name > * {
  float: left;
}
.custom-range-name .range .range-start {
  font-size: small;
}
.custom-range-name .range .range-end {
  font-size: small;
}
.custom-range-name .range .range-divider {
  display: none;
}

.date-range-menu {
  width: 700px;
  height: 370px;
  left: auto;
  right: 0;
}
.date-range-menu .custom-date-range {
  float: left;
  width: 510px;
}
.date-range-menu .custom-date-range .custom-date {
  float: left;
  width: 250px;
  margin-left: 5px;
  height: 310px;
}
.date-range-menu .precanned-date-ranges {
  float: left;
  margin-left: 10px;
  height: 100%;
  width: 170px;
  background-color: #353c44;
  border-radius: 5px;
  padding: 10px;
}
.date-range-menu .precanned-date-ranges .dropdown-item {
  padding: 0.25rem;
  border: none;
}
.date-range-menu .precanned-date-ranges .selected {
  color: #fff;
  background-color: #4bb3d8;
}
.date-range-menu .date-range-buttons {
  clear: both;
}
.date-range-menu .date-range-buttons .date-range-button {
  border-radius: 10px;
  float: right;
}
.date-range-menu .date-range-message {
  width: 100%;
  color: #4bb3d8;
  clear: both;
  padding: 8px 8px 0;
}
.date-range-menu .date-range-message .material-icons {
  color: #4bb3d8;
}
.date-range-menu .date-range-warning {
  width: 100%;
  color: #e22006;
  clear: both;
  padding: 8px 8px 0;
}
.date-range-menu .date-range-warning .material-icons {
  color: #e22006;
}

.react-select__option {
  color: rgb(29, 33, 39) !important;
}

.battery-view-header {
  text-align: center;
}

.battery-box {
  height: 150px;
  width: 200px;
  background-color: rgb(29, 33, 39);
  border-radius: 20px;
  display: grid;
  grid-template-columns: 75px 75px;
  color: grey;
}

.battery-container {
  text-align: center;
  align-items: center;
  justify-items: center;
  height: 350px;
  width: 250px;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 80px 230px;
  grid-template-rows: 80px 150px 80px;
  color: rgb(207, 205, 205);
}
.battery-container p {
  font-size: medium;
}
.battery-container .state-of-health {
  margin-top: 8px;
  margin-bottom: 40px;
  background-color: #193138;
  border-radius: 5px;
  font-size: small;
}

.graph-tail-end {
  height: 8px;
  width: 8px;
  background-color: #00C49F;
  bottom: 180px;
  left: 0px;
  position: absolute;
  border-radius: 5px;
  z-index: 100;
}

.battery-cell {
  margin-right: 10px;
  margin-left: 10px;
  background-color: #1c3841;
  height: 100px;
  width: 70px;
  position: relative;
}
.battery-cell .logo {
  position: relative;
  top: 40px;
  left: -14px;
  max-width: 100px;
  filter: opacity(0.6) drop-shadow(0 0 0 rgb(0, 0, 0));
  transform: rotate(270deg);
}
.battery-cell .charge-bar {
  margin: 5px;
  position: absolute;
  width: 60px;
  bottom: 0px;
  background-color: #4caf50;
}
.battery-cell .empty {
  visibility: hidden;
}
.battery-cell .low {
  background-color: #e22006;
}
.battery-cell .high {
  background-color: #4caf50;
}
.battery-cell .charge-bar-wrapper {
  position: absolute;
  height: 90px;
  width: 60px;
  bottom: 0px;
}
.battery-cell .charge-bar-top {
  clip-path: ellipse(30px 10px);
  height: 20px;
  position: absolute;
  width: 60px;
  top: -10px;
  box-shadow: inset 0px -6px 12px 0px hsla(0, 0%, 0%, 0.2);
}
.battery-cell .charge-bar-bottom {
  clip-path: ellipse(30px 10px);
  height: 20px;
  position: absolute;
  width: 60px;
  bottom: -10px;
}
.battery-cell .battery-cell-top {
  clip-path: ellipse(35px 10px);
  height: 20px;
  position: absolute;
  width: 70px;
  top: -10px;
  background-color: #193138;
  z-index: 100;
}
.battery-cell .battery-cell-top2 {
  clip-path: ellipse(35px 10px);
  height: 20px;
  position: absolute;
  width: 70px;
  top: -5px;
  background-color: #1c3841;
  z-index: 99;
}
.battery-cell .battery-cell-bottom {
  clip-path: ellipse(35px 10px);
  height: 20px;
  position: absolute;
  width: 70px;
  bottom: -10px;
  background-color: #1c3841;
}

.side-by-side {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}

[class|=confetti] {
  position: absolute;
  z-index: 3000;
}

.papers-origin {
  position: absolute;
  overflow: visible;
  top: 50%;
  left: 50%;
}

.confetti-0 {
  width: 4px;
  height: 1.6px;
  background-color: #0483f2;
  top: 0px;
  left: 7px;
  opacity: 0;
  transform: rotate(52.3766961969deg);
  animation: drop-0 3.2464111602s 0.5944773564s 3;
}

@keyframes drop-0 {
  25% {
    top: -24vh;
    left: -14vw;
    opacity: 1.2642469655;
  }
  100% {
    top: 50vh;
    left: 0vw;
    opacity: 0;
  }
}
.confetti-1 {
  width: 2px;
  height: 0.8px;
  background-color: #0483f2;
  top: 0px;
  left: 13px;
  opacity: 0;
  transform: rotate(254.3096314057deg);
  animation: drop-1 3.5474072479s 0.4515171402s 3;
}

@keyframes drop-1 {
  25% {
    top: -5vh;
    left: 14vw;
    opacity: 0.8891830118;
  }
  100% {
    top: 50vh;
    left: 8vw;
    opacity: 0;
  }
}
.confetti-2 {
  width: 8px;
  height: 3.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -10px;
  opacity: 0;
  transform: rotate(20.4564023679deg);
  animation: drop-2 3.5754691034s 0.0450807318s 3;
}

@keyframes drop-2 {
  25% {
    top: -5vh;
    left: -5vw;
    opacity: 1.2510970392;
  }
  100% {
    top: 50vh;
    left: 0vw;
    opacity: 0;
  }
}
.confetti-3 {
  width: 4px;
  height: 1.6px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 5px;
  opacity: 0;
  transform: rotate(352.6182078314deg);
  animation: drop-3 3.9988641307s 0.3839653019s 3;
}

@keyframes drop-3 {
  25% {
    top: -3vh;
    left: 2vw;
    opacity: 1.4488867994;
  }
  100% {
    top: 50vh;
    left: 11vw;
    opacity: 0;
  }
}
.confetti-4 {
  width: 8px;
  height: 3.2px;
  background-color: #42f212;
  top: 0px;
  left: 8px;
  opacity: 0;
  transform: rotate(67.9309396598deg);
  animation: drop-4 3.0855620645s 0.4857645454s 3;
}

@keyframes drop-4 {
  25% {
    top: -18vh;
    left: 3vw;
    opacity: 0.9783460349;
  }
  100% {
    top: 50vh;
    left: 8vw;
    opacity: 0;
  }
}
.confetti-5 {
  width: 2px;
  height: 0.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 13px;
  opacity: 0;
  transform: rotate(113.6443349025deg);
  animation: drop-5 3.9272101351s 0.7826051069s 3;
}

@keyframes drop-5 {
  25% {
    top: -19vh;
    left: 1vw;
    opacity: 1.0271898189;
  }
  100% {
    top: 50vh;
    left: -3vw;
    opacity: 0;
  }
}
.confetti-6 {
  width: 3px;
  height: 1.2px;
  background-color: #0483f2;
  top: 0px;
  left: -7px;
  opacity: 0;
  transform: rotate(47.3405135848deg);
  animation: drop-6 3.4282215175s 0.6736923308s 3;
}

@keyframes drop-6 {
  25% {
    top: -19vh;
    left: -7vw;
    opacity: 1.3290729738;
  }
  100% {
    top: 50vh;
    left: 4vw;
    opacity: 0;
  }
}
.confetti-7 {
  width: 4px;
  height: 1.6px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -13px;
  opacity: 0;
  transform: rotate(251.6997281319deg);
  animation: drop-7 3.5079230365s 0.3553604381s 3;
}

@keyframes drop-7 {
  25% {
    top: -17vh;
    left: 14vw;
    opacity: 1.2670646398;
  }
  100% {
    top: 50vh;
    left: 25vw;
    opacity: 0;
  }
}
.confetti-8 {
  width: 5px;
  height: 2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 14px;
  opacity: 0;
  transform: rotate(250.726599751deg);
  animation: drop-8 3.1181034164s 0.8308715191s 3;
}

@keyframes drop-8 {
  25% {
    top: -22vh;
    left: 1vw;
    opacity: 1.2546171674;
  }
  100% {
    top: 50vh;
    left: 1vw;
    opacity: 0;
  }
}
.confetti-9 {
  width: 3px;
  height: 1.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -11px;
  opacity: 0;
  transform: rotate(147.1029070291deg);
  animation: drop-9 3.6797358807s 0.1857074392s 3;
}

@keyframes drop-9 {
  25% {
    top: -22vh;
    left: 14vw;
    opacity: 1.1697127239;
  }
  100% {
    top: 50vh;
    left: 4vw;
    opacity: 0;
  }
}
.confetti-10 {
  width: 3px;
  height: 1.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 15px;
  opacity: 0;
  transform: rotate(76.5825912144deg);
  animation: drop-10 3.6257041006s 0.8104873119s 3;
}

@keyframes drop-10 {
  25% {
    top: -15vh;
    left: -12vw;
    opacity: 0.6753667146;
  }
  100% {
    top: 50vh;
    left: -15vw;
    opacity: 0;
  }
}
.confetti-11 {
  width: 6px;
  height: 2.4px;
  background-color: #42f212;
  top: 0px;
  left: -6px;
  opacity: 0;
  transform: rotate(61.8870239638deg);
  animation: drop-11 3.9104623261s 0.6742405191s 3;
}

@keyframes drop-11 {
  25% {
    top: -1vh;
    left: 6vw;
    opacity: 0.6010237564;
  }
  100% {
    top: 50vh;
    left: 2vw;
    opacity: 0;
  }
}
.confetti-12 {
  width: 7px;
  height: 2.8px;
  background-color: #42f212;
  top: 0px;
  left: 14px;
  opacity: 0;
  transform: rotate(183.8132512612deg);
  animation: drop-12 3.7176779233s 0.4659022402s 3;
}

@keyframes drop-12 {
  25% {
    top: -14vh;
    left: -1vw;
    opacity: 0.5159891724;
  }
  100% {
    top: 50vh;
    left: 5vw;
    opacity: 0;
  }
}
.confetti-13 {
  width: 7px;
  height: 2.8px;
  background-color: #42f212;
  top: 0px;
  left: -13px;
  opacity: 0;
  transform: rotate(64.2283085609deg);
  animation: drop-13 3.4443799661s 0.5406679912s 3;
}

@keyframes drop-13 {
  25% {
    top: -20vh;
    left: -12vw;
    opacity: 0.7386971571;
  }
  100% {
    top: 50vh;
    left: 1vw;
    opacity: 0;
  }
}
.confetti-14 {
  width: 5px;
  height: 2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 9px;
  opacity: 0;
  transform: rotate(1.5277149501deg);
  animation: drop-14 3.1375293637s 0.2463106053s 3;
}

@keyframes drop-14 {
  25% {
    top: -1vh;
    left: -13vw;
    opacity: 0.9167209134;
  }
  100% {
    top: 50vh;
    left: 2vw;
    opacity: 0;
  }
}
.confetti-15 {
  width: 1px;
  height: 0.4px;
  background-color: #0483f2;
  top: 0px;
  left: -4px;
  opacity: 0;
  transform: rotate(89.7378180433deg);
  animation: drop-15 3.7714639466s 0.8116942893s 3;
}

@keyframes drop-15 {
  25% {
    top: -12vh;
    left: 1vw;
    opacity: 0.6472320395;
  }
  100% {
    top: 50vh;
    left: 0vw;
    opacity: 0;
  }
}
.confetti-16 {
  width: 4px;
  height: 1.6px;
  background-color: #42f212;
  top: 0px;
  left: 2px;
  opacity: 0;
  transform: rotate(148.0528658866deg);
  animation: drop-16 3.609094081s 0.6117960728s 3;
}

@keyframes drop-16 {
  25% {
    top: -16vh;
    left: 10vw;
    opacity: 0.874334476;
  }
  100% {
    top: 50vh;
    left: 9vw;
    opacity: 0;
  }
}
.confetti-17 {
  width: 8px;
  height: 3.2px;
  background-color: #0483f2;
  top: 0px;
  left: -12px;
  opacity: 0;
  transform: rotate(169.9790882142deg);
  animation: drop-17 3.4800245839s 0.2305466203s 3;
}

@keyframes drop-17 {
  25% {
    top: -23vh;
    left: -14vw;
    opacity: 1.2018279244;
  }
  100% {
    top: 50vh;
    left: -13vw;
    opacity: 0;
  }
}
.confetti-18 {
  width: 6px;
  height: 2.4px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -10px;
  opacity: 0;
  transform: rotate(277.3342058937deg);
  animation: drop-18 3.5911715605s 0.5692341048s 3;
}

@keyframes drop-18 {
  25% {
    top: -25vh;
    left: 5vw;
    opacity: 0.7433897708;
  }
  100% {
    top: 50vh;
    left: 2vw;
    opacity: 0;
  }
}
.confetti-19 {
  width: 8px;
  height: 3.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 1px;
  opacity: 0;
  transform: rotate(276.6523121074deg);
  animation: drop-19 3.1322355736s 0.9370571424s 3;
}

@keyframes drop-19 {
  25% {
    top: -11vh;
    left: -1vw;
    opacity: 1.1718859335;
  }
  100% {
    top: 50vh;
    left: 5vw;
    opacity: 0;
  }
}
.confetti-20 {
  width: 2px;
  height: 0.8px;
  background-color: #0483f2;
  top: 0px;
  left: 11px;
  opacity: 0;
  transform: rotate(82.0259534266deg);
  animation: drop-20 3.10383832s 0.1596720257s 3;
}

@keyframes drop-20 {
  25% {
    top: -18vh;
    left: -3vw;
    opacity: 1.3512885622;
  }
  100% {
    top: 50vh;
    left: -4vw;
    opacity: 0;
  }
}
.confetti-21 {
  width: 1px;
  height: 0.4px;
  background-color: #0483f2;
  top: 0px;
  left: -2px;
  opacity: 0;
  transform: rotate(15.2345921519deg);
  animation: drop-21 3.7464279946s 0.1136363339s 3;
}

@keyframes drop-21 {
  25% {
    top: -15vh;
    left: 13vw;
    opacity: 1.2331319757;
  }
  100% {
    top: 50vh;
    left: 12vw;
    opacity: 0;
  }
}
.confetti-22 {
  width: 4px;
  height: 1.6px;
  background-color: #42f212;
  top: 0px;
  left: 2px;
  opacity: 0;
  transform: rotate(284.9000109047deg);
  animation: drop-22 3.7385194278s 0.071901965s 3;
}

@keyframes drop-22 {
  25% {
    top: -12vh;
    left: -12vw;
    opacity: 0.6673920511;
  }
  100% {
    top: 50vh;
    left: -23vw;
    opacity: 0;
  }
}
.confetti-23 {
  width: 4px;
  height: 1.6px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -3px;
  opacity: 0;
  transform: rotate(336.2781147758deg);
  animation: drop-23 3.0112628381s 0.6540742268s 3;
}

@keyframes drop-23 {
  25% {
    top: -6vh;
    left: -6vw;
    opacity: 1.3063339838;
  }
  100% {
    top: 50vh;
    left: -12vw;
    opacity: 0;
  }
}
.confetti-24 {
  width: 7px;
  height: 2.8px;
  background-color: #0483f2;
  top: 0px;
  left: -14px;
  opacity: 0;
  transform: rotate(296.2810395384deg);
  animation: drop-24 3.4205199181s 0.5625682068s 3;
}

@keyframes drop-24 {
  25% {
    top: -14vh;
    left: -11vw;
    opacity: 0.8178228128;
  }
  100% {
    top: 50vh;
    left: -8vw;
    opacity: 0;
  }
}
.confetti-25 {
  width: 2px;
  height: 0.8px;
  background-color: #42f212;
  top: 0px;
  left: -9px;
  opacity: 0;
  transform: rotate(106.0366679247deg);
  animation: drop-25 3.9177495929s 0.8304052898s 3;
}

@keyframes drop-25 {
  25% {
    top: -17vh;
    left: -8vw;
    opacity: 0.8392564991;
  }
  100% {
    top: 50vh;
    left: 6vw;
    opacity: 0;
  }
}
.confetti-26 {
  width: 7px;
  height: 2.8px;
  background-color: #0483f2;
  top: 0px;
  left: -7px;
  opacity: 0;
  transform: rotate(62.4170489862deg);
  animation: drop-26 3.166257409s 0.6722406945s 3;
}

@keyframes drop-26 {
  25% {
    top: -9vh;
    left: 9vw;
    opacity: 1.180351285;
  }
  100% {
    top: 50vh;
    left: 4vw;
    opacity: 0;
  }
}
.confetti-27 {
  width: 3px;
  height: 1.2px;
  background-color: #42f212;
  top: 0px;
  left: 0px;
  opacity: 0;
  transform: rotate(161.9281778774deg);
  animation: drop-27 3.4075510964s 0.9515374791s 3;
}

@keyframes drop-27 {
  25% {
    top: -24vh;
    left: 10vw;
    opacity: 0.534758825;
  }
  100% {
    top: 50vh;
    left: 25vw;
    opacity: 0;
  }
}
.confetti-28 {
  width: 3px;
  height: 1.2px;
  background-color: #0483f2;
  top: 0px;
  left: -9px;
  opacity: 0;
  transform: rotate(35.0817904444deg);
  animation: drop-28 3.5382443344s 0.4443002793s 3;
}

@keyframes drop-28 {
  25% {
    top: -15vh;
    left: 12vw;
    opacity: 1.1892041864;
  }
  100% {
    top: 50vh;
    left: 3vw;
    opacity: 0;
  }
}
.confetti-29 {
  width: 6px;
  height: 2.4px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -1px;
  opacity: 0;
  transform: rotate(0.5547739551deg);
  animation: drop-29 3.3971507636s 0.359627393s 3;
}

@keyframes drop-29 {
  25% {
    top: -18vh;
    left: -7vw;
    opacity: 1.4762040432;
  }
  100% {
    top: 50vh;
    left: -1vw;
    opacity: 0;
  }
}
.confetti-30 {
  width: 1px;
  height: 0.4px;
  background-color: #0483f2;
  top: 0px;
  left: 15px;
  opacity: 0;
  transform: rotate(83.6504633693deg);
  animation: drop-30 3.2742394834s 0.9323491547s 3;
}

@keyframes drop-30 {
  25% {
    top: -6vh;
    left: -6vw;
    opacity: 1.2032035364;
  }
  100% {
    top: 50vh;
    left: 8vw;
    opacity: 0;
  }
}
.confetti-31 {
  width: 5px;
  height: 2px;
  background-color: #42f212;
  top: 0px;
  left: -1px;
  opacity: 0;
  transform: rotate(252.1005464366deg);
  animation: drop-31 3.2408637989s 0.7348422049s 3;
}

@keyframes drop-31 {
  25% {
    top: -11vh;
    left: -2vw;
    opacity: 0.7201769642;
  }
  100% {
    top: 50vh;
    left: -6vw;
    opacity: 0;
  }
}
.confetti-32 {
  width: 7px;
  height: 2.8px;
  background-color: #0483f2;
  top: 0px;
  left: -4px;
  opacity: 0;
  transform: rotate(266.4013032524deg);
  animation: drop-32 3.6972954681s 0.6418313588s 3;
}

@keyframes drop-32 {
  25% {
    top: -23vh;
    left: 2vw;
    opacity: 1.3362577823;
  }
  100% {
    top: 50vh;
    left: -10vw;
    opacity: 0;
  }
}
.confetti-33 {
  width: 3px;
  height: 1.2px;
  background-color: #0483f2;
  top: 0px;
  left: 9px;
  opacity: 0;
  transform: rotate(235.6445325581deg);
  animation: drop-33 3.5652577869s 0.8749949824s 3;
}

@keyframes drop-33 {
  25% {
    top: -15vh;
    left: 13vw;
    opacity: 1.4314356584;
  }
  100% {
    top: 50vh;
    left: 24vw;
    opacity: 0;
  }
}
.confetti-34 {
  width: 5px;
  height: 2px;
  background-color: #42f212;
  top: 0px;
  left: -8px;
  opacity: 0;
  transform: rotate(169.7165121241deg);
  animation: drop-34 3.1242330449s 0.1943701909s 3;
}

@keyframes drop-34 {
  25% {
    top: -20vh;
    left: -6vw;
    opacity: 0.6225161104;
  }
  100% {
    top: 50vh;
    left: -11vw;
    opacity: 0;
  }
}
.confetti-35 {
  width: 3px;
  height: 1.2px;
  background-color: #0483f2;
  top: 0px;
  left: 2px;
  opacity: 0;
  transform: rotate(350.1628040775deg);
  animation: drop-35 3.3261853633s 0.8380370718s 3;
}

@keyframes drop-35 {
  25% {
    top: -9vh;
    left: 13vw;
    opacity: 0.9128310503;
  }
  100% {
    top: 50vh;
    left: 28vw;
    opacity: 0;
  }
}
.confetti-36 {
  width: 8px;
  height: 3.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 8px;
  opacity: 0;
  transform: rotate(59.6177363285deg);
  animation: drop-36 3.4822461513s 0.9711129263s 3;
}

@keyframes drop-36 {
  25% {
    top: -25vh;
    left: 14vw;
    opacity: 1.2862816767;
  }
  100% {
    top: 50vh;
    left: 20vw;
    opacity: 0;
  }
}
.confetti-37 {
  width: 1px;
  height: 0.4px;
  background-color: #0483f2;
  top: 0px;
  left: 7px;
  opacity: 0;
  transform: rotate(239.0830881467deg);
  animation: drop-37 3.1332469221s 0.4624088996s 3;
}

@keyframes drop-37 {
  25% {
    top: -14vh;
    left: -2vw;
    opacity: 1.101936872;
  }
  100% {
    top: 50vh;
    left: -13vw;
    opacity: 0;
  }
}
.confetti-38 {
  width: 4px;
  height: 1.6px;
  background-color: #0483f2;
  top: 0px;
  left: 9px;
  opacity: 0;
  transform: rotate(53.0806644492deg);
  animation: drop-38 3.986987957s 0.413078748s 3;
}

@keyframes drop-38 {
  25% {
    top: -17vh;
    left: 9vw;
    opacity: 0.5040759363;
  }
  100% {
    top: 50vh;
    left: 12vw;
    opacity: 0;
  }
}
.confetti-39 {
  width: 1px;
  height: 0.4px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -13px;
  opacity: 0;
  transform: rotate(59.8865307128deg);
  animation: drop-39 3.779459465s 0.5070462734s 3;
}

@keyframes drop-39 {
  25% {
    top: -21vh;
    left: -1vw;
    opacity: 0.7655531029;
  }
  100% {
    top: 50vh;
    left: -6vw;
    opacity: 0;
  }
}
.confetti-40 {
  width: 7px;
  height: 2.8px;
  background-color: #42f212;
  top: 0px;
  left: 15px;
  opacity: 0;
  transform: rotate(159.0554266896deg);
  animation: drop-40 3.6507433865s 0.7075300676s 3;
}

@keyframes drop-40 {
  25% {
    top: -12vh;
    left: -11vw;
    opacity: 1.0595852009;
  }
  100% {
    top: 50vh;
    left: -24vw;
    opacity: 0;
  }
}
.confetti-41 {
  width: 3px;
  height: 1.2px;
  background-color: #42f212;
  top: 0px;
  left: -2px;
  opacity: 0;
  transform: rotate(223.1663506584deg);
  animation: drop-41 3.2067317725s 0.5386328864s 3;
}

@keyframes drop-41 {
  25% {
    top: -2vh;
    left: -14vw;
    opacity: 0.8578693019;
  }
  100% {
    top: 50vh;
    left: -12vw;
    opacity: 0;
  }
}
.confetti-42 {
  width: 3px;
  height: 1.2px;
  background-color: #42f212;
  top: 0px;
  left: -14px;
  opacity: 0;
  transform: rotate(274.7723109962deg);
  animation: drop-42 3.1158139531s 0.667687947s 3;
}

@keyframes drop-42 {
  25% {
    top: -25vh;
    left: -7vw;
    opacity: 1.4849822783;
  }
  100% {
    top: 50vh;
    left: 6vw;
    opacity: 0;
  }
}
.confetti-43 {
  width: 4px;
  height: 1.6px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -1px;
  opacity: 0;
  transform: rotate(14.6694580682deg);
  animation: drop-43 3.1983639343s 0.7284922017s 3;
}

@keyframes drop-43 {
  25% {
    top: -2vh;
    left: 11vw;
    opacity: 1.0139440683;
  }
  100% {
    top: 50vh;
    left: -2vw;
    opacity: 0;
  }
}
.confetti-44 {
  width: 5px;
  height: 2px;
  background-color: #42f212;
  top: 0px;
  left: 7px;
  opacity: 0;
  transform: rotate(79.5844346117deg);
  animation: drop-44 3.5156338248s 0.247990312s 3;
}

@keyframes drop-44 {
  25% {
    top: -10vh;
    left: -9vw;
    opacity: 1.2951913746;
  }
  100% {
    top: 50vh;
    left: -10vw;
    opacity: 0;
  }
}
.confetti-45 {
  width: 2px;
  height: 0.8px;
  background-color: #42f212;
  top: 0px;
  left: -1px;
  opacity: 0;
  transform: rotate(158.6647231663deg);
  animation: drop-45 3.8405277532s 0.9177497984s 3;
}

@keyframes drop-45 {
  25% {
    top: -11vh;
    left: -8vw;
    opacity: 0.9794140079;
  }
  100% {
    top: 50vh;
    left: -8vw;
    opacity: 0;
  }
}
.confetti-46 {
  width: 8px;
  height: 3.2px;
  background-color: #42f212;
  top: 0px;
  left: -9px;
  opacity: 0;
  transform: rotate(3.498721824deg);
  animation: drop-46 3.1412175988s 0.1126374279s 3;
}

@keyframes drop-46 {
  25% {
    top: -25vh;
    left: -10vw;
    opacity: 0.7307317134;
  }
  100% {
    top: 50vh;
    left: -11vw;
    opacity: 0;
  }
}
.confetti-47 {
  width: 3px;
  height: 1.2px;
  background-color: #42f212;
  top: 0px;
  left: 9px;
  opacity: 0;
  transform: rotate(241.4642198139deg);
  animation: drop-47 3.9729015329s 0.6296658458s 3;
}

@keyframes drop-47 {
  25% {
    top: -20vh;
    left: -14vw;
    opacity: 0.7816289139;
  }
  100% {
    top: 50vh;
    left: -21vw;
    opacity: 0;
  }
}
.confetti-48 {
  width: 5px;
  height: 2px;
  background-color: #0483f2;
  top: 0px;
  left: 8px;
  opacity: 0;
  transform: rotate(100.6168290047deg);
  animation: drop-48 3.6870662378s 0.10379135s 3;
}

@keyframes drop-48 {
  25% {
    top: -24vh;
    left: -14vw;
    opacity: 1.0575482104;
  }
  100% {
    top: 50vh;
    left: -6vw;
    opacity: 0;
  }
}
.confetti-49 {
  width: 3px;
  height: 1.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 3px;
  opacity: 0;
  transform: rotate(20.0344661563deg);
  animation: drop-49 3.6817082429s 0.2197815892s 3;
}

@keyframes drop-49 {
  25% {
    top: -1vh;
    left: 12vw;
    opacity: 0.7781062907;
  }
  100% {
    top: 50vh;
    left: 3vw;
    opacity: 0;
  }
}
.confetti-50 {
  width: 7px;
  height: 2.8px;
  background-color: #42f212;
  top: 0px;
  left: -10px;
  opacity: 0;
  transform: rotate(197.9393256348deg);
  animation: drop-50 3.0226060901s 0.9354037524s 3;
}

@keyframes drop-50 {
  25% {
    top: -11vh;
    left: 9vw;
    opacity: 1.0720293768;
  }
  100% {
    top: 50vh;
    left: 13vw;
    opacity: 0;
  }
}
.confetti-51 {
  width: 8px;
  height: 3.2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 1px;
  opacity: 0;
  transform: rotate(31.567107796deg);
  animation: drop-51 3.1284823073s 0.9830616476s 3;
}

@keyframes drop-51 {
  25% {
    top: -19vh;
    left: 15vw;
    opacity: 1.1603668824;
  }
  100% {
    top: 50vh;
    left: 10vw;
    opacity: 0;
  }
}
.confetti-52 {
  width: 6px;
  height: 2.4px;
  background-color: #0483f2;
  top: 0px;
  left: -5px;
  opacity: 0;
  transform: rotate(94.5281558668deg);
  animation: drop-52 3.335420046s 0.5265992359s 3;
}

@keyframes drop-52 {
  25% {
    top: -15vh;
    left: 9vw;
    opacity: 0.897713568;
  }
  100% {
    top: 50vh;
    left: 1vw;
    opacity: 0;
  }
}
.confetti-53 {
  width: 1px;
  height: 0.4px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 0px;
  opacity: 0;
  transform: rotate(201.1770778285deg);
  animation: drop-53 3.70714441s 0.2943618825s 3;
}

@keyframes drop-53 {
  25% {
    top: -21vh;
    left: -4vw;
    opacity: 1.3841194415;
  }
  100% {
    top: 50vh;
    left: -11vw;
    opacity: 0;
  }
}
.confetti-54 {
  width: 1px;
  height: 0.4px;
  background-color: #0483f2;
  top: 0px;
  left: -2px;
  opacity: 0;
  transform: rotate(11.9370262776deg);
  animation: drop-54 3.1476635068s 0.0443760338s 3;
}

@keyframes drop-54 {
  25% {
    top: -3vh;
    left: 9vw;
    opacity: 0.6268930425;
  }
  100% {
    top: 50vh;
    left: 5vw;
    opacity: 0;
  }
}
.confetti-55 {
  width: 7px;
  height: 2.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 6px;
  opacity: 0;
  transform: rotate(125.9525688651deg);
  animation: drop-55 3.3587027427s 0.530970278s 3;
}

@keyframes drop-55 {
  25% {
    top: -19vh;
    left: 13vw;
    opacity: 0.6247492099;
  }
  100% {
    top: 50vh;
    left: 28vw;
    opacity: 0;
  }
}
.confetti-56 {
  width: 8px;
  height: 3.2px;
  background-color: #0483f2;
  top: 0px;
  left: 14px;
  opacity: 0;
  transform: rotate(323.8657431115deg);
  animation: drop-56 3.8271155072s 0.4311949441s 3;
}

@keyframes drop-56 {
  25% {
    top: -24vh;
    left: 7vw;
    opacity: 0.948871876;
  }
  100% {
    top: 50vh;
    left: 15vw;
    opacity: 0;
  }
}
.confetti-57 {
  width: 4px;
  height: 1.6px;
  background-color: #42f212;
  top: 0px;
  left: -12px;
  opacity: 0;
  transform: rotate(189.7851153589deg);
  animation: drop-57 3.5948593658s 0.6173403862s 3;
}

@keyframes drop-57 {
  25% {
    top: -23vh;
    left: 14vw;
    opacity: 1.003269389;
  }
  100% {
    top: 50vh;
    left: 18vw;
    opacity: 0;
  }
}
.confetti-58 {
  width: 3px;
  height: 1.2px;
  background-color: #42f212;
  top: 0px;
  left: -12px;
  opacity: 0;
  transform: rotate(319.880620042deg);
  animation: drop-58 3.2177519516s 0.9183736145s 3;
}

@keyframes drop-58 {
  25% {
    top: -25vh;
    left: 7vw;
    opacity: 0.7095016816;
  }
  100% {
    top: 50vh;
    left: -7vw;
    opacity: 0;
  }
}
.confetti-59 {
  width: 8px;
  height: 3.2px;
  background-color: #42f212;
  top: 0px;
  left: -7px;
  opacity: 0;
  transform: rotate(204.589830445deg);
  animation: drop-59 3.3675664591s 0.9731775775s 3;
}

@keyframes drop-59 {
  25% {
    top: -11vh;
    left: 4vw;
    opacity: 0.5739915229;
  }
  100% {
    top: 50vh;
    left: 4vw;
    opacity: 0;
  }
}
.confetti-60 {
  width: 8px;
  height: 3.2px;
  background-color: #0483f2;
  top: 0px;
  left: -3px;
  opacity: 0;
  transform: rotate(352.5997234258deg);
  animation: drop-60 3.096105664s 0.0972334122s 3;
}

@keyframes drop-60 {
  25% {
    top: -19vh;
    left: 1vw;
    opacity: 1.4018192121;
  }
  100% {
    top: 50vh;
    left: 15vw;
    opacity: 0;
  }
}
.confetti-61 {
  width: 5px;
  height: 2px;
  background-color: #42f212;
  top: 0px;
  left: 4px;
  opacity: 0;
  transform: rotate(198.3489789306deg);
  animation: drop-61 3.3730188973s 0.7912383196s 3;
}

@keyframes drop-61 {
  25% {
    top: -15vh;
    left: 3vw;
    opacity: 0.990350304;
  }
  100% {
    top: 50vh;
    left: 15vw;
    opacity: 0;
  }
}
.confetti-62 {
  width: 4px;
  height: 1.6px;
  background-color: #42f212;
  top: 0px;
  left: -8px;
  opacity: 0;
  transform: rotate(185.5682121693deg);
  animation: drop-62 3.789283568s 0.6730086681s 3;
}

@keyframes drop-62 {
  25% {
    top: -16vh;
    left: -6vw;
    opacity: 1.3088706841;
  }
  100% {
    top: 50vh;
    left: -14vw;
    opacity: 0;
  }
}
.confetti-63 {
  width: 5px;
  height: 2px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 6px;
  opacity: 0;
  transform: rotate(10.9665059969deg);
  animation: drop-63 3.0915671152s 0.9821644928s 3;
}

@keyframes drop-63 {
  25% {
    top: -7vh;
    left: 1vw;
    opacity: 0.6544672111;
  }
  100% {
    top: 50vh;
    left: 1vw;
    opacity: 0;
  }
}
.confetti-64 {
  width: 2px;
  height: 0.8px;
  background-color: #0483f2;
  top: 0px;
  left: 6px;
  opacity: 0;
  transform: rotate(28.5519856188deg);
  animation: drop-64 3.0663350469s 0.8473212553s 3;
}

@keyframes drop-64 {
  25% {
    top: -5vh;
    left: 2vw;
    opacity: 0.8157430838;
  }
  100% {
    top: 50vh;
    left: 6vw;
    opacity: 0;
  }
}
.confetti-65 {
  width: 4px;
  height: 1.6px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 9px;
  opacity: 0;
  transform: rotate(293.4091372703deg);
  animation: drop-65 3.068774464s 0.0113441664s 3;
}

@keyframes drop-65 {
  25% {
    top: -18vh;
    left: -3vw;
    opacity: 1.3968455841;
  }
  100% {
    top: 50vh;
    left: -13vw;
    opacity: 0;
  }
}
.confetti-66 {
  width: 2px;
  height: 0.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -1px;
  opacity: 0;
  transform: rotate(332.6048286673deg);
  animation: drop-66 3.194950423s 0.8336961307s 3;
}

@keyframes drop-66 {
  25% {
    top: -12vh;
    left: 12vw;
    opacity: 1.2626212043;
  }
  100% {
    top: 50vh;
    left: 23vw;
    opacity: 0;
  }
}
.confetti-67 {
  width: 5px;
  height: 2px;
  background-color: #42f212;
  top: 0px;
  left: 11px;
  opacity: 0;
  transform: rotate(332.0351334373deg);
  animation: drop-67 3.3759820839s 0.8183706244s 3;
}

@keyframes drop-67 {
  25% {
    top: -25vh;
    left: 9vw;
    opacity: 1.1284636993;
  }
  100% {
    top: 50vh;
    left: 3vw;
    opacity: 0;
  }
}
.confetti-68 {
  width: 2px;
  height: 0.8px;
  background-color: #42f212;
  top: 0px;
  left: -7px;
  opacity: 0;
  transform: rotate(173.1691294898deg);
  animation: drop-68 3.0056615153s 0.2988194479s 3;
}

@keyframes drop-68 {
  25% {
    top: -7vh;
    left: 0vw;
    opacity: 1.1080205;
  }
  100% {
    top: 50vh;
    left: -1vw;
    opacity: 0;
  }
}
.confetti-69 {
  width: 1px;
  height: 0.4px;
  background-color: #0483f2;
  top: 0px;
  left: 13px;
  opacity: 0;
  transform: rotate(286.94331749deg);
  animation: drop-69 3.3787645453s 0.6896363629s 3;
}

@keyframes drop-69 {
  25% {
    top: -18vh;
    left: -12vw;
    opacity: 1.2114193527;
  }
  100% {
    top: 50vh;
    left: 3vw;
    opacity: 0;
  }
}
.confetti-70 {
  width: 7px;
  height: 2.8px;
  background-color: #42f212;
  top: 0px;
  left: -14px;
  opacity: 0;
  transform: rotate(91.7058191755deg);
  animation: drop-70 3.3687076678s 0.7832217029s 3;
}

@keyframes drop-70 {
  25% {
    top: -15vh;
    left: -1vw;
    opacity: 1.209023515;
  }
  100% {
    top: 50vh;
    left: 12vw;
    opacity: 0;
  }
}
.confetti-71 {
  width: 2px;
  height: 0.8px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: -2px;
  opacity: 0;
  transform: rotate(282.8078717415deg);
  animation: drop-71 3.8393232177s 0.5924273173s 3;
}

@keyframes drop-71 {
  25% {
    top: -18vh;
    left: 4vw;
    opacity: 0.5616647006;
  }
  100% {
    top: 50vh;
    left: 0vw;
    opacity: 0;
  }
}
.confetti-72 {
  width: 7px;
  height: 2.8px;
  background-color: #0483f2;
  top: 0px;
  left: -3px;
  opacity: 0;
  transform: rotate(58.1238208737deg);
  animation: drop-72 3.4045241082s 0.431270257s 3;
}

@keyframes drop-72 {
  25% {
    top: -24vh;
    left: -4vw;
    opacity: 0.6950164519;
  }
  100% {
    top: 50vh;
    left: -9vw;
    opacity: 0;
  }
}
.confetti-73 {
  width: 6px;
  height: 2.4px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 13px;
  opacity: 0;
  transform: rotate(158.8871989086deg);
  animation: drop-73 3.3073556243s 0.2528305645s 3;
}

@keyframes drop-73 {
  25% {
    top: -17vh;
    left: -7vw;
    opacity: 0.8855285112;
  }
  100% {
    top: 50vh;
    left: -5vw;
    opacity: 0;
  }
}
.confetti-74 {
  width: 4px;
  height: 1.6px;
  background-color: rgba(246, 194, 38, 0.9137254902);
  top: 0px;
  left: 1px;
  opacity: 0;
  transform: rotate(102.454988874deg);
  animation: drop-74 3.6532114065s 0.723868172s 3;
}

@keyframes drop-74 {
  25% {
    top: -24vh;
    left: 6vw;
    opacity: 0.5442082096;
  }
  100% {
    top: 50vh;
    left: 12vw;
    opacity: 0;
  }
}
.confetti-75 {
  width: 3px;
  height: 1.2px;
  background-color: #42f212;
  top: 0px;
  left: -1px;
  opacity: 0;
  transform: rotate(202.6589528015deg);
  animation: drop-75 3.4587335072s 0.7048725267s 3;
}

@keyframes drop-75 {
  25% {
    top: -5vh;
    left: 0vw;
    opacity: 0.6828750819;
  }
  100% {
    top: 50vh;
    left: 6vw;
    opacity: 0;
  }
}

