$ka-background-color: #2c2c2c;
$ka-border-color: #4d4d4d;
$ka-cell-hover-background-color: transparentize(#fff, 0.8);
$ka-color-base: #fefefe;
$ka-input-background-color: $ka-background-color;
$ka-input-border-color: $ka-border-color;
$ka-input-color: $ka-color-base;
$ka-row-hover-background-color: transparentize(#fff, 0.9);
$ka-thead-background-color: #1b1b1b;
$ka-thead-color: #c5c5c5;

.amplify-checkbox__label {
    color: white;
}

.amplify-checkbox__button {
    border: 1px solid white;
}

.amplify-checkboxfield {
    margin-bottom: 8px;
}

.white-border-select {
  border-color: white !important;
}

.white-border-select label {
    color: white !important;
}

.amplify-select, .amplify-input, .amplify-textarea{
  color: lightgray;
}

.field-array-entry {
  margin-bottom: 10px;
  background-color: #1f1f1f;
  border-radius: 10px;
}

