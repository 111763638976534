/**
 * Material icon styling.
 */
.material-icons {
  -webkit-user-select: none;
  user-select: none;
}

/**
 * Popover styling.
 */
.popover {
  background-color: #475055;
}

.popover-body {
  color: #fff;
}

.popover-header {
  background-color: #4bb3d8;
  color: #fff;
}

.popover-arrow::before {
  border-top-color: #475055 !important;
}

.popover-arrow::after {
  border-top-color: #475055 !important;
}

/**
 * Generic table styling.
 */
.center-table-td, .center-table-th {
  text-align: center !important;
}

.center-table-td {
  vertical-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.sf-loader-position {
  position: absolute;
  right: 1%;
  top: 90px;
}

.disable-none {
  display: none;
}

.loader-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.EcogyTerminal .terminal-header {
  background-color: #4bb3d8;
  color: #fff;
  font-size: large;
  text-align: center;
}
.EcogyTerminal .terminal-header a {
  color: #fff;
}
.EcogyTerminal .terminal-header label {
  color: #dae0e7;
  margin: 0;
  padding: 5px 10px;
}
.EcogyTerminal .terminal-loading {
  color: #4bb3d8;
  text-align: center;
}
.EcogyTerminal .ecogy-terminal {
  height: 100%;
  width: 100%;
}
.EcogyTerminal .ecogy-terminal .alert {
  position: absolute;
  bottom: 50px;
  width: 100%;
}
.EcogyTerminal .ecogy-terminal .footer {
  background-color: #000;
  position: absolute;
  bottom: 0;
  height: 50px;
  width: 100%;
  padding: 5px;
}
.EcogyTerminal .solarnode-login {
  height: 100vh;
  margin: auto;
  padding-top: 100px;
  width: 400px;
}
.EcogyTerminal .centre-form {
  display: flex;
  align-items: center;
}
.EcogyTerminal .input-wrapper {
  margin-right: 8px;
}

