@import "../../../common/shared";

.Module .module-content {
  .content {
    .finance-table {
      position: relative;
      border-collapse: collapse;
      overflow-y: auto;

      tfoot {
        font-weight: bold;

        td {
          position: sticky;
          bottom: 0; /* Don't forget this, required for the stickiness */
          background: $ecogy-mediumgrey;
          color: $ecogy-color-active;
        }
      }

      thead th {
        position: sticky;
        top: 0; /* Don't forget this, required for the stickiness */
        background: $ecogy-mediumgrey;
      }

      th {
        // position: relative;
        .material-icons {
          position: absolute;
          right: 0;
          color: $ecogy-white;
          padding-top: 5px;
          z-index: 0;
        }
      }

      td.code {
        width: 50px;
      }

      td.name {
        width: 200px;
      }

      td.ranged {
        width: 10%;
      }

      select {
        position: relative;

        // width: auto;
        background: none;
        color: $ecogy-white;
        border: none;
        border-radius: 0;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
          "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 14px;
        font-weight: bold;
        appearance: none;
        padding-right: 20px;
        z-index: 10;
      }

      select:focus {
        background: $ecogy-color-active;
        color: $ecogy-white;
        border: none;
        border-radius: 0;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
          "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 14px;
        font-weight: bold;
        appearance: none;
        padding-right: 20px;
        z-index: 10;
      }

      .out-of-date {
        color: $ecogy-status-orange;
      }

      .category-type-total {
        background-color: black;
      }

      .category-type-category {
        font-weight: bold;
      }

      .category-type-subCategory {
        color: $ecogy-lightgrey;
      }

      .icon-list-container > div {
        width: 100%;
        text-align: center;

        svg {
          cursor: pointer;
          width: 24px;
          height: 24px;
        }

        img {
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
      }

      .cell-icon {
        position: relative;
      }
    }

    .pro-forma-inputs {
      thead tr th {
        background-color: $ecogy-blue;
        color: $ecogy-white;
      }

      th {
        text-align: left;
      }

      .account {
        font-weight: bold;
      }

      .sub-account {
        td:first-child {
          padding-left: 20px;
        }
      }

      .sub-account-title {
        td:first-child {
          text-transform: capitalize;
          padding-left: 40px;
        }
      }

      .sub-account-detail {
        td:first-child {
          padding-left: 60px;
        }

        td:nth-child(2) {
          width: 30%;
        }
      }

      .total {
        td {
          font-weight: bold;
        }
      }

      .system-type {
        text-transform: capitalize;
      }
    }

    .sources-uses {
      tr.account {
        td:first-child {
          padding-left: 20px;
        }
      }

      tr.sub-account {
        td:first-child {
          padding-left: 40px;
        }

        td {
          color: $ecogy-lightgrey;
        }
      }

      tfoot {
        font-weight: bold;

        td {
          color: $ecogy-blue;
        }
      }
    }

    .Content {
      .finance-project {
        overflow: auto;
        height: 100%;

        hr {
          border-color: $ecogy-lightgrey;
        }

        .finance-project-info {
          overflow: auto;
          clear: both;

          .finance-project-totals {
            width: 33.33%;
            float: left;
          }

          .finance-project-categories {
            width: 66.66%;
            float: left;

            .finance-project-category {
              width: 50%;
              float: left;
            }
          }
        }

        .IRRReport-content {
          padding: 0;
        }
      }
    }

    .Finance-header {
      label {
        padding-top: 10px;
      }
    }
  }

  .content-view-controls {
    .float-end.btn-toolbar {
      .btn {
        border-radius: 10px;
      }

      .material-icons {
        padding: 0;
      }
    }

    #pro-forma-toggle {
      max-width: 400px;
    }
  }
}
