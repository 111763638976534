.clipboard__button {
  appearance: none;
  border-width: 0;
  cursor: pointer;
  outline: 0;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  position: relative;
  display: grid;
}

.clipboard__icon {
  grid-column: 1;
  grid-row: 1;
}
