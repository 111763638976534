$height-header: 60px;
$height-content-header: 60px;
$height-module-header: 62px;
$bottom-content-spacing: 20px;
$content-margin: 10px;
$height-view-controls: 50px;
$height-form-controls: 60px;
$height-form-header: 66px;
$tabs-height: 58px;
$height-module-tabs: 41px;
$menu-width: 190px;
$filter-height: 100px;
$ecogy-blue: #4bb3d8;
$ecogy-navy: #1c3841;
$ecogy-paleblue: #d6e9f0;
$ecogy-purple: #988dec; // 9691c2
$ecogy-yellow: #fec87e; // f4c88d
$ecogy-pink: #ff9b91; // ea9e96
$ecogy-orange: #ec6732; // da6e48
$ecogy-green: #00c4a0; // 62b89d

$ecogy-status-red: #e22006;
$ecogy-status-green: #4caf50;
$ecogy-status-orange: orange;
$ecogy-black: #000;
$ecogy-dark: #1f1f1f;
$ecogy-darkgrey: #272e34;
$ecogy-mediumgrey: #353c44;
$ecogy-grey: #475055;
$ecogy-lightgrey: #aaaeb3;
$ecogy-palegrey: #dae0e7;
$ecogy-white: #fff;
$ecogy-color-blue: #0088fe;
$ecogy-color-green: #00c49f;
$ecogy-color-yellow: #ffbb28;
$ecogy-color-orange: #ff8042;
$ecogy-color-error: $ecogy-status-red;
$ecogy-color-controls: $ecogy-blue;
$ecogy-color-active: $ecogy-blue;
$ecogy-color-inactive: $ecogy-grey;
$ecogy-color-info: $ecogy-blue;
$ecogy-color-positive: $ecogy-green;
$ecogy-color-negative: $ecogy-pink;
$ecogy-color-highlight: $ecogy-blue;
$ecogy-color-title: $ecogy-white;
$ecogy-color-subtitle: $ecogy-lightgrey;
$ecogy-color-hover: $ecogy-white;
$color-sidebar-lightgrey: #aaaeb3;
$background-app: $ecogy-darkgrey;
$background-app-header: $ecogy-dark;
$background-sidebar: $background-app;
$background-content: $ecogy-mediumgrey;
$background-content-header: $ecogy-darkgrey;
$foreground-background-color: $ecogy-grey;
$foreground-foreground-color: $ecogy-white;
$foreground-foreground-header-color: $ecogy-white;
$foreground-border-color: $ecogy-lightgrey;
$foreground-button-color: $ecogy-white;

/**
 * Material icon styling.
 */
.material-icons {
  // Disable the ability to select/highlight material icons.
  -webkit-user-select: none;
  user-select: none;
}

/**
 * Popover styling.
 */
.popover {
  background-color: $foreground-background-color;
}

.popover-body {
  color: $foreground-foreground-color;
}

.popover-header {
  background-color: $ecogy-blue;
  color: $foreground-foreground-header-color;
}

.popover-arrow::before {
  border-top-color: $foreground-background-color !important;
}

.popover-arrow::after {
  border-top-color: $foreground-background-color !important;
}

/**
 * Generic table styling.
 */

%center-table-th {
  text-align: center !important;
}

.center-table-th {
  @extend %center-table-th;
}

.center-table-td {
  @extend %center-table-th;

  vertical-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.sf-loader-position {
  position: absolute;
  right: 1%;
  top: 90px;
}

.disable-none {
  display: none;
}

.loader-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
