@import "../../../common/shared";

%file-dropzone {
  text-align: center;
  padding: 1em;
  border: 2px dashed $ecogy-lightgrey;
  border-radius: 5px;
  margin: 1em;
}

.file-dropzone {
  @extend %file-dropzone;
}

.file-dropzone-drag {
  @extend %file-dropzone;

  border: 3px dashed $ecogy-blue;
  background-color: rgba($ecogy-blue, 0.15);
}

.dropzone-content {
  color: $ecogy-lightgrey;
}

.dropzone-content-drag {
  color: $ecogy-blue;
}
