@import "../../../../../common/shared";

%rfi-tile {
  background-color: $background-content;
  color: $foreground-foreground-color;
}

.rfi-tile {
  @extend %rfi-tile;
}

.rfi-tile-resolved {
  @extend %rfi-tile;

  color: $ecogy-status-green;
}

.rfi-tile-past-due {
  @extend %rfi-tile;

  color: $ecogy-status-red;
}
