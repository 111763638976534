.wheel-loader {
  text-align: center;
  vertical-align: middle;
}

#wheel-loader-wrapper {
  width: 300px;
  margin: 0 auto;
  text-align: center;
  white-space: pre-line;
  vertical-align: middle;
}

.cogs {
  height: auto;
  display: inline-block;
  transition: margin-top 0.3s, opacity 0.3s;
}

.cogs.visible {
  margin-top: 0;
  opacity: 1;
}

.cogs svg {
  float: left;
}

.cogs svg:first-child {
  margin-top: -10px;
}

.cogs svg:nth-child(2) {
  margin-left: -4px;
  margin-top: -6px;
}

.cogs svg:nth-child(3) {
  clear: left;
  float: left;
  margin-top: -24px;
  margin-left: 6px;
}

.cogs svg path {
  fill: hsl(var(--primary));
}

.wheel-loader progress {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  margin: 10px auto;
  clear: left;
  display: inline-block;
}

.wheel-loader progress[value]::-webkit-progress-value {
  background: hsl(var(--primary));
  border-radius: 2px;
}

.wheel-loader progress[value]::-webkit-progress-bar {
  background: hsl(var(--secondary));
  border-radius: 10px;
  border: 2px solid hsl(var(--primary));
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes rotate-opp {
  from { transform: rotate(0deg); }
  to { transform: rotate(-360deg); }
}

.cog * {
  animation: rotate 3s infinite linear;
  transform-origin: 50% 50%;
}

.cog-opp * {
  animation: rotate-opp 3s infinite linear;
  transform-origin: 50% 50%;
}

.progress-text {
  color: hsl(var(--primary));
  font-family: Inter, sans-serif;
  font-size: 18px;
  margin-top: 10px;
}

 .completion-indicator {
    text-align: center;
    vertical-align: middle;
    width: 300px;
    margin: 0 auto;
    white-space: pre-line;
  }

  .icon-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }