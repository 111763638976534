@import "../../../common/shared";

.ProcessModule {

  .process-dashboard {
    .section-title {
      padding: 10px;
      background-color: $ecogy-darkgrey;
    }

    .process-dashboard-info {
      margin: 10px;
      clear: both;

      > div {
        float: left;
        padding-right: 100px;
      }

      .process-dashboard-title {
        font-size: large;
        color: $ecogy-color-info;
      }
    }

    .process-dashboard-status {
      padding: 30px 0;
      padding-right: 20px;
      clear: both;

      table {
        border-spacing: 30px 5px;
        border-collapse: separate;

        td {
          width: 16.667%;
          border: none;
          text-align: center;
        }
      }

      .pointer {
        padding-left: 45px;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        height: 60px;
        position: relative;
        background: $ecogy-color-inactive;
      }

      .pointer::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 30px solid $ecogy-mediumgrey;
        border-top: 30px solid transparent;
        border-bottom: 30px solid transparent;
      }

      .pointer::before {
        content: "";
        position: absolute;
        right: -30px;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 30px solid $ecogy-color-inactive;
        border-top: 30px solid transparent;
        border-bottom: 30px solid transparent;
      }

      .active {
        background: $ecogy-color-info;
      }

      .active::before {
        background: $ecogy-color-info;
        border-left: 30px solid $ecogy-color-info;
        border-top: 30px solid $ecogy-mediumgrey;
        border-bottom: 30px solid $ecogy-mediumgrey;
      }
    }

    .process-dashboard-tasks {
      width: 100%;

      .tasks {
        padding-top: 10px;
        overflow: auto;
        margin: 0 auto;
        max-height: 300px;

        .active-tasks {
          height: 200px;
          clear: both;
        }

        .task-group {
          float: left;
          min-height: 100px;
          width: 300px;

          h4 {
            color: $ecogy-white;
            text-align: center;
            font-size: medium;
            font-weight: normal;
          }

          .card {
            background-color: $ecogy-grey;
            box-shadow: 2px 2px $ecogy-darkgrey;

            table {
              border: none;

              tr {
                border: none;

                td {
                  vertical-align: top;
                  border: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .process-dashboard-notes {
    .notes {
      .note {
        background-color: $ecogy-mediumgrey;
        box-shadow: 2px 2px $ecogy-darkgrey;
        margin-bottom: 10px;
        clear: both;

        .card-title {
          text-align: center;
        }

        .card-body {
          padding: 20px;
          padding-right: 10px;
        }

        button {
          background-color: $ecogy-color-active;
        }

        .note-content {
          clear: both;

          .note-header {
            clear: both;
            height: 40px;

            .note-timestamp {
              font-size: smaller;
              color: $ecogy-lightgrey;
              margin: 0;
            }

            .note-info {
              float: left;
            }

            .resolve-note {
              float: right;
              background-color: $ecogy-darkgrey;
            }
          }

          .text {
            position: relative;

            i {
              position: absolute;
              right: 0;
              display: none;
            }
          }

          .text:hover {
            color: $ecogy-lightgrey;

            i {
              display: block;
              color: $ecogy-white;
            }
          }

          .comments {
            margin-left: 20px;
            margin-bottom: 10px;

            textarea {
              background-color: $ecogy-darkgrey;
              color: $ecogy-white;
              width: calc(100% - 30px);
            }

            input {
              color: $ecogy-white;
              background-color: $ecogy-color-active;
              border: none;
              float: right;
              width: 25px;
              padding: 0;
              margin-top: 16px;
            }
          }
        }
      }
    }
  }

  .processes-calendar {
    .fc {
      height: 100%;
    }

    .fc-theme-standard {
      .fc-scrollgrid {
        border: none;
      }
    }

    .fc-theme-standard th {
      border-color: transparent;
    }

    .fc-theme-standard td.fc-today {
      background-color: $ecogy-grey;
    }

    .fc .fc-row .fc-content-skeleton td {
      border-color: transparent;
    }

    .fc-theme-standard .fc-popover {
      // position: fixed;
      display: block;
    }

    .fc-popover {
      background-color: $ecogy-black;
      border-color: $ecogy-black;

      .fc-header {
        background-color: $ecogy-blue;
      }
    }

    .fc .fc-more-popover .fc-popover-body {
      padding: 0;
    }
  }

  .target-timeline,
  .active-tasks,
  .all-process-tasks,
  .overdue-tasks,
  .early-tasks,
  .incomplete-tasks,
  .single-process-tasks {
    width: 100%;
    color: $ecogy-color-info;

    h2 {
      text-align: center;
    }

    .very-overdue {
      color: $ecogy-status-red;
    }

    .overdue {
      color: $ecogy-status-orange;
    }

    .ontime {
      color: $ecogy-status-green;
    }

    .on-track {
      color: $ecogy-status-green;
    }

    td {
      border: solid 1px $ecogy-lightgrey;
    }

    th {
      color: $ecogy-blue;
      text-transform: capitalize;
    }

    .group,
    .taskAverage {
      width: 10%;
    }

    .card {
      padding: 10px;
      background-color: $ecogy-darkgrey;
      margin: 10px;

      .card-body {
        padding: 0;
      }
    }

    .single-process-tasks-table {
      border: none;

      table {
        overflow-y: auto;
        background-color: $ecogy-mediumgrey;
      }

      .tasks-table {
        width: 100%;

        th {
          text-align: left;
        }

        th.group {
          text-align: center;
          color: $ecogy-white;
          font-size: large;
          background-color: $ecogy-blue;
        }

        td {
          border: none;
          border-top: 1px solid $ecogy-darkgrey;
        }

        .taskName {
          width: 45%;
        }

        .dueDate {
          width: 15%;
        }

        .daysElapsed {
          width: 10%;
        }

        .targetDays {
          width: 10%;
        }

        .takenToAllotedRatio {
          width: 10%;
        }

        .status {
          width: 10%;
        }
      }
    }
  }

  .incomplete-tasks {
    .overdue {
      td {
        color: $ecogy-status-orange;
      }
    }

    .ontime {
      td {
        color: $ecogy-status-green;
      }
    }
  }
}

.calendar-dot-popover {
  .popover {
    background-color: $ecogy-black;
    min-width: 300px;
    color: $ecogy-white;
    z-index: 100;
  }

  h3.complete {
    background-color: $ecogy-darkgrey;
  }

  h3.overdue {
    background-color: $ecogy-status-red;
  }

  h3.due {
    background-color: $ecogy-status-orange;
  }

  .popover-header {
    background-color: $ecogy-blue;
    color: $ecogy-white;
    max-height: 100px;
    vertical-align: middle;
  }

  .popover-body {
    padding: 10px;
    background-color: $ecogy-black;
    color: $ecogy-white;

    p {
      margin: 0;
    }

    .col,
    .col-md-3,
    .col-md-4,
    .col-md-5 {
      padding: 0;
    }

    .row.due {
      color: $ecogy-status-orange;
    }

    .row.overdue {
      color: $ecogy-status-red;
    }
  }
}
