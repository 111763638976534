@import "../common/shared";

@mixin scrollbar(){
  &::-webkit-scrollbar {
      width: 14px;
      height: 18px;
  }
  &::-webkit-scrollbar-thumb {
      height: 6px;
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 7px;
      background-color: rgba(0, 0, 0, 0.15);
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }
  &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
  }
  &::-webkit-scrollbar-corner {
      background-color: transparent;
  }

}

.power-user-content {
  height: calc(100vh - #{$height-header} - #{$height-content-header});

  .power-user-row {
    display: inline-flex; 
    width: 100%; 
    height: 100%; 
  }
  
  .graph-info {
    b {
      color: $ecogy-color-info;
    }
  }
  
  .graph-warning {
    color: $ecogy-status-orange;
  }
  
  .graph-error {
    color: $ecogy-status-red;
  }
  
  .power-user-sources-tree {
    display: inline-flex;
    
    .property-selector {
      overflow-y: scroll;
      overflow-x: visible;
      scrollbar-width: thin;
      @include scrollbar();

      .property {
          padding: 0.2rem;
          background-color: #475055;
          color: white;
      }

      .active {
        background-color: #0d6efd;
      }
    }

    .rc-tree {
      overflow-y: scroll;
      overflow-x: visible;
      scrollbar-width: thin;
      padding-right: 10px;
    }
  }

  .power-user-graph {
    height: calc(100vh - 200px);
    width: 100%;
    text-align: center;
    display: inline-flex;
    
    .select-projects-mesg p {
      font-size: 2em;
    }
    
    .linechart {
      margin: 5 10 0 0;
    }
    
    .recharts-legend-wrapper {
      max-height: 20%;
    }
    
    .recharts-tooltip-wrapper {
      pointer-events: auto;
      margin-left: -5px;
      .recharts-default-tooltip {
        overflow: hidden;
        .recharts-tooltip-item-list {
          max-height: 50vh;
        }
      }
    }
  }

}

