@import "~@fullcalendar/common/main.css";
@import "~@fullcalendar/daygrid/main.css";

.Module .module-content {
  .content {
    .bottomToolbar {
      margin-top: 5px;
    }
  }
}

.btn.btn-secondary {
  margin-right: 5px;
  margin-left: 5px;
}
