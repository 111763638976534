@import "../../shared";

/**
 * Button library specific styling.
 */

/**
 * Confirmation.tsx
 */
.confirmation-button {
  margin-bottom: 1em;
  margin-left: 1em;
  margin-right: 1em;
  border-color: $foreground-border-color !important;
  background-color: $foreground-button-color;
}

.confirmation-popover-header {
  color: $foreground-foreground-header-color !important;
  font-weight: bold;
}

.backdrop-dialog-header-text {
  font-weight: bold;
}

.backdrop-dialog-cancel {
  margin-left: 1em !important;
}
