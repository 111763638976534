/**
 * Material icon styling.
 */
.material-icons {
  -webkit-user-select: none;
  user-select: none;
}

/**
 * Popover styling.
 */
.popover {
  background-color: #475055;
}

.popover-body {
  color: #fff;
}

.popover-header {
  background-color: #4bb3d8;
  color: #fff;
}

.popover-arrow::before {
  border-top-color: #475055 !important;
}

.popover-arrow::after {
  border-top-color: #475055 !important;
}

/**
 * Generic table styling.
 */
.center-table-td, .center-table-th {
  text-align: center !important;
}

.center-table-td {
  vertical-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.sf-loader-position {
  position: absolute;
  right: 1%;
  top: 90px;
}

.disable-none {
  display: none;
}

.loader-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.download-modal .customRange {
  width: 100%;
}
.download-modal .customRange label {
  display: block;
}
.download-modal .customRange .btn-group {
  width: 100%;
}
.download-modal .customRange button {
  padding-top: 10px;
  padding-bottom: 10px;
  border-color: #4bb3d8;
}
.download-modal .customRange .custom-range-name > * {
  float: none;
}
.download-modal .customRange .custom-range-name .range .range-start {
  display: inline;
  font-size: medium;
}
.download-modal .customRange .custom-range-name .range .range-end {
  display: inline;
  font-size: medium;
}
.download-modal .customRange .custom-range-name .range .range-divider {
  display: inline;
}

.EnergyView {
  height: 100%;
}
.EnergyView h5 {
  text-align: center;
  background-color: #272e34;
  padding: 5px;
}
.EnergyView .cumulative-graph-controls {
  position: relative;
}
.EnergyView .cumulative-graph-controls .inline-graph-controls {
  position: absolute;
  right: 20px;
  top: -50px;
  clear: both;
}
.EnergyView .cumulative-graph-controls .inline-graph-controls > * {
  float: left;
  width: auto;
  vertical-align: center;
}
.EnergyView .cumulative-graph-controls .inline-graph-controls label {
  padding: 5px;
}
.EnergyView .content-main .projects-table td.projects-table__averages {
  font-weight: bold;
  text-align: right;
  color: #fff;
  background-color: #4bb3d8;
}
.EnergyView .content-view-controls .ok {
  background-color: #4caf50;
}
.EnergyView .content-view-controls .warning {
  background-color: orange;
}
.EnergyView .content-view-controls .error {
  background-color: #e22006;
}
.EnergyView .project-details h3 {
  text-align: center;
}
.EnergyView .project-details .project-details-info {
  font-size: large;
  padding: 10px 0;
}
.EnergyView .project-details .project-details-info label {
  color: #4bb3d8;
}
.EnergyView .project-details .project-details-info > :last-child {
  float: none;
  clear: both;
}
.EnergyView .details-info {
  padding-top: 5px;
}
.EnergyView .details-info label {
  color: #4bb3d8;
  vertical-align: top;
}
.EnergyView .site-content th {
  background-color: #272e34;
}
.EnergyView .asset-info {
  float: left;
}
.EnergyView .asset-info label {
  width: 120px;
}
.EnergyView .asset-info .size-warning {
  padding: 0 5px 10px;
  transform: scale(1.3);
  color: orange;
  cursor: default;
}
.EnergyView .asset-info .warning-info {
  float: right;
  margin-right: 10px;
}
.EnergyView .asset-info .warning-info .material-icons {
  color: #fec87e;
}
.EnergyView .links {
  clear: both;
}
.EnergyView .links ul {
  display: inline-block;
  list-style: none;
  padding: 0;
}
.EnergyView .generation {
  width: 50%;
}
.EnergyView .consumption {
  width: 50%;
}
.EnergyView .size {
  width: 25%;
}
.EnergyView .address label {
  width: 120px;
}
.EnergyView .accessNotes label {
  width: 120px;
}
.EnergyView .source-connect {
  min-width: 170px;
}

.source-details-tooltip {
  background-color: #000;
}
.source-details-tooltip td {
  vertical-align: top;
  padding-left: 5px;
}
.source-details-tooltip .meta-data {
  min-width: 100px;
}

.menu-error {
  color: #e22006;
}

.menu-warning {
  color: orange;
}

.tooltip {
  pointer-events: none;
}

.tooltip-error .tooltip-inner {
  color: #fff;
  background-color: #e22006;
  max-width: 400px;
}

.tooltip-warning .tooltip-inner {
  color: #fff;
  background-color: orange;
  max-width: 400px;
}

p.tooltip-detail {
  margin: 10px 0 0;
}

.text-error {
  color: #e22006;
}

.text-warning {
  color: orange;
}

.text-ok {
  color: #4caf50;
}

.timeseries-legend {
  float: right;
}
.timeseries-legend li {
  float: left;
  width: auto;
  padding-right: 30px;
}

.timeseries-counter {
  position: relative;
  text-align: center;
}
.timeseries-counter .timeseries-buttons {
  position: absolute;
  right: 5px;
  top: 5px;
}

.node-photo {
  display: flex;
  justify-content: center;
}
.node-photo .close {
  position: fixed;
  top: 0;
  right: 0;
}

.node-photo-thumb {
  width: 25%;
  float: left;
}

