/**
 * Material icon styling.
 */
.material-icons {
  -webkit-user-select: none;
  user-select: none;
}

/**
 * Popover styling.
 */
.popover {
  background-color: #475055;
}

.popover-body {
  color: #fff;
}

.popover-header {
  background-color: #4bb3d8;
  color: #fff;
}

.popover-arrow::before {
  border-top-color: #475055 !important;
}

.popover-arrow::after {
  border-top-color: #475055 !important;
}

/**
 * Generic table styling.
 */
.center-table-td, .center-table-th {
  text-align: center !important;
}

.center-table-td {
  vertical-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.sf-loader-position {
  position: absolute;
  right: 1%;
  top: 90px;
}

.disable-none {
  display: none;
}

.loader-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.user-profile {
  padding: 10px;
}
.user-profile h1 {
  text-align: center;
}
.user-profile .message {
  text-align: center;
  font-size: large;
}
.user-profile .user-notifications {
  overflow: auto;
  height: calc(100vh - 60px - 70px);
}
.user-profile .notification-content {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 60px);
}
.user-profile .notification-footer {
  position: absolute;
  bottom: 0;
  padding-bottom: 10px;
  padding-top: 10px;
  width: calc(50% - 20px);
}
.user-profile .userlist-content {
  height: calc(100vh - 70px - 56px);
  overflow: auto;
}

