/**
 * Material icon styling.
 */
.material-icons {
  -webkit-user-select: none;
  user-select: none;
}

/**
 * Popover styling.
 */
.popover {
  background-color: #475055;
}

.popover-body {
  color: #fff;
}

.popover-header {
  background-color: #4bb3d8;
  color: #fff;
}

.popover-arrow::before {
  border-top-color: #475055 !important;
}

.popover-arrow::after {
  border-top-color: #475055 !important;
}

/**
 * Generic table styling.
 */
.center-table-td, .center-table-th {
  text-align: center !important;
}

.center-table-td {
  vertical-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.sf-loader-position {
  position: absolute;
  right: 1%;
  top: 90px;
}

.disable-none {
  display: none;
}

.loader-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.Module .module-content .content .audit-log {
  width: 100%;
  table-layout: fixed;
}
.Module .module-content .content .audit-log .audit-changes {
  width: 50%;
}
.Module .module-content .content .audit-diff {
  table-layout: fixed;
  overflow-wrap: break-word;
}
.Module .module-content .content .audit-diff th {
  font-weight: normal;
}
.Module .module-content .content .audit-diff td {
  padding-right: 0.5rem;
}
.Module .module-content .content .audit-diff .original {
  width: 40%;
  color: #e22006;
}
.Module .module-content .content .audit-diff .new {
  width: 40%;
  color: #4caf50;
}
.Module .module-content .content .content-view-controls #audit-view-controls-type-toggle {
  background-color: #272e34;
  padding-right: 0;
  border-radius: 0;
}
.Module .module-content .content .content-view-controls #audit-view-controls-type-toggle i {
  padding: 0 0.5rem;
}
.Module .module-content .content .content-view-controls .audit-view-controls__dropdown-item {
  display: flex;
  justify-content: space-between;
}
.Module .module-content .content .content-view-controls .audit-view-controls__dropdown-item span {
  text-transform: capitalize;
}

