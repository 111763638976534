@import "../common/shared";
@import "@aws-amplify/ui-react/styles.css";

.login-mfa {
  display: flex;
  justify-content: center;
  width: 100%;

  .login-mfa-wrapper {
    max-width: 768px;
    margin-top: 16px;
    height: calc(100vh - 90px) !important;
    background-color: white;
    color: black;
    padding: 32px;
    border-radius: 8px;
    overflow-y: auto;
  }

  .login-mfa-body {
    margin-top: 32px;
    margin-bottom: 16px;
  }

  .mfa-code {
    color: $ecogy-color-info;
  }

  .error {
    color: $ecogy-status-red;
  }

  .login-mfa-confirm-button {
    width: 100%;
  }
}

[data-amplify-authenticator] {
  background-color: $background-app !important;
}

[data-amplify-router] {
  border-radius: 8px;
}

.amplify-button[data-variation="primary"] {
  background: $ecogy-blue;
}

.auth-header {
  top: 0;
  position: absolute;
  width: 100%;
  left: 0;
}

.auth-signin-header {
  padding: 32px 32px 0;
}

.auth-confirm-resetpassword-header {
  margin-bottom: 16px;
}

.auth-resetpassword-header {
  margin-bottom: 16px;
}
