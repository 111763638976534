@import "../../../common/shared";

/**
NodeEditor
 */
// The main class before being active.
.node-editor {
  border: 3px groove $ecogy-lightgrey;
  border-radius: 4px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: $ecogy-white;
  color: $ecogy-black;
}

// Styling across all editor buttons.
// TODO
.editor-button {
  margin-top: 1rem;
}
