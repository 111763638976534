@import "../../../common/shared";

$module-selector-height: 45px;

.Econode {
  .App-sidebar {
    .selector {
      height: calc(100% - #{$module-selector-height});
    }
  }

  .content {
    table.econode-info {
      text-align: center;

      td {
        width: 33%;
        border: none;

        p {
          font-size: large;
          color: $ecogy-color-info;
          margin: 0;
        }

        p.highlight {
          font-size: xx-large;
          margin-top: 20px;
        }
      }
    }

    .econode-info {
      clear: both;
      overflow: auto;

      .btn-report-start-date {
        margin-left: 20px;
        margin-bottom: 20px;
      }

      .econode-info-spot {
        width: calc(50% - 40px);
        margin: 20px;
        padding: 2px;
        float: left;
        background-color: $ecogy-color-info;
        color: $ecogy-white;
        border-radius: 20px;
        font-weight: bold;

        .econode-info-spot-label {
          padding-top: 2px;
          padding-left: 20px;
          font-size: large;
          display: inline-block;

          .econode-info-spot-tooltip {
            margin-right: 8px;
          }
        }

        .econode-info-spot-content {
          float: right;
          background-color: $ecogy-darkgrey;
          border-radius: 20px;
          padding: 5px 50px;
        }
      }

      .econode-info-spot-3 {
        width: calc(33.33% - 40px);

        .econode-info-spot-label {
          font-size: medium;
        }
      }
    }

    .econode-toggles {
      margin: 0 20px 10px;
      padding: 2px;

      button {
        border-radius: 10px;
      }

      button.btn.active {
        background-color: $ecogy-color-active;
      }
    }

    table.econode-readings {
      padding: 0 20px;
      border-collapse: separate;
      border-spacing: 0 20px;

      .label {
        text-align: left;
        background-color: $ecogy-color-info;
        border-radius: 20px 0 0 20px;
      }

      tr {
        th {
          color: $ecogy-white;
          text-align: center;
        }

        td {
          background-color: $background-app;
          text-align: center;
          width: 25%;
          border: 0;
          padding: 7px 20px;
        }

        td:last-child {
          border-radius: 0 20px 20px 0;
        }
      }
    }
  }
}
