/**
 * Material icon styling.
 */
.material-icons {
  -webkit-user-select: none;
  user-select: none;
}

/**
 * Popover styling.
 */
.popover {
  background-color: #475055;
}

.popover-body {
  color: #fff;
}

.popover-header {
  background-color: #4bb3d8;
  color: #fff;
}

.popover-arrow::before {
  border-top-color: #475055 !important;
}

.popover-arrow::after {
  border-top-color: #475055 !important;
}

/**
 * Generic table styling.
 */
.center-table-td, .center-table-th {
  text-align: center !important;
}

.center-table-td {
  vertical-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.sf-loader-position {
  position: absolute;
  right: 1%;
  top: 90px;
}

.disable-none {
  display: none;
}

.loader-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.standalone-preview {
  height: calc(100vh - 60px);
}

.tableButton {
  padding: 5px;
}

.preview {
  width: 100%;
  height: 100%;
}
.preview .preview-header {
  background-color: #000;
  height: 50px;
  font-size: large;
}
.preview .preview-header .preview-title {
  float: left;
  padding: 10px;
}
.preview .preveiw-with-controls {
  width: 100%;
  height: 100%;
  display: inline-flex;
  justify-content: space-around;
}
.preview .arrow {
  border: none;
  background-color: transparent;
}
.preview .preview-content {
  width: 100%;
  height: 100%;
  color: #fff;
}
.preview .preview-content .preview-unavailable {
  font-size: large;
  margin-top: 100px;
}

.Module .module-content .Assets {
  height: 100%;
  margin-top: 1px;
}
.Module .module-content .Assets h1 {
  height: 66px;
  margin: 0;
  padding-left: 35px;
}
.Module .module-content .Assets .title-bar {
  height: 66px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.Module .module-content .Assets .title-bar h1 {
  text-align: left;
}
.Module .module-content .Assets .title-bar .version-controls {
  float: right;
  width: auto;
}
.Module .module-content .Assets .pro-forma-alert .alert {
  padding: 5px;
  margin-right: 20px;
}
.Module .module-content .Assets .proforma-content {
  width: 100%;
  overflow: auto;
  padding-left: 20px;
  padding-right: 20px;
}
.Module .module-content .Assets .proforma-content .ecogy-form {
  margin-top: 0;
}
.Module .module-content .Assets .proforma-content .pro-forma-cash-flow-filters {
  position: sticky;
  top: 0;
  z-index: 5;
  background-color: #4bb3d8;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
}
.Module .module-content .Assets .proforma-content .pro-forma-cash-flow-filters div > * {
  width: auto;
  display: inline-block;
}
.Module .module-content .Assets .proforma-content .pro-forma-cash-flow-filters button {
  margin-left: 5px;
}
.Module .module-content .Assets .proforma-content .pro-forma-cash-flow-filters p {
  margin: 0;
  margin-top: 5px;
}
.Module .module-content .Assets .record-content {
  width: 100%;
  overflow: auto;
}
.Module .module-content .Assets .record-content .ecogy-form {
  margin-top: 0;
}
.Module .module-content .Assets .record-content .ecogy-form option:disabled {
  display: none;
}
.Module .module-content .Assets .record-content .dropdown-icon {
  margin-left: 10px;
}
.Module .module-content .Assets .record-content .dropdown-icon::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8px 0;
  border-color: #fff transparent transparent;
  vertical-align: middle;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
}
.Module .module-content .Assets .record-content .dropdown-icon.collapse-hide::after {
  border-width: 0 8px 8px;
  border-color: transparent transparent #fff;
}
.Module .module-content .Assets .record-content .collapse-show .material-icons {
  display: none;
}
.Module .module-content .Assets .content-with-controls .record-content {
  height: calc(100vh - 60px - 60px - 60px - 66px);
}
.Module .module-content .Assets .content-with-controls .ghost-record {
  text-transform: capitalize;
  opacity: 0.4;
}
.Module .module-content .Assets .content-with-controls .ghost-record-toggle {
  display: grid;
  grid-template-columns: repeat(3, 33.3%);
  align-items: center;
  justify-items: center;
}
.Module .module-content .Assets .content-with-controls .ghost-record-toggle span {
  width: 24px;
  text-align: center;
  grid-column-start: 2;
}
.Module .module-content .Assets .content-with-controls .ghost-record-toggle .btn {
  grid-column-start: 3;
  padding: 0;
  padding-left: 5px;
}
.Module .module-content .Assets .content-with-controls .ghost-record-toggle .btn i {
  opacity: 0.4;
}
.Module .module-content .Assets tr.document-type {
  width: 80px;
  margin: auto;
  border-radius: 5px;
  text-transform: uppercase;
  vertical-align: middle;
}
.Module .module-content .Assets tr.document-type td.document-download {
  text-align: center;
  cursor: pointer;
}
.Module .module-content .Assets tr.document-type td.document-download .material-icons {
  color: #fff;
}
.Module .module-content .Assets .document-name:hover {
  text-decoration: underline;
  cursor: pointer;
}
.Module .module-content .Assets .type-financial {
  background-color: #0088fe;
  border-color: #0088fe;
}
.Module .module-content .Assets .type-compliance {
  background-color: #ff8042;
  border-color: #ff8042;
}
.Module .module-content .Assets .type-energy {
  background-color: #00c49f;
  border-color: #00c49f;
}
.Module .module-content .Assets .selected {
  background-color: #4bb3d8;
  color: #fff;
  border-radius: 5px;
}
.Module .module-content .Assets .selected .document-type {
  border: 1px solid #fff;
}
.Module .module-content .Assets .document-details-title {
  background-color: #4bb3d8;
  padding: 20px;
}
.Module .module-content .Assets .document-details {
  background-color: #272e34;
  padding: 20px;
}
.Module .module-content .Assets .document-details label {
  color: #4bb3d8;
  margin-right: 5px;
}
.Module .module-content .Assets .document-details .type {
  text-transform: capitalize;
}
.Module .module-content .Assets .document-details hr {
  margin: 2px;
  border-top-color: #dae0e7;
}
.Module .module-content .Assets .document-contracts > h4 {
  padding: 20px 20px 15px;
}
.Module .module-content .Assets .document-contracts > div {
  padding: 20px 20px 15px;
  background-color: #272e34;
  border-bottom: solid 1px #000;
}
.Module .module-content .Assets .document-contracts > div label {
  color: #4bb3d8;
  margin-right: 5px;
}
.Module .module-content .Assets .document-contracts > div .type {
  text-transform: capitalize;
}
.Module .module-content .Assets .btn:last-child {
  margin-right: 0;
}
.Module .module-content .Assets .no-documents {
  text-align: center;
  padding-top: 100px;
  font-size: large;
  color: white;
}
.Module .module-content .Assets .Content .item-details .legal-contract-content {
  padding-right: 0;
}
.Module .module-content .Assets .Content .item-details .legal-contract-content .row {
  width: 100%;
}
.Module .module-content .Assets .Content .item-details .legal-contract-content .side-panel {
  padding: 0;
  margin: 0;
}
.Module .module-content .Assets .Content .item-details .legal-contract-content form {
  margin-right: -30px;
}
.Module .module-content .Assets .Content .item-details .legal-contract-content label {
  justify-content: left;
}
.Module .module-content .Assets .Content .item-details .legal-contract-content select {
  width: 100%;
}
.Module .module-content .Assets .type-filter .menu-sub-type {
  width: 100%;
}
.Module .module-content .Assets .type-filter .menu-sub-type > button {
  text-align: left;
}
.Module .module-content .Assets .type-filter button {
  padding: 6px 12px;
}
.Module .module-content .Assets .type-filter button:hover {
  border: none;
}
.Module .module-content .Assets .type-filter__ghost-records {
  display: flex;
  align-items: center;
}
.Module .module-content .Assets .type-filter__ghost-records .react-toggle-track-check,
.Module .module-content .Assets .type-filter__ghost-records .react-toggle-track-x {
  margin-top: 0.5rem;
}
.Module .module-content .Assets .type-filter__ghost-records span {
  padding: 3px;
}
.Module .module-content .Assets .side-panel {
  margin-left: -15px;
  margin-right: 0;
  padding-right: 0;
  margin-bottom: 60px;
}
.Module .module-content .Assets .side-panel .side-panel-content {
  background-color: #4bb3d8;
  color: #fff;
  border-radius: 10px;
  padding: 20px;
  height: 100%;
  margin-bottom: 20px;
}
.Module .module-content .Assets .side-panel .side-panel-content h3 {
  clear: both;
}
.Module .module-content .Assets .side-panel .side-panel-content .side-panel-section {
  margin-bottom: 10px;
}
.Module .module-content .Assets .side-panel .side-panel-content table {
  width: 100%;
  border: solid 1px #fff;
}
.Module .module-content .Assets .side-panel .side-panel-content table td {
  border: solid 1px #fff;
  color: #fff;
}
.Module .module-content .Assets .side-panel .side-panel-content table th {
  color: #fff;
}
.Module .module-content .Assets .side-panel .side-panel-content a {
  color: #fff;
}
.Module .module-content .Assets .side-panel .side-panel-content > div {
  padding-bottom: 20px;
}
.Module .module-content .Assets .side-panel .side-panel-content .folder {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff;
  color: #000;
  margin-bottom: 10px;
}
.Module .module-content .Assets .side-panel .side-panel-content .folder h4 {
  margin: 0;
  padding: 10px;
  background-color: #fff;
  text-align: center;
  border-bottom: solid 1px #aaaeb3;
}
.Module .module-content .Assets .side-panel .side-panel-content .folder h4:hover {
  background-color: #dae0e7;
}
.Module .module-content .Assets .side-panel .side-panel-content .folder h4 i {
  float: right;
}
.Module .module-content .Assets .side-panel .side-panel-content .folder h5 {
  padding: 10px;
  border-bottom: solid 1px #aaaeb3;
}
.Module .module-content .Assets .side-panel .side-panel-content .folder .folder-document {
  border-bottom: solid 1px #aaaeb3;
}
.Module .module-content .Assets .side-panel .side-panel-content .folder .folder-document .folder-document-click-wrapper {
  padding: 10px;
}
.Module .module-content .Assets .side-panel .side-panel-content .folder .folder-document .folder-document-click-wrapper:hover {
  background-color: #dae0e7;
}
.Module .module-content .Assets .side-panel .side-panel-content .document {
  padding: 0 0 5px 5px;
  width: 100%;
  clear: both;
}
.Module .module-content .Assets .side-panel .side-panel-content .document button {
  margin: 0 0 0 5px;
}
.Module .module-content .Assets .side-panel .side-panel-content .download {
  border-color: #fff;
  background-color: #fff;
  color: #4bb3d8;
  margin-bottom: 2px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.Module .module-content .Assets .side-panel .side-panel-content .download .material-icons {
  color: #4bb3d8;
  padding: 0;
}
.Module .module-content .Assets .side-panel .side-panel-content .missing {
  color: #e22006;
}
.Module .module-content .Assets .side-panel .side-panel-content .record-payment {
  margin-bottom: 20px;
}
.Module .module-content .Assets .side-panel .side-panel-content .record-payment h4 {
  text-transform: capitalize;
}
.Module .module-content .Assets .side-panel .side-panel-content .record-payment h5 {
  margin-top: 10px;
}
.Module .module-content .Assets .side-panel .side-panel-content .payments {
  width: 100%;
}
.Module .module-content .Assets .side-panel .side-panel-content .payments th {
  color: #fff;
}
.Module .module-content .Assets .side-panel .side-panel-content .payments .past {
  color: #dae0e7;
}
.Module .module-content .Assets .side-panel .side-panel-content table.pro-forma-costs tr td:nth-child(1) {
  width: 50%;
}
.Module .module-content .Assets .side-panel .side-panel-content table.pro-forma-costs tr td:nth-child(2) {
  width: 20%;
}
.Module .module-content .Assets .side-panel .side-panel-content table.pro-forma-irr td {
  width: 50%;
}

