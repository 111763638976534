/**
 * Material icon styling.
 */
.material-icons {
  -webkit-user-select: none;
  user-select: none;
}

/**
 * Popover styling.
 */
.popover {
  background-color: #475055;
}

.popover-body {
  color: #fff;
}

.popover-header {
  background-color: #4bb3d8;
  color: #fff;
}

.popover-arrow::before {
  border-top-color: #475055 !important;
}

.popover-arrow::after {
  border-top-color: #475055 !important;
}

/**
 * Generic table styling.
 */
.center-table-td, .center-table-th {
  text-align: center !important;
}

.center-table-td {
  vertical-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.sf-loader-position {
  position: absolute;
  right: 1%;
  top: 90px;
}

.disable-none {
  display: none;
}

.loader-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.capitalize-buttons button {
  text-transform: capitalize;
}

.Module .module-content .item-details .event-content {
  height: calc(100vh - 60px - 60px - 60px - 66px);
  width: 100%;
  overflow: auto;
}
.Module .module-content .item-details .event-content .ecogy-form {
  margin-top: 0;
}
.Module .module-content .item-details .event-priority-1 {
  background-color: #0088fe;
}
.Module .module-content .item-details .event-priority-5 {
  background-color: #00c49f;
}
.Module .module-content .item-details .event-priority-10 {
  background-color: #ffbb28;
}
.Module .module-content .item-details .event-due {
  background-color: #e22006;
}
.Module .module-content .item-details .event-unresolved {
  background-color: orange;
  color: #fff;
}
.Module .module-content .events tr.selected td {
  color: #4bb3d8;
}
.Module .module-content .events tr.selected td .event-time-local {
  color: #4bb3d8;
}
.Module .module-content .events tr.selected .file-type {
  border: 1px solid #fff;
}
.Module .module-content .events tr td.event-priority {
  background-color: #0088fe;
  color: #fff;
}
.Module .module-content .events tr td.event-priority-5 {
  background-color: #00c49f;
  color: #fff;
}
.Module .module-content .events tr td.event-priority-10 {
  background-color: #ffbb28;
  color: #fff;
}
.Module .module-content .events .due {
  background-color: #e22006;
}
.Module .module-content .events .unresolved {
  background-color: orange;
  color: #fff;
}
.Module .module-content .events table.events-table .event-cause {
  width: 30%;
}
.Module .module-content .events table.events-table .event-cause a {
  color: #4bb3d8;
}
.Module .module-content .events table.events-table td.event-type {
  text-transform: capitalize;
}
.Module .module-content .events table.events-table td.selected {
  border-radius: 0;
}
.Module .module-content .events table.events-table td .btn-link {
  color: #fff;
}
.Module .module-content .events table.events-table td.resolve {
  text-align: center;
  width: 1px;
}
.Module .module-content .events table.events-table tr.selected a {
  color: #fff;
}
.Module .module-content .events table.events-table .event-type-energy {
  color: #00c49f;
}
.Module .module-content .events table.events-table .event-type-finance {
  color: #0088fe;
}
.Module .module-content .events table.events-table .event-type-other {
  color: #ffbb28;
}
.Module .module-content .events table.events-table .event-time-local {
  color: #dae0e7;
  float: right;
}
.Module .module-content .event-tooltip {
  color: #fff;
  width: 50%;
  max-width: 600px;
  max-height: 50%;
}
.Module .module-content .event-tooltip .tooltip-inner {
  max-width: 800px;
  max-height: 50%;
}
.Module .module-content .event-tooltip p {
  margin: 0;
  padding: 0;
  color: #fff;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Module .module-content .event-tooltip .event-description {
  padding-bottom: 10px;
}
.Module .module-content .event-tooltip .note {
  color: #dae0e7;
}
.Module .module-content .event-tooltip .note em {
  color: #4bb3d8;
}
.Module .module-content .errors .panel-title {
  color: #e22006;
}
.Module .module-content .rdt .rdt-input-container {
  display: flex;
  align-items: center;
}
.Module .module-content .rdt .rdt-input-container .rdt-input-clear-button {
  right: 0;
  position: absolute;
  height: 2rem;
  width: 2rem;
  padding: 0;
  border-radius: 50%;
  border-color: transparent;
  background-color: transparent;
}
.Module .module-content .rdt .rdt-input-container .rdt-input-clear-button i {
  padding: 0;
  color: black;
}

