/* stylelint-disable scss/at-extend-no-missing-placeholder */
@import "../../../../../common/shared";

.rfi-details {
  background-color: $background-content;
  display: flex;
  flex-direction: column;
}

.rfi-details-header {
  background-color: $background-content;
  display: flex;
  flex-direction: column;
}

.rfi-details-header-resolved {
  @extend .rfi-details-header;

  color: $ecogy-green;
}

.rfi-details-header-past-due {
  @extend .rfi-details-header;

  color: $ecogy-status-red;
}

.subtitle {
  color: rgba($foreground-foreground-color, 0.5);
  margin: 0;
}

.placeholder-text {
  color: rgba($foreground-foreground-color, 0.8);
  font-style: italic;
}
