.popout {
    position: relative;
    width: 100%;
    height: 100%;
}

.popoutWindow {
    position: absolute;
    width: 500px;
    height: 450px;
    background: grey;
    border: 1px solid #ccc;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 25%);
    z-index: 101
}

.popoutHeader{
    padding: 5px;
    font-size: large;
    display: flex;
    justify-content: flex-end;
}

.popoutBody {
    text-align: center;
    align-content: center;
}

.carousel-block{
    display:flex;
    text-align: center;
    justify-content: center;
    width: 100%;
}

.carousel-container{
    overflow: hidden;
    width: 200px;
}

@keyframes animateContainer {
  from {
    transform: translateX(calc(300px));
  }
  to {
    transform: translateX(calc(-600px));
  }
}
.text-carousel {
    width: 600px;
    height: 50px;
    display: flex;
    transform: translateX(calc(100%));
    overflow: visible;
    animation-name: animateContainer;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}