@import "../../../../../../common/shared";

$cash-flow-width: 100px;
$color-forecast: $ecogy-purple;
$color-expected: $ecogy-color-green;
$color-actual: $ecogy-color-yellow;
$color-latestBestEstimate: $ecogy-color-blue;
$color-difference: $ecogy-color-orange;
$color-cumulative: $ecogy-darkgrey;
$color-category: $ecogy-lightgrey;
$color-inline: $ecogy-black;

.Module .module-content .content {
  .irr-report {
    table.total {
      width: auto;
    }

    table {
      width: 100%;
      table-layout: fixed;

      th {
        text-transform: capitalize;
        background-color: $ecogy-black;
      }

      td {
        text-align: right;
        padding-right: 0;
      }

      .spacer {
        width: calc(100vw);
      }

      span {
        width: 25%;
        min-height: 1px;
        float: left;
        display: block;
      }

      .cash-flow-forecast {
        color: $color-forecast;

        th {
          color: $ecogy-black;
        }
      }

      .cash-flow-expected {
        color: $color-expected;

        th {
          color: $ecogy-black;
        }
      }

      .cash-flow-actual {
        color: $color-actual;

        th {
          color: $ecogy-black;
        }
      }

      .cash-flow-latestBestEstimate {
        color: $color-latestBestEstimate;

        th {
          color: $ecogy-black;
        }
      }

      .cash-flow-difference {
        color: $color-difference;

        th {
          color: $ecogy-black;
        }
      }

      thead {
        position: sticky;

        tr.years {
          div {
            margin: 0 !important;
          }

          th {
            position: sticky;
            top: 0;

            .form-group {
              margin: 0;
            }

            scroll-margin-inline-start: 250px;
          }

          th:first-child {
            // position: fixed;
            z-index: 100;
            padding: 0;
          }
        }

        tr.cashflow-types {
          th {
            position: sticky;
            top: 40px;
          }
        }

        th {
          width: $cash-flow-width;
          background: white;
          border: white;
          text-align: center;
          vertical-align: top;
          z-index: 10;
        }

        th:first-child {
          left: 0;
          width: 250px;
          text-align: left;
          vertical-align: middle;
          position: sticky;
          z-index: 100;
        }
      }

      tbody {
        th:first-child {
          top: 77px;
          left: 0;
          width: 250px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          position: sticky;
          z-index: 0;
        }

        tr.irr th {
          background-color: $ecogy-color-info;
          color: $ecogy-white;
        }

        tr.total th,
        tr.total td {
          background-color: $ecogy-black;
          color: $ecogy-white;
        }

        tr.total-cumulative th {
          background-color: $color-cumulative;
          color: $ecogy-white;
        }

        tr.retained th {
          background-color: $color-forecast;
          color: $ecogy-white;
        }

        tr.category th {
          background-color: $color-category;
          color: $ecogy-white;
        }

        .irr {
          background-color: $ecogy-color-info;
          color: $ecogy-white;
        }

        .total {
          background-color: $ecogy-black;
          font-weight: bold;
        }

        .total-cumulative {
          background-color: $color-cumulative;
          color: $ecogy-white;
        }

        .actual {
          background-color: $color-actual;
          color: $ecogy-white;
        }

        .expected {
          background-color: $color-expected;
          color: $ecogy-white;
        }

        .forecast {
          background-color: $color-forecast;
          color: $ecogy-white;
        }

        .difference {
          background-color: $color-difference;
          color: $ecogy-white;
        }

        .retained {
          background-color: $color-forecast;
          color: $ecogy-white;
        }

        .category {
          background-color: $color-category;
          color: $ecogy-white;
        }

        .inline {
          background-color: $color-inline;
        }

        tr.profit-loss th {
          padding: 1px;
        }

        tr.profit-loss td {
          padding: 1px;
        }

        tr.account {
          th {
            font-size: smaller;
            font-weight: normal;
          }

          td {
            font-size: smaller;
            font-weight: lighter;
          }
        }

        tr.actual th {
          background-color: $color-actual;

          div {
            margin-top: 80px;
          }
        }

        tr.expected th {
          background-color: $color-expected;

          div {
            margin-top: 80px;
          }
        }

        tr.forecast th {
          background-color: $color-forecast;

          div {
            margin-top: 120px;
          }
        }

        tr.difference th {
          background-color: $color-difference;

          div {
            margin-top: 100px;
          }
        }

        tr.inline th {
          background-color: $color-inline;
          color: $ecogy-white;

          div {
            margin-top: 100px;
          }
        }
      }

      tr.years,
      tr.cashflow-types {
        th.after {
          font-style: italic;
          background-color: $ecogy-black;
        }
      }

      td.after {
        font-style: italic;
      }

      // th.after {
      //   background-color: #f0f0f0;
      // }

      // .edge {
      //    border-right: $ecogy-status-red solid 2px;
      // }
    }
  }

  .multi-report {
    .multi-report-content {
      .line {
        display: table;
        min-height: 20px;
        border-bottom: solid 1px $ecogy-grey;

        .line-body {
          clear: right;
          float: left;
          min-height: 20px;
          padding-right: 5px;

          .cash-flow {
            float: left;
            min-height: 20px;
          }
        }

        .title {
          display: table-cell;
          max-width: 250px;
          width: 250px;
          height: 100%;
          font-weight: bold;
          text-align: left;
          vertical-align: top;
          position: sticky;
          left: 0;
          z-index: 5;
          opacity: 1;
          padding-top: 1px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .subaccount-title {
          display: table-cell;
          max-width: 250px;
          width: 250px;
          height: 100%;
          font-weight: bold;
          text-align: left;
          vertical-align: top;
          position: sticky;
          left: 1rem;
          z-index: 5;
          opacity: 1;
          padding-top: 1px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .subtitle {
          width: 250px;
          float: left;
          min-height: 20px;
          position: sticky;
          left: 250px;
          height: 100%;
          z-index: 5;
          opacity: 1;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          .material-icons {
            float: right;
          }
        }
      }

      .years {
        background-color: $ecogy-black;
        border: none;
        position: sticky;
        top: 0;
        z-index: 10;

        div {
          margin: 0 !important;
          padding: 0;
        }

        .title,
        .subtitle {
          background-color: $ecogy-black;
          position: sticky;
          top: 0;
          height: 40px;
          z-index: 1;
        }

        .year {
          scroll-margin-left: 500px;
        }
      }

      .cashflow-types {
        background-color: $ecogy-black;
        position: sticky;
        top: 40px;
        z-index: 10;

        .title,
        .subtitle {
          background-color: $ecogy-black;
          position: sticky;
          top: 0;
          height: 40px;
          z-index: 1;
        }
      }
    }

    .inline {
      background-color: $ecogy-black;

      div {
        background-color: $ecogy-black;
      }
    }

    .category {
      background-color: $ecogy-darkgrey;

      div {
        background-color: $ecogy-darkgrey;
      }
    }

    .account {
      background-color: $ecogy-mediumgrey;

      div {
        background-color: $ecogy-mediumgrey;
      }
    }

    .sub-account {
      background-color: $ecogy-grey;

      div {
        background-color: $ecogy-grey;
      }
    }

    .project {
      border-top: $ecogy-grey;
      color: $ecogy-blue;

      div {
        background-color: $ecogy-grey;
        opacity: 0.8;
      }

      .subtitle {
        color: $ecogy-blue;
      }
    }

    .cash-flow {
      text-align: right;

      div {
        float: left;
        width: $cash-flow-width;
        min-height: 20px;
      }
    }

    .cash-flow-header {
      padding: 16px 0;
      text-align: center;
      font-size: large;

      div {
        float: left;
        color: $ecogy-white;
      }
    }

    .retained {
      background-color: $ecogy-color-blue;
      color: $ecogy-white;

      .title,
      .subtitle {
        background-color: $ecogy-color-blue;
      }
    }

    .total-aggregate {
      .title,
      .subtitle {
        background-color: $ecogy-black;
      }

      background-color: $ecogy-black;
      color: $ecogy-white;
    }

    .total-cumulative {
      .title,
      .subtitle {
        background-color: $ecogy-darkgrey;
      }

      background-color: $ecogy-darkgrey;
      color: $ecogy-white;
    }

    .irr {
      background-color: $ecogy-blue;
      color: $ecogy-white;

      .title,
      .subtitle {
        background-color: $ecogy-blue;
      }

      .project {
        color: $ecogy-white;
      }
    }

    .summary {
      .project .subtitle {
        background-color: $ecogy-grey;
      }
    }

    .cashflow-types {
      position: sticky;

      .cash-flow {
        padding: 10px 0;
        text-align: center;
      }
    }

    .cash-flow-latestBestEstimate {
      color: $color-latestBestEstimate;
    }

    .cash-flow-actual {
      color: $color-actual;
    }

    .cash-flow-expected {
      color: $color-expected;
    }

    .cash-flow-forecast {
      color: $color-forecast;
    }

    .cash-flow-difference {
      color: $color-difference;
    }

    .columns-1 {
      .summary {
        .cash-flow {
          div {
            width: 100px;
          }
        }
      }

      .cash-flow-header {
        div {
          width: 100px;
        }
      }
    }

    .columns-2 {
      .summary {
        .cash-flow {
          div {
            width: 200px;
          }
        }
      }

      .cash-flow-header {
        div {
          width: 200px;
        }
      }
    }

    .columns-3 {
      .summary {
        .cash-flow {
          div {
            width: 300px;
          }
        }
      }

      .cash-flow-header {
        div {
          width: 300px;
        }
      }
    }

    .columns-4 {
      .summary {
        .cash-flow {
          div {
            width: 400px;
          }
        }
      }

      .cash-flow-header {
        div {
          width: 400px;
        }
      }
    }

    .columns-5 {
      .summary {
        .cash-flow {
          div {
            width: 500px;
          }
        }
      }

      .cash-flow-header {
        div {
          width: 500px;
        }
      }
    }

    .hide-latestBestEstimate {
      .cash-flow-latestBestEstimate {
        display: none;
      }
    }

    .hide-actual {
      .cash-flow-actual {
        display: none;
      }
    }

    .hide-expected {
      .cash-flow-expected {
        display: none;
      }
    }

    .hide-forecast {
      .cash-flow-forecast {
        display: none;
      }
    }

    .hide-difference {
      .cash-flow-difference {
        display: none;
      }
    }

    .hide-projects {
      .project {
        display: none;
      }
    }

    .hide-accounts {
      .account {
        display: none;
      }
    }

    .hide-sub-accounts {
      .sub-account {
        display: none;
      }
    }
  }
}

.tooltip {
  .tooltip-inner {
    max-width: 800px;

    table.transactions-tooltip {
      th {
        font-weight: bold;
      }

      td {
        vertical-align: top;

        // white-space: nowrap;
        text-align: left;
        padding: 0 5px;
      }
    }
  }
}
