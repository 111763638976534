@import "../../../../../common/shared";

.content-with-controls {
  .projection-view-table {
    margin-top: 1rem;
    margin-right: 0.5rem;
    overflow: scroll hidden;

    // Left Hand Sticky Headers
    .table__sticky-headers {
      position: sticky;
      top: 0;
      left: 0;
      padding: 0;

      .table__sticky-headers__content {
        border-right: 2px solid $ecogy-white;
        padding: 0.5rem;
        font-size: 1rem;
      }
    }

    // Top Headers
    .table__quarter-headers {
      text-align: center;
      min-width: 100px;
      border-bottom: 2px solid $ecogy-white;
      font-size: 1rem;
    }

    // Table Data
    .table__data-content {
      text-align: right;
      border-right: 1px solid $ecogy-black;
      border-left: 1px solid $ecogy-black;
      font-size: 1rem;
    }
  }
}

.content-view-controls {
  .projection-view-table-selector {
    width: 15%;
  }
}

.popover {
  max-width: 500px;

  // `inner` is the container for popover-head and popover-body
  .popover-inner {
    border-radius: 5px;

    .popover-body {
      // 'border' color of the table popover, default is $ecogy-grey
      // background-color: $ecogy-blue;
      padding: 0.33rem;
      border-radius: 5px;

      table {
        margin: 0;

        th,
        td {
          font-size: 1rem;
        }
      }
    }
  }
}

.right-align {
  text-align: right;
}
