.userlist-content {
  height: calc(100vh - 60px - 56px);
  overflow: auto;
}

.user-table th.user-name {
  width: 25%;
}

.user-table td.user-name {
  padding-right: 20px;
}

.userlist-content table button.user-button {
  width: 100%;
  text-align: left;
  padding: 0 5px;
}

table .active {
  background-color: #4bb3d8;
  border-color: #4bb3d8;
}

td.active button.user-button {
  background-color: #4bb3d8;
}

