@import "../../../../common/shared";

.Module .module-content .content {
  .audit-log {
    width: 100%;
    table-layout: fixed;

    .audit-changes {
      width: 50%;
    }
  }

  .audit-diff {
    table-layout: fixed;
    overflow-wrap: break-word;

    th {
      font-weight: normal;
    }

    td {
      padding-right: 0.5rem;
    }

    .original {
      width: 40%;
      color: $ecogy-status-red;
    }

    .new {
      width: 40%;
      color: $ecogy-status-green;
    }
  }

  .content-view-controls {
    #audit-view-controls-type-toggle {
      background-color: $ecogy-darkgrey;
      padding-right: 0;
      border-radius: 0;

      i {
        padding: 0 0.5rem;
      }
    }

    .audit-view-controls__dropdown-item {
      display: flex;
      justify-content: space-between;

      span {
        text-transform: capitalize;
      }
    }
  }
}
