@import "../../../../../common/shared";

$checklist-tabs-height: 39px;

.view-checklist {
  height: 100%;
  overflow-y: auto;
  .nav {
    border-bottom: 1px solid black;

    .checklist__nav {
      color: #fff;
      background: $ecogy-mediumgrey;
      cursor: pointer;
      text-transform: capitalize;
    }

    .active {
      color: $ecogy-blue;
      font-weight: bold;
      background: $ecogy-darkgrey;
      border: 1px solid $ecogy-blue;
      border-bottom: none;
    }

  }
  .tab-content{    
    height: 100%;
    .active {
      height: calc(100% - #{$checklist-tabs-height});
      overflow-y: scroll;
    }
  }

  .ecogy-form {
    margin-top: 0;
    padding-right: 5px;

    .ecogy-form-buttons {
      margin-left: 0;
      min-height: #{$height-view-controls};

      .button-section {
        bottom: 10px;
      }
    }

    table {
      td {
        font-size: 0.9rem;
      }

      input:disabled {
        background-color: $ecogy-lightgrey;
        border-color: $ecogy-lightgrey;

        &:checked {
          background-color: #0d6efd;
          border-color: #0d6efd;
          opacity: 1;
        }
      }

      .center-align {
        text-align: center;
      }

      .table__header {
        // text-align: center;
        position: sticky;
        top: 0;
        left: 0;
        line-height: 2;
        font-size: 1rem;
        font-weight: bold;
        padding-right: 11%;
        width: 30%;
      }

      .table__header-placeholder {
        z-index: 1000;
        position: sticky;
        top: 0;
        left: 0;
        font-size: 1rem;
        font-weight: bold;
      }

      .table__check-container {
        border-left: 1px solid $ecogy-mediumgrey;
        border-right: 1px solid $ecogy-mediumgrey;

        .table__check {
          display: flex;
          justify-content: center;
          align-items: center;

          input {
            transform: scale(1.5);
          }
        }
      }

      .table__text-container {
        border-left: 1px solid $ecogy-mediumgrey;
        border-right: 1px solid $ecogy-mediumgrey;
        width: 20%;

        .table__text {
          margin-right: 10px;
          cursor: text;
        }
      }

      .no-padding {
        padding: 0;
      }
    }
  }
}
