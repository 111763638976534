/**
 * Material icon styling.
 */
.material-icons {
  -webkit-user-select: none;
  user-select: none;
}

/**
 * Popover styling.
 */
.popover {
  background-color: #475055;
}

.popover-body {
  color: #fff;
}

.popover-header {
  background-color: #4bb3d8;
  color: #fff;
}

.popover-arrow::before {
  border-top-color: #475055 !important;
}

.popover-arrow::after {
  border-top-color: #475055 !important;
}

/**
 * Generic table styling.
 */
.center-table-td, .center-table-th {
  text-align: center !important;
}

.center-table-td {
  vertical-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.sf-loader-position {
  position: absolute;
  right: 1%;
  top: 90px;
}

.disable-none {
  display: none;
}

.loader-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/**
 * Material icon styling.
 */
.material-icons {
  -webkit-user-select: none;
  user-select: none;
}

/**
 * Popover styling.
 */
.popover {
  background-color: #475055;
}

.popover-body {
  color: #fff;
}

.popover-header {
  background-color: #4bb3d8;
  color: #fff;
}

.popover-arrow::before {
  border-top-color: #475055 !important;
}

.popover-arrow::after {
  border-top-color: #475055 !important;
}

/**
 * Generic table styling.
 */
.center-table-th, .center-table-td {
  text-align: center !important;
}

.center-table-td {
  vertical-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.sf-loader-position {
  position: absolute;
  right: 1%;
  top: 90px;
}

.disable-none {
  display: none;
}

.loader-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.Module .module-content .content .irr-report table.total {
  width: auto;
}
.Module .module-content .content .irr-report table {
  width: 100%;
  table-layout: fixed;
}
.Module .module-content .content .irr-report table th {
  text-transform: capitalize;
  background-color: #000;
}
.Module .module-content .content .irr-report table td {
  text-align: right;
  padding-right: 0;
}
.Module .module-content .content .irr-report table .spacer {
  width: 100vw;
}
.Module .module-content .content .irr-report table span {
  width: 25%;
  min-height: 1px;
  float: left;
  display: block;
}
.Module .module-content .content .irr-report table .cash-flow-forecast {
  color: #988dec;
}
.Module .module-content .content .irr-report table .cash-flow-forecast th {
  color: #000;
}
.Module .module-content .content .irr-report table .cash-flow-expected {
  color: #00c49f;
}
.Module .module-content .content .irr-report table .cash-flow-expected th {
  color: #000;
}
.Module .module-content .content .irr-report table .cash-flow-actual {
  color: #ffbb28;
}
.Module .module-content .content .irr-report table .cash-flow-actual th {
  color: #000;
}
.Module .module-content .content .irr-report table .cash-flow-latestBestEstimate {
  color: #0088fe;
}
.Module .module-content .content .irr-report table .cash-flow-latestBestEstimate th {
  color: #000;
}
.Module .module-content .content .irr-report table .cash-flow-difference {
  color: #ff8042;
}
.Module .module-content .content .irr-report table .cash-flow-difference th {
  color: #000;
}
.Module .module-content .content .irr-report table thead {
  position: sticky;
}
.Module .module-content .content .irr-report table thead tr.years div {
  margin: 0 !important;
}
.Module .module-content .content .irr-report table thead tr.years th {
  position: sticky;
  top: 0;
  scroll-margin-inline-start: 250px;
}
.Module .module-content .content .irr-report table thead tr.years th .form-group {
  margin: 0;
}
.Module .module-content .content .irr-report table thead tr.years th:first-child {
  z-index: 100;
  padding: 0;
}
.Module .module-content .content .irr-report table thead tr.cashflow-types th {
  position: sticky;
  top: 40px;
}
.Module .module-content .content .irr-report table thead th {
  width: 100px;
  background: white;
  border: white;
  text-align: center;
  vertical-align: top;
  z-index: 10;
}
.Module .module-content .content .irr-report table thead th:first-child {
  left: 0;
  width: 250px;
  text-align: left;
  vertical-align: middle;
  position: sticky;
  z-index: 100;
}
.Module .module-content .content .irr-report table tbody th:first-child {
  top: 77px;
  left: 0;
  width: 250px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: sticky;
  z-index: 0;
}
.Module .module-content .content .irr-report table tbody tr.irr th {
  background-color: #4bb3d8;
  color: #fff;
}
.Module .module-content .content .irr-report table tbody tr.total th,
.Module .module-content .content .irr-report table tbody tr.total td {
  background-color: #000;
  color: #fff;
}
.Module .module-content .content .irr-report table tbody tr.total-cumulative th {
  background-color: #272e34;
  color: #fff;
}
.Module .module-content .content .irr-report table tbody tr.retained th {
  background-color: #988dec;
  color: #fff;
}
.Module .module-content .content .irr-report table tbody tr.category th {
  background-color: #aaaeb3;
  color: #fff;
}
.Module .module-content .content .irr-report table tbody .irr {
  background-color: #4bb3d8;
  color: #fff;
}
.Module .module-content .content .irr-report table tbody .total {
  background-color: #000;
  font-weight: bold;
}
.Module .module-content .content .irr-report table tbody .total-cumulative {
  background-color: #272e34;
  color: #fff;
}
.Module .module-content .content .irr-report table tbody .actual {
  background-color: #ffbb28;
  color: #fff;
}
.Module .module-content .content .irr-report table tbody .expected {
  background-color: #00c49f;
  color: #fff;
}
.Module .module-content .content .irr-report table tbody .forecast {
  background-color: #988dec;
  color: #fff;
}
.Module .module-content .content .irr-report table tbody .difference {
  background-color: #ff8042;
  color: #fff;
}
.Module .module-content .content .irr-report table tbody .retained {
  background-color: #988dec;
  color: #fff;
}
.Module .module-content .content .irr-report table tbody .category {
  background-color: #aaaeb3;
  color: #fff;
}
.Module .module-content .content .irr-report table tbody .inline {
  background-color: #000;
}
.Module .module-content .content .irr-report table tbody tr.profit-loss th {
  padding: 1px;
}
.Module .module-content .content .irr-report table tbody tr.profit-loss td {
  padding: 1px;
}
.Module .module-content .content .irr-report table tbody tr.account th {
  font-size: smaller;
  font-weight: normal;
}
.Module .module-content .content .irr-report table tbody tr.account td {
  font-size: smaller;
  font-weight: lighter;
}
.Module .module-content .content .irr-report table tbody tr.actual th {
  background-color: #ffbb28;
}
.Module .module-content .content .irr-report table tbody tr.actual th div {
  margin-top: 80px;
}
.Module .module-content .content .irr-report table tbody tr.expected th {
  background-color: #00c49f;
}
.Module .module-content .content .irr-report table tbody tr.expected th div {
  margin-top: 80px;
}
.Module .module-content .content .irr-report table tbody tr.forecast th {
  background-color: #988dec;
}
.Module .module-content .content .irr-report table tbody tr.forecast th div {
  margin-top: 120px;
}
.Module .module-content .content .irr-report table tbody tr.difference th {
  background-color: #ff8042;
}
.Module .module-content .content .irr-report table tbody tr.difference th div {
  margin-top: 100px;
}
.Module .module-content .content .irr-report table tbody tr.inline th {
  background-color: #000;
  color: #fff;
}
.Module .module-content .content .irr-report table tbody tr.inline th div {
  margin-top: 100px;
}
.Module .module-content .content .irr-report table tr.years th.after,
.Module .module-content .content .irr-report table tr.cashflow-types th.after {
  font-style: italic;
  background-color: #000;
}
.Module .module-content .content .irr-report table td.after {
  font-style: italic;
}
.Module .module-content .content .multi-report .multi-report-content .line {
  display: table;
  min-height: 20px;
  border-bottom: solid 1px #475055;
}
.Module .module-content .content .multi-report .multi-report-content .line .line-body {
  clear: right;
  float: left;
  min-height: 20px;
  padding-right: 5px;
}
.Module .module-content .content .multi-report .multi-report-content .line .line-body .cash-flow {
  float: left;
  min-height: 20px;
}
.Module .module-content .content .multi-report .multi-report-content .line .title {
  display: table-cell;
  max-width: 250px;
  width: 250px;
  height: 100%;
  font-weight: bold;
  text-align: left;
  vertical-align: top;
  position: sticky;
  left: 0;
  z-index: 5;
  opacity: 1;
  padding-top: 1px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.Module .module-content .content .multi-report .multi-report-content .line .subaccount-title {
  display: table-cell;
  max-width: 250px;
  width: 250px;
  height: 100%;
  font-weight: bold;
  text-align: left;
  vertical-align: top;
  position: sticky;
  left: 1rem;
  z-index: 5;
  opacity: 1;
  padding-top: 1px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.Module .module-content .content .multi-report .multi-report-content .line .subtitle {
  width: 250px;
  float: left;
  min-height: 20px;
  position: sticky;
  left: 250px;
  height: 100%;
  z-index: 5;
  opacity: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.Module .module-content .content .multi-report .multi-report-content .line .subtitle .material-icons {
  float: right;
}
.Module .module-content .content .multi-report .multi-report-content .years {
  background-color: #000;
  border: none;
  position: sticky;
  top: 0;
  z-index: 10;
}
.Module .module-content .content .multi-report .multi-report-content .years div {
  margin: 0 !important;
  padding: 0;
}
.Module .module-content .content .multi-report .multi-report-content .years .title,
.Module .module-content .content .multi-report .multi-report-content .years .subtitle {
  background-color: #000;
  position: sticky;
  top: 0;
  height: 40px;
  z-index: 1;
}
.Module .module-content .content .multi-report .multi-report-content .years .year {
  scroll-margin-left: 500px;
}
.Module .module-content .content .multi-report .multi-report-content .cashflow-types {
  background-color: #000;
  position: sticky;
  top: 40px;
  z-index: 10;
}
.Module .module-content .content .multi-report .multi-report-content .cashflow-types .title,
.Module .module-content .content .multi-report .multi-report-content .cashflow-types .subtitle {
  background-color: #000;
  position: sticky;
  top: 0;
  height: 40px;
  z-index: 1;
}
.Module .module-content .content .multi-report .inline {
  background-color: #000;
}
.Module .module-content .content .multi-report .inline div {
  background-color: #000;
}
.Module .module-content .content .multi-report .category {
  background-color: #272e34;
}
.Module .module-content .content .multi-report .category div {
  background-color: #272e34;
}
.Module .module-content .content .multi-report .account {
  background-color: #353c44;
}
.Module .module-content .content .multi-report .account div {
  background-color: #353c44;
}
.Module .module-content .content .multi-report .sub-account {
  background-color: #475055;
}
.Module .module-content .content .multi-report .sub-account div {
  background-color: #475055;
}
.Module .module-content .content .multi-report .project {
  border-top: #475055;
  color: #4bb3d8;
}
.Module .module-content .content .multi-report .project div {
  background-color: #475055;
  opacity: 0.8;
}
.Module .module-content .content .multi-report .project .subtitle {
  color: #4bb3d8;
}
.Module .module-content .content .multi-report .cash-flow {
  text-align: right;
}
.Module .module-content .content .multi-report .cash-flow div {
  float: left;
  width: 100px;
  min-height: 20px;
}
.Module .module-content .content .multi-report .cash-flow-header {
  padding: 16px 0;
  text-align: center;
  font-size: large;
}
.Module .module-content .content .multi-report .cash-flow-header div {
  float: left;
  color: #fff;
}
.Module .module-content .content .multi-report .retained {
  background-color: #0088fe;
  color: #fff;
}
.Module .module-content .content .multi-report .retained .title,
.Module .module-content .content .multi-report .retained .subtitle {
  background-color: #0088fe;
}
.Module .module-content .content .multi-report .total-aggregate {
  background-color: #000;
  color: #fff;
}
.Module .module-content .content .multi-report .total-aggregate .title,
.Module .module-content .content .multi-report .total-aggregate .subtitle {
  background-color: #000;
}
.Module .module-content .content .multi-report .total-cumulative {
  background-color: #272e34;
  color: #fff;
}
.Module .module-content .content .multi-report .total-cumulative .title,
.Module .module-content .content .multi-report .total-cumulative .subtitle {
  background-color: #272e34;
}
.Module .module-content .content .multi-report .irr {
  background-color: #4bb3d8;
  color: #fff;
}
.Module .module-content .content .multi-report .irr .title,
.Module .module-content .content .multi-report .irr .subtitle {
  background-color: #4bb3d8;
}
.Module .module-content .content .multi-report .irr .project {
  color: #fff;
}
.Module .module-content .content .multi-report .summary .project .subtitle {
  background-color: #475055;
}
.Module .module-content .content .multi-report .cashflow-types {
  position: sticky;
}
.Module .module-content .content .multi-report .cashflow-types .cash-flow {
  padding: 10px 0;
  text-align: center;
}
.Module .module-content .content .multi-report .cash-flow-latestBestEstimate {
  color: #0088fe;
}
.Module .module-content .content .multi-report .cash-flow-actual {
  color: #ffbb28;
}
.Module .module-content .content .multi-report .cash-flow-expected {
  color: #00c49f;
}
.Module .module-content .content .multi-report .cash-flow-forecast {
  color: #988dec;
}
.Module .module-content .content .multi-report .cash-flow-difference {
  color: #ff8042;
}
.Module .module-content .content .multi-report .columns-1 .summary .cash-flow div {
  width: 100px;
}
.Module .module-content .content .multi-report .columns-1 .cash-flow-header div {
  width: 100px;
}
.Module .module-content .content .multi-report .columns-2 .summary .cash-flow div {
  width: 200px;
}
.Module .module-content .content .multi-report .columns-2 .cash-flow-header div {
  width: 200px;
}
.Module .module-content .content .multi-report .columns-3 .summary .cash-flow div {
  width: 300px;
}
.Module .module-content .content .multi-report .columns-3 .cash-flow-header div {
  width: 300px;
}
.Module .module-content .content .multi-report .columns-4 .summary .cash-flow div {
  width: 400px;
}
.Module .module-content .content .multi-report .columns-4 .cash-flow-header div {
  width: 400px;
}
.Module .module-content .content .multi-report .columns-5 .summary .cash-flow div {
  width: 500px;
}
.Module .module-content .content .multi-report .columns-5 .cash-flow-header div {
  width: 500px;
}
.Module .module-content .content .multi-report .hide-latestBestEstimate .cash-flow-latestBestEstimate {
  display: none;
}
.Module .module-content .content .multi-report .hide-actual .cash-flow-actual {
  display: none;
}
.Module .module-content .content .multi-report .hide-expected .cash-flow-expected {
  display: none;
}
.Module .module-content .content .multi-report .hide-forecast .cash-flow-forecast {
  display: none;
}
.Module .module-content .content .multi-report .hide-difference .cash-flow-difference {
  display: none;
}
.Module .module-content .content .multi-report .hide-projects .project {
  display: none;
}
.Module .module-content .content .multi-report .hide-accounts .account {
  display: none;
}
.Module .module-content .content .multi-report .hide-sub-accounts .sub-account {
  display: none;
}

.tooltip .tooltip-inner {
  max-width: 800px;
}
.tooltip .tooltip-inner table.transactions-tooltip th {
  font-weight: bold;
}
.tooltip .tooltip-inner table.transactions-tooltip td {
  vertical-align: top;
  text-align: left;
  padding: 0 5px;
}

.Module .module-content .IRRReport {
  height: calc(100vh - 60px);
}
.Module .module-content .IRRReport table tr.cashflow-types th {
  text-align: center;
}
.Module .module-content .IRRReport table .cash-flow-latestBestEstimate {
  color: #0088fe;
}
.Module .module-content .IRRReport table .cash-flow-latestBestEstimate th {
  color: #000;
}
.Module .module-content .IRRReport table .cash-flow-actual {
  color: #ffbb28;
}
.Module .module-content .IRRReport table .cash-flow-actual th {
  color: #000;
}
.Module .module-content .IRRReport table .cash-flow-expected {
  color: #00c49f;
}
.Module .module-content .IRRReport table .cash-flow-expected th {
  color: #000;
}
.Module .module-content .IRRReport table .cash-flow-forecast {
  color: #988dec;
}
.Module .module-content .IRRReport table .cash-flow-forecast th {
  color: #000;
}
.Module .module-content .IRRReport table .cash-flow-difference {
  color: #ff8042;
}
.Module .module-content .IRRReport table .cash-flow-difference th {
  color: #000;
}
.Module .module-content .IRRReport .error {
  color: #e22006;
}
.Module .module-content .IRRReport h1 {
  color: #4bb3d8;
  text-align: center;
}
.Module .module-content .IRRReport .IRRReport-controls {
  float: left;
  padding-top: 5px;
}
.Module .module-content .IRRReport .IRRReport-controls > * {
  float: left;
  width: auto;
}
.Module .module-content .IRRReport .IRRReport-options {
  position: absolute;
  right: 50px;
  padding-top: 5px;
}
.Module .module-content .IRRReport .IRRReport-options > * {
  float: right;
}
.Module .module-content .IRRReport .IRRReport-options * {
  display: inline-block;
  width: auto;
}
.Module .module-content .IRRReport .IRRReport-options span {
  padding: 8px;
  margin-right: 20px;
}
.Module .module-content .IRRReport .IRRReport-content .col {
  padding: 0;
}
.Module .module-content .IRRReport .IRRReport-content .scroller {
  padding-bottom: 5px;
}
.Module .module-content .IRRReport .IRRReport-content table {
  overflow: auto;
}
.Module .module-content .IRRReport .IRRReport-content table tr.years th {
  color: #fff;
  text-align: center;
  background-color: #272e34;
  border: 1px solid #585c61;
}
.Module .module-content .IRRReport .IRRReport-content table tr.cashflow-types th {
  background-color: #272e34;
}
.Module .module-content .IRRReport .IRRReport-content table th {
  border: 1px solid #585c61;
  color: #fff;
}
.Module .module-content .IRRReport .IRRReport-content .table-striped tbody tr.profit-loss:nth-of-type(odd) {
  background-color: #272e34;
}
.Module .module-content .IRRReport .IRRReport-content .table-striped tbody tr.profit-loss:nth-of-type(odd) th {
  background-color: #272e34;
  color: #4bb3d8;
}
.Module .module-content .IRRReport .IRRReport-content .table-striped tbody tr.account:nth-of-type(odd) th {
  color: #fff;
}
.Module .module-content .IRRReport .IRRReport-content .table-striped tbody tr.cumulative:nth-of-type(odd) th {
  color: #aaaeb3;
}
.Module .module-content .IRRReport .IRRReport-content .table-striped tbody tr.profit-loss:nth-of-type(even) {
  background-color: #1f1f1f;
}
.Module .module-content .IRRReport .IRRReport-content .table-striped tbody tr.profit-loss:nth-of-type(even) th {
  background-color: #1f1f1f;
  color: #4bb3d8;
}
.Module .module-content .IRRReport .IRRReport-content .table-striped tbody tr.account:nth-of-type(even) th {
  color: #fff;
}
.Module .module-content .IRRReport .IRRReport-content .table-striped tbody tr.cumulative:nth-of-type(even) th {
  color: #aaaeb3;
}
.Module .module-content .IRRReport .IRRReport-content .irr-report table tbody tr.category th {
  background-color: #353c44;
  color: #fff;
}
.Module .module-content .IRRReport .IRRReport-content .irr-report table tbody .category {
  background-color: #353c44;
  color: #fff;
}
.Module .module-content .IRRReport .IRRReport-content .irr-report select {
  color: #fff;
  background: #1f1f1f;
}
.Module .module-content .IRRReport .IRRReport-content .multi-report table tbody tr.account th {
  border-bottom: #272e34;
}
.Module .module-content .IRRReport .IRRReport-content .multi-report table tbody tr.project th {
  background-color: #272e34;
  border-top: #272e34;
  border-bottom: #272e34;
}
.Module .module-content .IRRReport .IRRReport-content .multi-report table tbody tr.retained th,
.Module .module-content .IRRReport .IRRReport-content .multi-report table tbody tr.retained td {
  background-color: #988dec;
  border-color: #988dec;
}
.Module .module-content .IRRReport .IRRReport-content .multi-report .table-striped tbody tr.project th,
.Module .module-content .IRRReport .IRRReport-content .multi-report .table-striped tbody tr.project th:nth-of-type(even),
.Module .module-content .IRRReport .IRRReport-content .multi-report .table-striped tbody tr.account th,
.Module .module-content .IRRReport .IRRReport-content .multi-report .table-striped tbody tr.account th:nth-of-type(even) {
  background-color: #272e34;
}
.Module .module-content .IRRReport .IRRReport-content .multi-report .table-striped tbody tr.retained th,
.Module .module-content .IRRReport .IRRReport-content .multi-report .table-striped tbody tr.retained th:nth-of-type(even) {
  background-color: #988dec;
  border-color: #988dec;
}
.Module form.irr-report-column-controls {
  float: left;
  border: 1px transparent solid;
}
.Module form.irr-report-column-controls .form-check {
  margin-bottom: 0px;
}
.Module form.irr-report-column-controls .form-check-inline {
  padding-left: 5px;
}
.Module form.irr-report-column-controls .form-check-label-color {
  margin-top: -7px;
}
.Module form.irr-report-column-controls .form-check-input {
  margin-left: 2px;
}
.Module form.irr-report-column-controls .control-forecast-group > * {
  display: flex;
}
.Module form.irr-report-column-controls .control-forecast-label {
  margin-top: 5px;
  padding: 0 2px;
}
.Module form.irr-report-column-controls .control-latestBestEstimate {
  background-color: #0088fe;
}
.Module form.irr-report-column-controls .control-actual {
  background-color: #ffbb28;
}
.Module form.irr-report-column-controls .control-expected {
  background-color: #00c49f;
}
.Module form.irr-report-column-controls .control-forecast {
  background-color: #988dec;
}
.Module form.irr-report-column-controls .control-difference {
  background-color: #ff8042;
}
.Module .module-content .content-view-controls label.irr-report-account-controls {
  margin-top: 3px;
}
.Module .module-content .content-view-controls .report-flag-controls .dropdown-menu {
  width: 230px;
  padding: 10px;
}
.Module .module-content .content-view-controls .control-forecast__proforma-check {
  margin-top: 0.5rem;
}
.Module .hidden {
  display: none;
}

