/**
 * Material icon styling.
 */
.material-icons {
  -webkit-user-select: none;
  user-select: none;
}

/**
 * Popover styling.
 */
.popover {
  background-color: #475055;
}

.popover-body {
  color: #fff;
}

.popover-header {
  background-color: #4bb3d8;
  color: #fff;
}

.popover-arrow::before {
  border-top-color: #475055 !important;
}

.popover-arrow::after {
  border-top-color: #475055 !important;
}

/**
 * Generic table styling.
 */
.center-table-td, .center-table-th {
  text-align: center !important;
}

.center-table-td {
  vertical-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.sf-loader-position {
  position: absolute;
  right: 1%;
  top: 90px;
}

.disable-none {
  display: none;
}

.loader-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ProcessModule .process-dashboard .section-title {
  padding: 10px;
  background-color: #272e34;
}
.ProcessModule .process-dashboard .process-dashboard-info {
  margin: 10px;
  clear: both;
}
.ProcessModule .process-dashboard .process-dashboard-info > div {
  float: left;
  padding-right: 100px;
}
.ProcessModule .process-dashboard .process-dashboard-info .process-dashboard-title {
  font-size: large;
  color: #4bb3d8;
}
.ProcessModule .process-dashboard .process-dashboard-status {
  padding: 30px 0;
  padding-right: 20px;
  clear: both;
}
.ProcessModule .process-dashboard .process-dashboard-status table {
  border-spacing: 30px 5px;
  border-collapse: separate;
}
.ProcessModule .process-dashboard .process-dashboard-status table td {
  width: 16.667%;
  border: none;
  text-align: center;
}
.ProcessModule .process-dashboard .process-dashboard-status .pointer {
  padding-left: 45px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  height: 60px;
  position: relative;
  background: #475055;
}
.ProcessModule .process-dashboard .process-dashboard-status .pointer::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 30px solid #353c44;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
}
.ProcessModule .process-dashboard .process-dashboard-status .pointer::before {
  content: "";
  position: absolute;
  right: -30px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 30px solid #475055;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
}
.ProcessModule .process-dashboard .process-dashboard-status .active {
  background: #4bb3d8;
}
.ProcessModule .process-dashboard .process-dashboard-status .active::before {
  background: #4bb3d8;
  border-left: 30px solid #4bb3d8;
  border-top: 30px solid #353c44;
  border-bottom: 30px solid #353c44;
}
.ProcessModule .process-dashboard .process-dashboard-tasks {
  width: 100%;
}
.ProcessModule .process-dashboard .process-dashboard-tasks .tasks {
  padding-top: 10px;
  overflow: auto;
  margin: 0 auto;
  max-height: 300px;
}
.ProcessModule .process-dashboard .process-dashboard-tasks .tasks .active-tasks {
  height: 200px;
  clear: both;
}
.ProcessModule .process-dashboard .process-dashboard-tasks .tasks .task-group {
  float: left;
  min-height: 100px;
  width: 300px;
}
.ProcessModule .process-dashboard .process-dashboard-tasks .tasks .task-group h4 {
  color: #fff;
  text-align: center;
  font-size: medium;
  font-weight: normal;
}
.ProcessModule .process-dashboard .process-dashboard-tasks .tasks .task-group .card {
  background-color: #475055;
  box-shadow: 2px 2px #272e34;
}
.ProcessModule .process-dashboard .process-dashboard-tasks .tasks .task-group .card table {
  border: none;
}
.ProcessModule .process-dashboard .process-dashboard-tasks .tasks .task-group .card table tr {
  border: none;
}
.ProcessModule .process-dashboard .process-dashboard-tasks .tasks .task-group .card table tr td {
  vertical-align: top;
  border: none;
}
.ProcessModule .process-dashboard-notes .notes .note {
  background-color: #353c44;
  box-shadow: 2px 2px #272e34;
  margin-bottom: 10px;
  clear: both;
}
.ProcessModule .process-dashboard-notes .notes .note .card-title {
  text-align: center;
}
.ProcessModule .process-dashboard-notes .notes .note .card-body {
  padding: 20px;
  padding-right: 10px;
}
.ProcessModule .process-dashboard-notes .notes .note button {
  background-color: #4bb3d8;
}
.ProcessModule .process-dashboard-notes .notes .note .note-content {
  clear: both;
}
.ProcessModule .process-dashboard-notes .notes .note .note-content .note-header {
  clear: both;
  height: 40px;
}
.ProcessModule .process-dashboard-notes .notes .note .note-content .note-header .note-timestamp {
  font-size: smaller;
  color: #aaaeb3;
  margin: 0;
}
.ProcessModule .process-dashboard-notes .notes .note .note-content .note-header .note-info {
  float: left;
}
.ProcessModule .process-dashboard-notes .notes .note .note-content .note-header .resolve-note {
  float: right;
  background-color: #272e34;
}
.ProcessModule .process-dashboard-notes .notes .note .note-content .text {
  position: relative;
}
.ProcessModule .process-dashboard-notes .notes .note .note-content .text i {
  position: absolute;
  right: 0;
  display: none;
}
.ProcessModule .process-dashboard-notes .notes .note .note-content .text:hover {
  color: #aaaeb3;
}
.ProcessModule .process-dashboard-notes .notes .note .note-content .text:hover i {
  display: block;
  color: #fff;
}
.ProcessModule .process-dashboard-notes .notes .note .note-content .comments {
  margin-left: 20px;
  margin-bottom: 10px;
}
.ProcessModule .process-dashboard-notes .notes .note .note-content .comments textarea {
  background-color: #272e34;
  color: #fff;
  width: calc(100% - 30px);
}
.ProcessModule .process-dashboard-notes .notes .note .note-content .comments input {
  color: #fff;
  background-color: #4bb3d8;
  border: none;
  float: right;
  width: 25px;
  padding: 0;
  margin-top: 16px;
}
.ProcessModule .processes-calendar .fc {
  height: 100%;
}
.ProcessModule .processes-calendar .fc-theme-standard .fc-scrollgrid {
  border: none;
}
.ProcessModule .processes-calendar .fc-theme-standard th {
  border-color: transparent;
}
.ProcessModule .processes-calendar .fc-theme-standard td.fc-today {
  background-color: #475055;
}
.ProcessModule .processes-calendar .fc .fc-row .fc-content-skeleton td {
  border-color: transparent;
}
.ProcessModule .processes-calendar .fc-theme-standard .fc-popover {
  display: block;
}
.ProcessModule .processes-calendar .fc-popover {
  background-color: #000;
  border-color: #000;
}
.ProcessModule .processes-calendar .fc-popover .fc-header {
  background-color: #4bb3d8;
}
.ProcessModule .processes-calendar .fc .fc-more-popover .fc-popover-body {
  padding: 0;
}
.ProcessModule .target-timeline,
.ProcessModule .active-tasks,
.ProcessModule .all-process-tasks,
.ProcessModule .overdue-tasks,
.ProcessModule .early-tasks,
.ProcessModule .incomplete-tasks,
.ProcessModule .single-process-tasks {
  width: 100%;
  color: #4bb3d8;
}
.ProcessModule .target-timeline h2,
.ProcessModule .active-tasks h2,
.ProcessModule .all-process-tasks h2,
.ProcessModule .overdue-tasks h2,
.ProcessModule .early-tasks h2,
.ProcessModule .incomplete-tasks h2,
.ProcessModule .single-process-tasks h2 {
  text-align: center;
}
.ProcessModule .target-timeline .very-overdue,
.ProcessModule .active-tasks .very-overdue,
.ProcessModule .all-process-tasks .very-overdue,
.ProcessModule .overdue-tasks .very-overdue,
.ProcessModule .early-tasks .very-overdue,
.ProcessModule .incomplete-tasks .very-overdue,
.ProcessModule .single-process-tasks .very-overdue {
  color: #e22006;
}
.ProcessModule .target-timeline .overdue,
.ProcessModule .active-tasks .overdue,
.ProcessModule .all-process-tasks .overdue,
.ProcessModule .overdue-tasks .overdue,
.ProcessModule .early-tasks .overdue,
.ProcessModule .incomplete-tasks .overdue,
.ProcessModule .single-process-tasks .overdue {
  color: orange;
}
.ProcessModule .target-timeline .ontime,
.ProcessModule .active-tasks .ontime,
.ProcessModule .all-process-tasks .ontime,
.ProcessModule .overdue-tasks .ontime,
.ProcessModule .early-tasks .ontime,
.ProcessModule .incomplete-tasks .ontime,
.ProcessModule .single-process-tasks .ontime {
  color: #4caf50;
}
.ProcessModule .target-timeline .on-track,
.ProcessModule .active-tasks .on-track,
.ProcessModule .all-process-tasks .on-track,
.ProcessModule .overdue-tasks .on-track,
.ProcessModule .early-tasks .on-track,
.ProcessModule .incomplete-tasks .on-track,
.ProcessModule .single-process-tasks .on-track {
  color: #4caf50;
}
.ProcessModule .target-timeline td,
.ProcessModule .active-tasks td,
.ProcessModule .all-process-tasks td,
.ProcessModule .overdue-tasks td,
.ProcessModule .early-tasks td,
.ProcessModule .incomplete-tasks td,
.ProcessModule .single-process-tasks td {
  border: solid 1px #aaaeb3;
}
.ProcessModule .target-timeline th,
.ProcessModule .active-tasks th,
.ProcessModule .all-process-tasks th,
.ProcessModule .overdue-tasks th,
.ProcessModule .early-tasks th,
.ProcessModule .incomplete-tasks th,
.ProcessModule .single-process-tasks th {
  color: #4bb3d8;
  text-transform: capitalize;
}
.ProcessModule .target-timeline .group,
.ProcessModule .target-timeline .taskAverage,
.ProcessModule .active-tasks .group,
.ProcessModule .active-tasks .taskAverage,
.ProcessModule .all-process-tasks .group,
.ProcessModule .all-process-tasks .taskAverage,
.ProcessModule .overdue-tasks .group,
.ProcessModule .overdue-tasks .taskAverage,
.ProcessModule .early-tasks .group,
.ProcessModule .early-tasks .taskAverage,
.ProcessModule .incomplete-tasks .group,
.ProcessModule .incomplete-tasks .taskAverage,
.ProcessModule .single-process-tasks .group,
.ProcessModule .single-process-tasks .taskAverage {
  width: 10%;
}
.ProcessModule .target-timeline .card,
.ProcessModule .active-tasks .card,
.ProcessModule .all-process-tasks .card,
.ProcessModule .overdue-tasks .card,
.ProcessModule .early-tasks .card,
.ProcessModule .incomplete-tasks .card,
.ProcessModule .single-process-tasks .card {
  padding: 10px;
  background-color: #272e34;
  margin: 10px;
}
.ProcessModule .target-timeline .card .card-body,
.ProcessModule .active-tasks .card .card-body,
.ProcessModule .all-process-tasks .card .card-body,
.ProcessModule .overdue-tasks .card .card-body,
.ProcessModule .early-tasks .card .card-body,
.ProcessModule .incomplete-tasks .card .card-body,
.ProcessModule .single-process-tasks .card .card-body {
  padding: 0;
}
.ProcessModule .target-timeline .single-process-tasks-table,
.ProcessModule .active-tasks .single-process-tasks-table,
.ProcessModule .all-process-tasks .single-process-tasks-table,
.ProcessModule .overdue-tasks .single-process-tasks-table,
.ProcessModule .early-tasks .single-process-tasks-table,
.ProcessModule .incomplete-tasks .single-process-tasks-table,
.ProcessModule .single-process-tasks .single-process-tasks-table {
  border: none;
}
.ProcessModule .target-timeline .single-process-tasks-table table,
.ProcessModule .active-tasks .single-process-tasks-table table,
.ProcessModule .all-process-tasks .single-process-tasks-table table,
.ProcessModule .overdue-tasks .single-process-tasks-table table,
.ProcessModule .early-tasks .single-process-tasks-table table,
.ProcessModule .incomplete-tasks .single-process-tasks-table table,
.ProcessModule .single-process-tasks .single-process-tasks-table table {
  overflow-y: auto;
  background-color: #353c44;
}
.ProcessModule .target-timeline .single-process-tasks-table .tasks-table,
.ProcessModule .active-tasks .single-process-tasks-table .tasks-table,
.ProcessModule .all-process-tasks .single-process-tasks-table .tasks-table,
.ProcessModule .overdue-tasks .single-process-tasks-table .tasks-table,
.ProcessModule .early-tasks .single-process-tasks-table .tasks-table,
.ProcessModule .incomplete-tasks .single-process-tasks-table .tasks-table,
.ProcessModule .single-process-tasks .single-process-tasks-table .tasks-table {
  width: 100%;
}
.ProcessModule .target-timeline .single-process-tasks-table .tasks-table th,
.ProcessModule .active-tasks .single-process-tasks-table .tasks-table th,
.ProcessModule .all-process-tasks .single-process-tasks-table .tasks-table th,
.ProcessModule .overdue-tasks .single-process-tasks-table .tasks-table th,
.ProcessModule .early-tasks .single-process-tasks-table .tasks-table th,
.ProcessModule .incomplete-tasks .single-process-tasks-table .tasks-table th,
.ProcessModule .single-process-tasks .single-process-tasks-table .tasks-table th {
  text-align: left;
}
.ProcessModule .target-timeline .single-process-tasks-table .tasks-table th.group,
.ProcessModule .active-tasks .single-process-tasks-table .tasks-table th.group,
.ProcessModule .all-process-tasks .single-process-tasks-table .tasks-table th.group,
.ProcessModule .overdue-tasks .single-process-tasks-table .tasks-table th.group,
.ProcessModule .early-tasks .single-process-tasks-table .tasks-table th.group,
.ProcessModule .incomplete-tasks .single-process-tasks-table .tasks-table th.group,
.ProcessModule .single-process-tasks .single-process-tasks-table .tasks-table th.group {
  text-align: center;
  color: #fff;
  font-size: large;
  background-color: #4bb3d8;
}
.ProcessModule .target-timeline .single-process-tasks-table .tasks-table td,
.ProcessModule .active-tasks .single-process-tasks-table .tasks-table td,
.ProcessModule .all-process-tasks .single-process-tasks-table .tasks-table td,
.ProcessModule .overdue-tasks .single-process-tasks-table .tasks-table td,
.ProcessModule .early-tasks .single-process-tasks-table .tasks-table td,
.ProcessModule .incomplete-tasks .single-process-tasks-table .tasks-table td,
.ProcessModule .single-process-tasks .single-process-tasks-table .tasks-table td {
  border: none;
  border-top: 1px solid #272e34;
}
.ProcessModule .target-timeline .single-process-tasks-table .tasks-table .taskName,
.ProcessModule .active-tasks .single-process-tasks-table .tasks-table .taskName,
.ProcessModule .all-process-tasks .single-process-tasks-table .tasks-table .taskName,
.ProcessModule .overdue-tasks .single-process-tasks-table .tasks-table .taskName,
.ProcessModule .early-tasks .single-process-tasks-table .tasks-table .taskName,
.ProcessModule .incomplete-tasks .single-process-tasks-table .tasks-table .taskName,
.ProcessModule .single-process-tasks .single-process-tasks-table .tasks-table .taskName {
  width: 45%;
}
.ProcessModule .target-timeline .single-process-tasks-table .tasks-table .dueDate,
.ProcessModule .active-tasks .single-process-tasks-table .tasks-table .dueDate,
.ProcessModule .all-process-tasks .single-process-tasks-table .tasks-table .dueDate,
.ProcessModule .overdue-tasks .single-process-tasks-table .tasks-table .dueDate,
.ProcessModule .early-tasks .single-process-tasks-table .tasks-table .dueDate,
.ProcessModule .incomplete-tasks .single-process-tasks-table .tasks-table .dueDate,
.ProcessModule .single-process-tasks .single-process-tasks-table .tasks-table .dueDate {
  width: 15%;
}
.ProcessModule .target-timeline .single-process-tasks-table .tasks-table .daysElapsed,
.ProcessModule .active-tasks .single-process-tasks-table .tasks-table .daysElapsed,
.ProcessModule .all-process-tasks .single-process-tasks-table .tasks-table .daysElapsed,
.ProcessModule .overdue-tasks .single-process-tasks-table .tasks-table .daysElapsed,
.ProcessModule .early-tasks .single-process-tasks-table .tasks-table .daysElapsed,
.ProcessModule .incomplete-tasks .single-process-tasks-table .tasks-table .daysElapsed,
.ProcessModule .single-process-tasks .single-process-tasks-table .tasks-table .daysElapsed {
  width: 10%;
}
.ProcessModule .target-timeline .single-process-tasks-table .tasks-table .targetDays,
.ProcessModule .active-tasks .single-process-tasks-table .tasks-table .targetDays,
.ProcessModule .all-process-tasks .single-process-tasks-table .tasks-table .targetDays,
.ProcessModule .overdue-tasks .single-process-tasks-table .tasks-table .targetDays,
.ProcessModule .early-tasks .single-process-tasks-table .tasks-table .targetDays,
.ProcessModule .incomplete-tasks .single-process-tasks-table .tasks-table .targetDays,
.ProcessModule .single-process-tasks .single-process-tasks-table .tasks-table .targetDays {
  width: 10%;
}
.ProcessModule .target-timeline .single-process-tasks-table .tasks-table .takenToAllotedRatio,
.ProcessModule .active-tasks .single-process-tasks-table .tasks-table .takenToAllotedRatio,
.ProcessModule .all-process-tasks .single-process-tasks-table .tasks-table .takenToAllotedRatio,
.ProcessModule .overdue-tasks .single-process-tasks-table .tasks-table .takenToAllotedRatio,
.ProcessModule .early-tasks .single-process-tasks-table .tasks-table .takenToAllotedRatio,
.ProcessModule .incomplete-tasks .single-process-tasks-table .tasks-table .takenToAllotedRatio,
.ProcessModule .single-process-tasks .single-process-tasks-table .tasks-table .takenToAllotedRatio {
  width: 10%;
}
.ProcessModule .target-timeline .single-process-tasks-table .tasks-table .status,
.ProcessModule .active-tasks .single-process-tasks-table .tasks-table .status,
.ProcessModule .all-process-tasks .single-process-tasks-table .tasks-table .status,
.ProcessModule .overdue-tasks .single-process-tasks-table .tasks-table .status,
.ProcessModule .early-tasks .single-process-tasks-table .tasks-table .status,
.ProcessModule .incomplete-tasks .single-process-tasks-table .tasks-table .status,
.ProcessModule .single-process-tasks .single-process-tasks-table .tasks-table .status {
  width: 10%;
}
.ProcessModule .incomplete-tasks .overdue td {
  color: orange;
}
.ProcessModule .incomplete-tasks .ontime td {
  color: #4caf50;
}

.calendar-dot-popover .popover {
  background-color: #000;
  min-width: 300px;
  color: #fff;
  z-index: 100;
}
.calendar-dot-popover h3.complete {
  background-color: #272e34;
}
.calendar-dot-popover h3.overdue {
  background-color: #e22006;
}
.calendar-dot-popover h3.due {
  background-color: orange;
}
.calendar-dot-popover .popover-header {
  background-color: #4bb3d8;
  color: #fff;
  max-height: 100px;
  vertical-align: middle;
}
.calendar-dot-popover .popover-body {
  padding: 10px;
  background-color: #000;
  color: #fff;
}
.calendar-dot-popover .popover-body p {
  margin: 0;
}
.calendar-dot-popover .popover-body .col,
.calendar-dot-popover .popover-body .col-md-3,
.calendar-dot-popover .popover-body .col-md-4,
.calendar-dot-popover .popover-body .col-md-5 {
  padding: 0;
}
.calendar-dot-popover .popover-body .row.due {
  color: orange;
}
.calendar-dot-popover .popover-body .row.overdue {
  color: #e22006;
}

