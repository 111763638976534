@import "../common/shared";

.PowerUserDashboard {
  .power-user-header {
    height: $height-content-header;
    padding: 10px;

    .power-user-header-controls-left {
      float: left;

      .active {
        border-color: $ecogy-color-info;
      }

      > * {
        float: left;
        margin-right: 10px;
        width: auto;
      }
    }
    
    .power-user-header-controls {
      float: right;

      > * {
        float: left;
        margin-left: 10px;
        width: auto;
      }
    }
  }

  .error-label {
    text-align: center;
    float: left;
    color: $ecogy-status-red;
    vertical-align: middle;
  }

  overflow-y: auto;
  overflow-x: hidden;
}
