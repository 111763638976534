@import "../../../common/shared";

.ghost-record {
  text-transform: capitalize;
  opacity: 0.4;
}

.status-in-progress {
  text-transform: uppercase;
  background-color: #317133;
  text-align: center;
}

.status-planned {
  text-transform: uppercase;
  background-color: #316a71;
  text-align: center;
}

.status-completed {
  text-transform: uppercase;
  background-color: orange;
  text-align: center;
}

th,
td {
  color: $ecogy-white;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.content-view-controls {
  padding: 10px;
  height: $height-view-controls;
}

.btn-create-event {
  width: 100px;
}

.dr-event-details-content {
  overflow: auto;
  height: calc(100% - 100px);
  background-color: $ecogy-darkgrey;
  padding: 20px;

  label {
    color: $ecogy-color-info;
    margin-right: 5px;
  }

  .ecogy-form label {
    margin-right: 0;
  }

  .type {
    text-transform: capitalize;
  }

  hr {
    margin: 2px;
    border-top-color: $ecogy-palegrey;
  }

  .remove-button {
    margin-bottom: 20px;
  }

  form > .form-group fieldset > .form-group {
    width: 100%;
    padding-right: 10px;
  }

  form > .form-group > div > div > fieldset > .form-group {
    width: 50%;
    padding-right: 10px;
  }

  .right-column {
    float: right;
    clear: both;
    padding-right: 0;
    padding-left: 10px;
  }
}

.help-box {
  background-color: #4bb3d8;
  color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.priority-low {
  background-color: #0088fe;
  color: white;
  min-width: 7em;
  text-transform: capitalize;
}

.priority-medium {
  background-color: #00c49f;
  color: white;
  min-width: 7em;
  text-transform: capitalize;
}

.priority-high {
  background-color: #ffbb28;
  color: white;
  min-width: 7em;
  text-transform: capitalize;
}

.participants {
  padding-bottom: $height-view-controls;
}

.events {
  padding-bottom: $height-view-controls;
}

.notifications {
  padding-bottom: $height-view-controls;
}
