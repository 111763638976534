/**
 * Material icon styling.
 */
.material-icons {
  -webkit-user-select: none;
  user-select: none;
}

/**
 * Popover styling.
 */
.popover {
  background-color: #475055;
}

.popover-body {
  color: #fff;
}

.popover-header {
  background-color: #4bb3d8;
  color: #fff;
}

.popover-arrow::before {
  border-top-color: #475055 !important;
}

.popover-arrow::after {
  border-top-color: #475055 !important;
}

/**
 * Generic table styling.
 */
.center-table-td, .center-table-th {
  text-align: center !important;
}

.center-table-td {
  vertical-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.sf-loader-position {
  position: absolute;
  right: 1%;
  top: 90px;
}

.disable-none {
  display: none;
}

.loader-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.Econode .module-content .content-sidebar .sidebar-section .sidebar-content .demand {
  color: #988dec;
}
.Econode .module-content .content-sidebar .sidebar-section .sidebar-content .cost {
  color: #fec87e;
}
.Econode .module-content .content-sidebar .disregard-range {
  background-color: #475055;
}
.Econode .EconomicsView {
  height: 100%;
}
.Econode .content .econode-monitors {
  padding-left: 10px;
}
.Econode .content .econode-monitors > div > .row {
  margin-top: 10px;
}
.Econode .content .econode-monitors > div > .row:last-child {
  margin-bottom: 10px;
}
.Econode .content .econode-monitors .econode-monitor-container {
  padding: 0;
}
.Econode .content .econode-monitors .econode-monitor-container .econode-monitor {
  min-height: 300px;
  height: 100%;
  width: calc(100% - 10px);
  float: left;
  background-color: #272e34;
  border-radius: 10px;
  padding: 10px;
}
.Econode .content .econode-monitors .econode-monitor-container .econode-monitor h4 {
  text-align: center;
}
.Econode .content .econode-monitors .econode-monitor-container .ok {
  background-color: #4caf50;
}
.Econode .content .econode-monitors .econode-monitor-container .warning {
  background-color: orange;
}
.Econode .content .econode-monitors .econode-monitor-container .error {
  background-color: #e22006;
}
.Econode .content .econode-monitors .econode-monitor-container .cause {
  background-color: white;
  font-weight: bold;
  padding-top: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.Econode .content .econode-monitors .econode-monitor-container .cause .cause-message {
  font-weight: normal;
  margin-bottom: 10px;
}
.Econode .content .econode-monitors .econode-monitor-container .ok .cause {
  color: #4caf50;
}
.Econode .content .econode-monitors .econode-monitor-container .warning .cause {
  color: orange;
}
.Econode .content .econode-monitors .econode-monitor-container .error .cause {
  color: #e22006;
}
.Econode .content .econode-power-dashboard .econode-toggles {
  margin-top: 10px;
  margin-left: 10px;
}
.Econode .content .econode-power-dashboard .econode-power-dashboard-filters {
  text-transform: capitalize;
}
.Econode .content .econode-power-dashboard .econode-power-dashboard-graph {
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 250px);
}
.Econode .content .econode-power-dashboard .econode-power-dashboard-graph .phased-graph {
  height: calc((100vh - 250px) / 3);
}

