@import "./common/shared";

.App {
  .app-mode {
    position: absolute;
    left: $menu-width;
    top: 20px;

    .nav-item {
      color: $ecogy-white;
      background-color: $background-content;
      border: $ecogy-dark 1px solid;
      border-bottom: $background-content-header 1px solid;
      border-radius: 5px 5px 0 0;
      cursor: pointer;

      a {
        color: $ecogy-white;
        background-color: $background-content;
        border: $ecogy-dark 1px solid;
        border-bottom: $background-content-header 1px solid;
      }

      a:hover {
        border: $ecogy-color-active 1px solid;
        border-bottom: none;

        // color: $ecogy-color-active;
      }

      span.nav-link {
        color: $ecogy-lightgrey;
      }

      span.nav-link:hover {
        border: $background-content-header 1px solid;
      }
    }

    .selected a {
      color: $ecogy-color-active;
      background-color: $background-content-header;

      // border: $ecogy-color-active 1px solid;
      // border-bottom: $background-content-header 1px solid;
      font-weight: bold;
    }
  }
}

.btn-ecogy {
  color: $ecogy-white;
  background-color: $ecogy-color-active;
  border-color: $ecogy-color-active;
}

.App-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1200px) {
  .marquee {
    display: none;
  }

  .maquee div {
    display: none;
  }
}

.App-stage-dev {
  .App-header {
    background-color: $ecogy-status-red;
  }
}

.App-stage-int {
  .App-header {
    background-color: $ecogy-status-orange;
  }
}

.App-stage-test {
  .App-header {
    background-color: $ecogy-status-green;
  }
}

.App-stage-prod {
  .App-header {
    background-color: $background-app-header;
  }
}

.App-header {
  background-color: $ecogy-blue;
  height: 60px;
  color: white;
  position: relative;

  .logo {
    height: 50px;
    max-height: 100px;
    width: auto;
    max-width: 100%;
    margin-top: 5px;
  }

  .controls {
    float: right;
    margin-top: 10px;

    .app-update {
      float: left;
      margin: 3px 10px 0 0;
      color: $ecogy-status-orange;

      button,
      button:hover,
      button:not(disabled, .disabled),
      button:focus {
        background-color: $ecogy-status-orange;
        color: $ecogy-white;
        border: solid 2px $ecogy-white;
      }
    }

    .stage {
      float: left;
      padding-right: 20px;
      font-size: 1rem;
      margin-top: 11px;
      color: $ecogy-white;
      text-transform: uppercase;
      font-weight: bold;
    }

    .marquee {
      white-space: nowrap;
      overflow: hidden;
      position: relative;
    }

    .marquee div {
      display: inline-block;
      animation: marquee 24s linear infinite;
    }

    @keyframes marquee {
      0% {
        transform: translateX(600px);
      }

      100% {
        transform: translateX(-100%);
      }
    }

    .clock {
      float: left;
      padding-right: 20px;
      font-size: 1rem;
      margin-top: 11px;

    }

    .help {
      display: inline-block;
      vertical-align: middle;
      padding-right: 12px;
      padding-bottom: 2px;
      user-select: none;

      i {
        color: $ecogy-palegrey;
        transform: scale(1.3);

        &:hover {
          color: $ecogy-lightgrey;
        }
      }
    }

    .settings {
      display: inline-block;
      margin-right: 20px;
      position: relative;
      top: 2px;

      .material-icons {
        color: $ecogy-lightgrey;
      }

      button {
        background: none;
        border: none;
        color: $ecogy-lightgrey;
      }

      button::after {
        vertical-align: 0.45em;
      }

      button:focus {
        outline: none;
        box-shadow: none;
      }

      .dropdown-item {
        color: $ecogy-color-active;

        a {
          color: $ecogy-color-active;
          text-decoration: none;
        }
      }

      .dropdown-item:hover {
        background-color: $ecogy-palegrey;

        a {
          color: $ecogy-color-active;
          text-decoration: none;
        }
      }

      a {
        color: $ecogy-color-active;
      }

      a:hover {
        color: $ecogy-color-active;
        text-decoration: none;
      }
    }

    .admin-menu {
      display: inline-block;
      position: relative;
      padding: 5px 0 0 3px;
      top: 0;
      color: $ecogy-white;

      .btn {
        background: $background-app;
        border: none;
        border-radius: 10px;
      }

      .dropdown-divider {
        border-top: 1px solid $ecogy-darkgrey;
      }

      .dropdown-menu {
        margin-top: 6px;
      }
    }
  }
}

.App-sidebar {
  background-color: $background-sidebar;
  width: $menu-width;
  height: calc(100vh - #{$height-header});
  float: left;

  .build {
    text-align: center;
    font-size: small;
    cursor: pointer;
    color: $ecogy-lightgrey;
    width: 100%;
    margin-top: 0.25rem;

    .build__new-version {
      position: absolute;
      bottom: 0.5rem;
    }
  }

  .dropdown-menu-dark {
    .header-menu {
      background-color: $ecogy-darkgrey;

      .selected.dropdown-item {
        border-radius: 0;
      }
    }

    .dropdown-header {
      color: $ecogy-lightgrey;
    }

    button {
      color: $ecogy-white;
    }

    > button {
      color: $ecogy-white;
      background-color: $ecogy-black;
      border-color: $ecogy-black;
    }

    > button.selected {
      background-color: $ecogy-color-active;
    }
  }

  button.menu-back,
  button.menu-back:hover:not(disabled, .disabled),
  button.menu-back:focus {
    width: 100%;
    background-color: $ecogy-black;
    border-color: $ecogy-black;
    text-align: left;
    padding-left: 5px;
  }

  button.menu-back:hover:not(disabled, .disabled) {
    background-color: $ecogy-color-active;
    border-color: $ecogy-black;
  }

  .selector {
    .project-filter {
      width: 100%;
      display: inline-flex;
      position: relative;
      background-color: $ecogy-color-active;
      align-items: center;
      padding-right: 2px;
      padding-left: 2px;

      .project-filter-title {
        width: 100%;
        flex: auto;
        color: $ecogy-white;
        border-color: $ecogy-black;
        padding: 2px;
        justify-content: center;

        .project-count {
          display: inline-block;
          padding-top: 5px;
        }
      }

      a {
        padding-top: 2px;
      }

      i.material-icons {
        color: $ecogy-white;
        height: 18px;
      }
      
      .save-icon {
        top: 2px;
        padding: 0 1px;
        margin-inline: 0px;
      }

      .project-filter-selected {
        background-color: $ecogy-color-active;
      }

      .project-filter-toggle {
        border: none;
        padding: 0 1px;
        margin-inline: 0px;

        i {
          //float: right;
          margin-top: -8px;
        }
      }

      .dropdown-menu-dark {
        > button {
          border-radius: 0;

          // border: 1px solid $ecogy-blue;
          // border-bottom: 2px solid $ecogy-black;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .header-menu {
          border: 2px solid $ecogy-black;
          margin: 0;
          width: 300px;

          .menu-info {
            float: right;
          }

          .sort-method {
            input {
              margin-right: 5px;
            }
          }

          .refinement {
            button {
              padding-left: 5px;

              input {
                margin-right: 5px;
              }
            }
          }

          .portfolio-select {
            padding: 6px;
          }

          select {
            background-color: $ecogy-black;
            color: $ecogy-white;
          }

          .selected {
            select {
              background-color: $ecogy-color-active;
            }
          }

          .badge-ecogy {
            margin-top: 5px;
            background-color: $ecogy-blue;
          }

          .dropend {
            width: 100%;

            button {
              text-align: left;
              width: 100%;
            }
          }

          .dropdown-menu {
            margin: 0;
            max-height: calc(100vh - #{$height-header});
            overflow: initial;
          }

          .dropdown-item {
            background-color: $ecogy-black;
          }

          .header-selected {
            background-color: $ecogy-color-active;
          }
        }
      }
    }

    .project-selector {
      height: calc(100% - #{$filter-height});
      overflow-y: auto;
      overflow-x: hidden;

      .list-group {
        height: 100%;

        .active {
          .material-icons {
            color: $ecogy-white;
          }
        }

        .list-group-item {
          padding: 0;
          padding-left: 5px;
          margin: 0;
          min-height: 30px;
          background-color: $ecogy-color-inactive;
          border-color: $ecogy-color-inactive;
          color: $ecogy-white;
          border-radius: 0;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          .form-check-input {
            margin-right: 2px;
          }
        }

        .list-group-item:hover:not(disabled, .disabled) {
          background-color: $ecogy-color-active;
        }

        .list-group-item.active {
          background-color: $ecogy-color-active;
          border-color: $ecogy-color-active;
          border-top-width: 0;
        }
      }

      .portfolio-selected {
        button.list-group-item {
          border-bottom: 0;
          border-left-color: $ecogy-blue;
          border-left-width: 4px;
          padding-left: 0;

          input {
            border: 1px;
            border-radius: 0;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            border-color: $ecogy-blue;
            background-color: $ecogy-blue;
            height: 20px;
            width: 20px;
            margin: 0;
          }

          input[type="checkbox"]:not(:checked) {
            background-color: $ecogy-darkgrey;
          }
        }

        button.unchecked {
          color: $ecogy-lightgrey;
        }

        button.unchecked:hover {
          color: $ecogy-white;
        }
      }
    }

    .active {
      .title {
        color: $ecogy-white;

        i.material-icons {
          color: $ecogy-color-active;
        }

        .open {
          position: absolute;
          right: 0;
          display: inline-block;
          margin-right: 10px;

          i.material-icons {
            color: $ecogy-white;
          }
        }

        .close {
          display: none;
        }

        .sidebar-back {
          width: 100%;
          color: $ecogy-white;
          text-align: left;

          i.material-icons {
            color: $ecogy-white;
          }
        }
      }
    }
  }

  .nav {
    background-color: $background-app;
    width: 190px;
    margin-top: 10px 0;
    padding-left: 10px;
    padding-top: 10px;
    bottom: 0;
    position: fixed;

    a {
      text-decoration: none;
      width: 100%;
      display: inline-block;
      position: relative;
    }

    a:hover {
      text-decoration: none;
    }

    a:active {
      text-decoration: none;
    }

    .content-selector {
      border: solid 1px $ecogy-color-inactive;
      background-color: $ecogy-color-inactive;
      color: $ecogy-white;
      border-radius: 10px;
      width: 100%;
      padding: 5px;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 5px;
    }

    .content-selector:hover {
      border: solid 1px $ecogy-color-hover;
      color: $ecogy-white;
    }

    .active {
      border: solid 1px $ecogy-color-active;
      background-color: $ecogy-color-active;
      color: $ecogy-white;
    }

    .active:hover {
      color: $ecogy-white;
    }
  }
}

.App-module {
  background-color: $background-app;
  width: calc(100vw - #{$menu-width});
  height: calc(100vh - #{$height-header});
  float: left;

  .type-filter {
    .dropdown-toggle {
      color: $ecogy-white;
    }

    .dropdown-menu {
      button:hover {
        background-color: $ecogy-color-active;
        border-radius: 0;
      }
    }

    > .btn-group > button:hover:not(disabled, .disabled) {
      border: none;
    }

    button {
      border: none;
    }
  }
}

.rdt {
  .rdtPicker {
    background-color: $ecogy-dark;
    border-radius: 5px;
    border: none;
    font-size: small;
    padding: 0;
    margin: 5px 0;

    .rdtDays {
      border-radius: 5px;
    }

    .rdtActive {
      background-color: $ecogy-color-active;
      border-radius: 4px;
    }

    .rdtTimeToggle:hover {
      background-color: $ecogy-color-active;
    }

    thead {
      background-color: $background-app;
      border-bottom: 1px solid $ecogy-white;

      tr:first-child {
        th:hover {
          background-color: $ecogy-color-active;
        }
      }
    }

    tbody {
      background-color: $background-content;
      border: none;
    }

    th {
      border-bottom: none;
    }

    td.rdtDay:hover {
      background-color: $ecogy-color-active;
      color: $ecogy-white;
      border-radius: 4px;
    }

    td.rdtToday::before {
      border-bottom-color: $ecogy-color-active;
    }
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
}

.App-loading {
  text-align: center;
  margin-top: 200px;
  color: $ecogy-color-info;
  font-size: xx-large;
}

.App-todo {
  text-align: center;
  margin-top: 200px;
  color: $ecogy-color-info;
  font-size: xx-large;
}

.App-logo {
  animation: App-logo-spin infinite 5s linear;
  height: 200px;
  margin: auto !important;
}

.loading {
  text-align: center;
  color: $ecogy-color-info;
  font-size: xx-large;
}

.loading-inline {
  position: fixed;
  bottom: 50px;
  right: 30px;
  .App-logo {
    animation: App-logo-spin infinite 5s linear;
    height: 40px;
    display: inline-block;
  }
  .loading-message {
    display: inline-block;
  }
}

.load-view {
  margin-top: 200px;
  text-align: center;
  color: $ecogy-color-info;
  font-size: xx-large;
  vertical-align: middle;
}

.Info {
  text-align: center;

  .info-title {
    padding-top: 10px;

    .material-icons {
      position: relative;
      top: 10px;
      color: $ecogy-blue;
      font-size: 48px;
    }
  }

  .info-message {
    color: white;
  }
}

.Error {
  text-align: center;

  .error-title {
    padding-top: 10px;

    .material-icons {
      position: relative;
      top: 10px;
      color: $ecogy-status-red;
      font-size: 48px;
    }
  }

  .error-message {
    color: white;
  }
}

.Warning {
  text-align: center;

  .warning-title {
    padding-top: 10px;

    .material-icons {
      position: relative;
      top: 10px;
      color: $ecogy-color-yellow;
      font-size: 48px;
    }
  }

  .error-message {
    color: white;
  }
}

.Message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 200px);
  font-size: 20;
  color: white;
  width: 100%;
}
