@import "../../../common/shared";

$preview-header-height: 60px;

.standalone-preview {
  height: calc(100vh - #{$preview-header-height});
}

.tableButton {
  padding: 5px;
}

.preview {
  width: 100%;
  height: 100%;
  .preview-header {
    background-color: $ecogy-black;
    height: 50px;
    font-size: large;

    .preview-title {
      float: left;
      padding: 10px;
    }
  }

  .preveiw-with-controls {
    width: 100%;
    height: 100%; 
    display: inline-flex; 
    justify-content: space-around;

  }
  .arrow {
    border: none;
    background-color: transparent;
  }

  .preview-content {
    width: 100%;
    height: 100%;
    color: $ecogy-white;

    .preview-unavailable {
      font-size: large;
      margin-top: 100px;
    }
  }
}

.Module .module-content {
  .Assets {
    height: 100%;
    margin-top: 1px; // Needed to avoid the header pushing the title down

    h1 {
      height: $height-form-header;
      margin: 0;
      padding-left: 35px;
    }

    .title-bar {
      height: #{$height-form-header};
      display: flex;
      width: 100%;
      justify-content: space-between;

      h1 {
        text-align: left;
      }

      .version-controls {
        float: right;
        width: auto;
      }
    }

    .pro-forma-alert {
      .alert {
        padding: 5px;
        margin-right: 20px;
      }
    }

    .proforma-content {
      width: 100%;
      overflow: auto;
      padding-left: 20px;
      padding-right: 20px;

      .ecogy-form {
        margin-top: 0;
      }

      .pro-forma-cash-flow-filters {
        position: sticky;
        top: 0;
        z-index: 5;
        background-color: $ecogy-blue;
        padding: 10px;
        margin-bottom: 5px;
        border-radius: 5px;

        div > * {
          width: auto;
          display: inline-block;
        }

        button {
          margin-left: 5px;
        }

        p {
          margin: 0;
          margin-top: 5px;
        }
      }
    }

    .record-content {
      width: 100%;
      overflow: auto;

      .ecogy-form {
        margin-top: 0;

        option:disabled {
          display: none;
        }
      }

      .dropdown-icon {
        margin-left: 10px;
      }

      .dropdown-icon::after {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 8px 0;
        border-color: #fff transparent transparent;
        vertical-align: middle;
        margin-right: 10px;
        margin-left: 10px;
        cursor: pointer;
      }

      .dropdown-icon.collapse-hide::after {
        border-width: 0 8px 8px;
        border-color: transparent transparent #fff;
      }

      .collapse-show .material-icons {
        display: none;
      }
    }

    .content-with-controls {
      .record-content {
        height: calc(
          100vh - #{$height-header} - #{$height-content-header} - #{$height-form-controls} - #{$height-form-header}
        );
      }

      .ghost-record {
        text-transform: capitalize;
        opacity: 0.4;
      }

      .ghost-record-toggle {
        display: grid;
        grid-template-columns: repeat(3, 33.3%);
        align-items: center;
        justify-items: center;

        span {
          width: 24px;
          text-align: center;
          grid-column-start: 2;
        }

        .btn {
          grid-column-start: 3;
          padding: 0;
          padding-left: 5px;

          i {
            opacity: 0.4;
          }
        }
      }
    }

    tr.document-type {
      width: 80px;
      margin: auto;

      // line-height: 80px;
      // height: 80px;
      // border: 1px $ecogy-palegrey solid;
      border-radius: 5px;
      text-transform: uppercase;
      vertical-align: middle;

      td.document-download {
        text-align: center;
        cursor: pointer;

        .material-icons {
          color: $ecogy-white;
        }
      }
    }

    .document-name:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    .type-financial {
      background-color: $ecogy-color-blue;
      border-color: $ecogy-color-blue;
    }

    .type-compliance {
      background-color: $ecogy-color-orange;
      border-color: $ecogy-color-orange;
    }

    .type-energy {
      background-color: $ecogy-color-green;
      border-color: $ecogy-color-green;
    }

    .selected {
      background-color: $ecogy-color-active;
      color: $ecogy-white;
      border-radius: 5px;

      .document-type {
        border: 1px solid $ecogy-white;
      }
    }

    .document-details-title {
      background-color: $ecogy-color-info;
      padding: 20px;
    }

    .document-details {
      background-color: $ecogy-darkgrey;
      padding: 20px;

      label {
        color: $ecogy-color-info;
        margin-right: 5px;
      }

      .type {
        text-transform: capitalize;
      }

      hr {
        margin: 2px;
        border-top-color: $ecogy-palegrey;
      }
    }

    .document-contracts {
      > h4 {
        padding: 20px 20px 15px;
      }

      > div {
        padding: 20px 20px 15px;
        background-color: $ecogy-darkgrey;
        border-bottom: solid 1px $ecogy-black;

        label {
          color: $ecogy-color-info;
          margin-right: 5px;
        }

        .type {
          text-transform: capitalize;
        }
      }
    }

    .btn:last-child {
      margin-right: 0;
    }

    .no-documents {
      text-align: center;
      padding-top: 100px;
      font-size: large;
      color: white;
    }

    .Content .item-details {
      .legal-contract-content {
        padding-right: 0;

        .row {
          width: 100%;
        }

        .side-panel {
          padding: 0;
          margin: 0;
        }

        form {
          margin-right: -30px;
        }

        label {
          justify-content: left;
        }

        select {
          width: 100%;
        }
      }
    }

    .type-filter {
      .menu-sub-type {
        width: 100%;

        > button {
          text-align: left;
        }
      }

      button {
        padding: 6px 12px;
      }

      button:hover {
        border: none;
      }
    }

    .type-filter__ghost-records {
      display: flex;
      align-items: center;

      .react-toggle-track-check,
      .react-toggle-track-x {
        margin-top: 0.5rem;
      }

      span {
        padding: 3px;
      }
    }

    .side-panel {
      margin-left: -15px;
      margin-right: 0;
      padding-right: 0;
      margin-bottom: 60px;

      .side-panel-content {
        // border: $ecogy-palegrey solid 1px;
        background-color: $ecogy-color-info;
        color: $ecogy-white;
        border-radius: 10px;
        padding: 20px;
        height: 100%;
        margin-bottom: 20px;

        h3 {
          clear: both;
        }

        .side-panel-section {
          margin-bottom: 10px;
        }

        table {
          width: 100%;
          border: solid 1px $ecogy-white;

          td {
            border: solid 1px $ecogy-white;
            color: $ecogy-white;
          }

          th {
            color: $ecogy-white;
          }
        }

        a {
          color: $ecogy-white;
        }

        > div {
          padding-bottom: 20px;
        }

        .folder {
          cursor: pointer;
          -webkit-user-select: none;
          user-select: none;
          background-color: $ecogy-white;
          color: $ecogy-black;
          margin-bottom: 10px;

          h4 {
            margin: 0;
            padding: 10px;
            background-color: $ecogy-white;
            text-align: center;
            border-bottom: solid 1px $ecogy-lightgrey;

            &:hover {
              background-color: $ecogy-palegrey;
            }

            i {
              float: right;
            }
          }

          h5 {
            padding: 10px;
            border-bottom: solid 1px $ecogy-lightgrey;
          }

          .folder-document {
            border-bottom: solid 1px $ecogy-lightgrey;

            .folder-document-click-wrapper {
              padding: 10px;

              &:hover {
                background-color: $ecogy-palegrey;
              }
            }
          }
        }

        .document {
          padding: 0 0 5px 5px;
          width: 100%;
          clear: both;

          button {
            margin: 0 0 0 5px;
          }
        }

        .download {
          border-color: $ecogy-white;
          background-color: $ecogy-white;
          color: $ecogy-color-active;
          margin-bottom: 2px;
          text-align: left;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          .material-icons {
            color: $ecogy-color-active;
            padding: 0;
          }
        }

        .missing {
          color: $ecogy-status-red;
        }

        .record-payment {
          margin-bottom: 20px;

          h4 {
            text-transform: capitalize;
          }

          h5 {
            margin-top: 10px;
          }
        }

        .payments {
          width: 100%;

          th {
            color: $ecogy-white;
          }

          .past {
            color: $ecogy-palegrey;
          }
        }

        table.pro-forma-costs {
          tr {
            td:nth-child(1) {
              width: 50%;
            }

            td:nth-child(2) {
              width: 20%;
            }
          }
        }

        table.pro-forma-irr {
          td {
            width: 50%;
          }
        }
      }
    }
  }
}
