@import "../common/shared";

$module-selector-height: 300px;

.Dashboard {
  .App-sidebar {
    .selector {
      height: calc(100% - #{$module-selector-height});
    }
  }
}
