@import "./common/shared";

body {
  margin: 0;
  padding: 0;
  background-color: $background-app;
  color: $ecogy-white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  overflow: hidden;
  font-size: 14px;
}

.slide-pane__content {
  z-index: 10;
}

.Toastify__toast-body { font-family: 'Inter'; }

[data-amplify-router] {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 500px;
}

// CSS for the Amplify generated login screen
#root {
  button[class^="Button__button___"],
  div[class*=" Button__button___"] {
    background-color: $ecogy-color-active;
  }
}

.numerical {
  text-align: right;
}

.material-icons {
  top: 4px;
  position: relative;
  font-size: 18px;
}

// We apply common styling to the reactstrap components


button.btn-primary,
button.btn-primary:active,
button.btn-primary:focus {
  background-color: $ecogy-color-active;
  border: 1px solid $ecogy-color-inactive;
}

button.btn-primary:hover:not(disabled, .disabled) {
  background-color: $ecogy-color-active;
  border: 1px solid $ecogy-color-hover;
}

button.btn-secondary,
button.btn-secondary:active,
button.btn-secondary:focus {
  background-color: $ecogy-color-inactive;
  border: 1px solid $ecogy-color-inactive;
}

button.btn-secondary:hover:not(disabled, .disabled) {
  border: 1px solid $ecogy-color-hover;
}

button.selected,
button.selected:active,
button.selected:focus {
  background-color: $ecogy-color-active;
  border: 1px solid $ecogy-color-inactive;
}

button.selected:hover:not(disabled, .disabled) {
  background-color: $ecogy-color-active;
  border: 1px solid $ecogy-color-hover;
}

.btn:hover {
  color: $ecogy-white;
}

.btn-group {
  > button {
    border-radius: 10px;
    border: 1px solid $ecogy-darkgrey;
  }

  .btn-secondary.disabled,
  .btn-secondary:disabled {
    border: 1px solid $ecogy-darkgrey;
  }
}

.btn-group-sm{
  .btn{
    border: 1px solid $ecogy-darkgrey;
    border-radius: 10px;
  }
}

.dropdown-menu {
  background-color: $ecogy-black;
  color: $ecogy-white;

  button {
    border-radius: 0;
  }

  .dropdown-item {
    border: 1px solid transparent;
    color: $ecogy-color-active;

    a {
      color: $ecogy-color-active;
      text-decoration: none;
    }
  }

  .dropdown-item:hover:not(disabled, .disabled) {
    color: $ecogy-white;
    background-color: $ecogy-color-active;
    border-color: $ecogy-color-active;

    a {
      color: $ecogy-white;
      text-decoration: none;
    }
  }
}

// .list-group-item {
//   border: 1px solid transparent;
// }

// .list-group-item + .list-group-item {
//   border-top-width: 0;
// }

button.list-group-item,
button.active.list-group-item {
  border-top-width: 0;
}

button.list-group-item:hover:not(disabled, .disabled) {
  border-top-width: 0;
  background-color: $ecogy-color-active;
}

.tooltip-inner {
  max-width: 400px;
}

.nav-tabs {
  border-bottom: 1px solid $ecogy-darkgrey;
}
