/**
 * Material icon styling.
 */
.material-icons {
  -webkit-user-select: none;
  user-select: none;
}

/**
 * Popover styling.
 */
.popover {
  background-color: #475055;
}

.popover-body {
  color: #fff;
}

.popover-header {
  background-color: #4bb3d8;
  color: #fff;
}

.popover-arrow::before {
  border-top-color: #475055 !important;
}

.popover-arrow::after {
  border-top-color: #475055 !important;
}

/**
 * Generic table styling.
 */
.center-table-td, .center-table-th {
  text-align: center !important;
}

.center-table-td {
  vertical-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.sf-loader-position {
  position: absolute;
  right: 1%;
  top: 90px;
}

.disable-none {
  display: none;
}

.loader-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.power-user-content {
  height: calc(100vh - 60px - 60px);
}
.power-user-content .power-user-row {
  display: inline-flex;
  width: 100%;
  height: 100%;
}
.power-user-content .graph-info b {
  color: #4bb3d8;
}
.power-user-content .graph-warning {
  color: orange;
}
.power-user-content .graph-error {
  color: #e22006;
}
.power-user-content .power-user-sources-tree {
  display: inline-flex;
}
.power-user-content .power-user-sources-tree .property-selector {
  overflow-y: scroll;
  overflow-x: visible;
  scrollbar-width: thin;
}
.power-user-content .power-user-sources-tree .property-selector::-webkit-scrollbar {
  width: 14px;
  height: 18px;
}
.power-user-content .power-user-sources-tree .property-selector::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.15);
  box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
.power-user-content .power-user-sources-tree .property-selector::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.power-user-content .power-user-sources-tree .property-selector::-webkit-scrollbar-corner {
  background-color: transparent;
}
.power-user-content .power-user-sources-tree .property-selector .property {
  padding: 0.2rem;
  background-color: #475055;
  color: white;
}
.power-user-content .power-user-sources-tree .property-selector .active {
  background-color: #0d6efd;
}
.power-user-content .power-user-sources-tree .rc-tree {
  overflow-y: scroll;
  overflow-x: visible;
  scrollbar-width: thin;
  padding-right: 10px;
}
.power-user-content .power-user-graph {
  height: calc(100vh - 200px);
  width: 100%;
  text-align: center;
  display: inline-flex;
}
.power-user-content .power-user-graph .select-projects-mesg p {
  font-size: 2em;
}
.power-user-content .power-user-graph .linechart {
  margin: 5 10 0 0;
}
.power-user-content .power-user-graph .recharts-legend-wrapper {
  max-height: 20%;
}
.power-user-content .power-user-graph .recharts-tooltip-wrapper {
  pointer-events: auto;
  margin-left: -5px;
}
.power-user-content .power-user-graph .recharts-tooltip-wrapper .recharts-default-tooltip {
  overflow: hidden;
}
.power-user-content .power-user-graph .recharts-tooltip-wrapper .recharts-default-tooltip .recharts-tooltip-item-list {
  max-height: 50vh;
}

