@import "../../../../../common/shared";

.weather-line {
  margin: 11px 10px;
  width: calc(100% - 20px);
  height: 15px;

  > div {
    position: relative;
  }

  .weather-dot {
    position: absolute;
    width: 18px;
    height: 18px;
    top: -9px;
    margin-left: -9px;
    float: left;

    img {
      margin-left: -6px;
      width: 30px;
    }
  }
}
