/**
 * Material icon styling.
 */
.material-icons {
  -webkit-user-select: none;
  user-select: none;
}

/**
 * Popover styling.
 */
.popover {
  background-color: #475055;
}

.popover-body {
  color: #fff;
}

.popover-header {
  background-color: #4bb3d8;
  color: #fff;
}

.popover-arrow::before {
  border-top-color: #475055 !important;
}

.popover-arrow::after {
  border-top-color: #475055 !important;
}

/**
 * Generic table styling.
 */
.center-table-td, .center-table-th {
  text-align: center !important;
}

.center-table-td {
  vertical-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.sf-loader-position {
  position: absolute;
  right: 1%;
  top: 90px;
}

.disable-none {
  display: none;
}

.loader-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.PowerUserDashboard {
  overflow-y: auto;
  overflow-x: hidden;
}
.PowerUserDashboard .power-user-header {
  height: 60px;
  padding: 10px;
}
.PowerUserDashboard .power-user-header .power-user-header-controls-left {
  float: left;
}
.PowerUserDashboard .power-user-header .power-user-header-controls-left .active {
  border-color: #4bb3d8;
}
.PowerUserDashboard .power-user-header .power-user-header-controls-left > * {
  float: left;
  margin-right: 10px;
  width: auto;
}
.PowerUserDashboard .power-user-header .power-user-header-controls {
  float: right;
}
.PowerUserDashboard .power-user-header .power-user-header-controls > * {
  float: left;
  margin-left: 10px;
  width: auto;
}
.PowerUserDashboard .error-label {
  text-align: center;
  float: left;
  color: #e22006;
  vertical-align: middle;
}

