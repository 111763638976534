@import "../../../../common/shared";

.card {
  color: $ecogy-white;
  background-color: $ecogy-darkgrey;
}

.future-date input {
  color: $ecogy-color-blue;
  font-weight: 600;
}

.future-date input:focus {
  color: $ecogy-color-blue;
  font-weight: 600;
}

.project-side-panel {
  margin-left: -15px;
  margin-right: 0;
  padding-right: 0;
  height: 100%;

  .side-panel {
    padding: 20px;

    .project-reports {
      button {
        background-color: $ecogy-white;
        border-color: $ecogy-white;
        color: $ecogy-color-controls;
      }

      .date-range-menu {
        // Temporary until we implement report for range of months
        width: auto;
        height: auto;

        .custom-date-range {
          width: 325px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .custom-date {
            width: 100%;
            height: 250px;
            padding-right: 5px;

            th {
              text-align: center;
            }

            .rdt {
              .rdtPicker {
                width: 100%;
              }
            }
          }

          .date-range-button {
            margin: 0;
          }
        }
      }

      .report {
        padding: 5px;
        clear: both;

        .document {
          min-height: 20px;
          line-height: 42.5px;
        }
      }
    }
  }

  .project-side-panel-content {
    // border: $ecogy-palegrey solid 1px;
    background-color: $ecogy-color-info;
    color: $ecogy-white;
    border-radius: 10px;
    padding: 20px;

    // height: 100%;

    table {
      width: 100%;
    }

    a {
      color: $ecogy-white;
    }

    > div {
      padding-bottom: 20px;
    }

    .download {
      border-color: $ecogy-white;
      background-color: $ecogy-white;
      color: $ecogy-color-active;
      margin-bottom: 2px;
      width: 100%;
      text-align: left;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      .material-icons {
        color: $ecogy-color-active;
      }
    }

    .missing {
      color: $ecogy-status-red;
    }

    .payments {
      width: 100%;

      .past {
        color: $ecogy-palegrey;
      }
    }
  }
}

.project-content {
  height: calc(
    100% - #{$bottom-content-spacing} 
  );
  width: 100%;
  overflow: auto;
  padding-left: 20px;
  padding-right: 20px;

  .ecogy-form {
    margin-top: 0;
  }
}

.project-finance {
  > div > div {
    margin: 20px 0;
  }

  .ecogy-form {
    margin-bottom: 0;
  }
}

.project-tabs {
  height: 100%;

  .tab-content {
    height: calc(100% - #{$tabs-height});
    overflow-x: hidden;
    overflow-y: auto;
    .active {
      height: 100%;
      margin-left: calc(#{$content-margin} * 2);
    }
  }

  .ecogy-form {
    margin-top: 0;
  }

  .new-button {
    background-color: $ecogy-color-active;
    border-color: $ecogy-color-active;
    float: right;
  }

  .nav-tabs {
    border-bottom: 1px solid $ecogy-dark;
    margin-bottom: 20px;

    .nav-item {
      cursor: pointer;

      a {
        color: $ecogy-white;
        background-color: $background-content;
        border-bottom: none;
      }

      a.active {
        color: $ecogy-color-active;
        font-weight: bold;
        background-color: $background-app;
        border: 1px solid $ecogy-color-active;
        border-bottom: none;
        margin-left: 0px;
      }

      a:hover {
        border: 1px solid $ecogy-color-active;
        border-bottom: none;
      }
    }

    .new {
      a {
        color: $ecogy-white;
        background-color: $ecogy-color-active;
      }

      a.active {
        color: $ecogy-color-active;
        background-color: $ecogy-white;
      }
    }

    .verified {
      top: 3px;
      margin: 0 -5px 0 5px;
    }
  }

  .new-card {
    color: $ecogy-white;
    background-color: $ecogy-color-info;

    .loading {
      background-color: $ecogy-white;
    }

    .icon-button {
      border-color: $ecogy-white;

      .material-icons {
        color: $ecogy-white;
      }
    }

    .icon-button.btn-danger {
      border-color: $ecogy-status-red;

      .material-icons {
        color: $ecogy-status-red;
      }
    }
  }

  .address-line {
    display: block;
  }

  .project-tab-content-area {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .project-tabs {
    .tab-content {
      overflow-x: hidden;
      overflow-y: auto;
    }

    .project-site {
      .project-site-system {
        background-color: $ecogy-white;
      }
    }
  }

  .project-site-tabs {
    .tab-content {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;

      .ecogy-form-buttons {
        margin-left: calc(-#{$content-margin} * 2 * 2);
      }
    }
    
  }

  .site-system-tabs {
    .tab-content {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;

      .ecogy-form-buttons {
        margin-left: calc(-#{$content-margin} * 2 * 3);
      }
    }
  }

  .field-group {
    .card {
      padding: 20px;
    }

    .field-group-title {
      font-weight: bold;
    }

    .field-group-toggle {
      text-align: right;
      float: right;
    }
  }

  .card {
    margin-bottom: 10px;
  }

  .project-finance-category {
    margin-bottom: 20px;
  }
}

.project-table {
  .mutli-line-cell {
    text-transform: capitalize;
    white-space: pre-wrap;
  }

  // Enforce a min-height
  .project-table-milestones-container {
    padding: 0.5rem 0;
    height: 4rem;

    .project-table-milestones {
      display: flex;
      justify-content: space-between;
    }
  }
}

table {
  thead.sticky th {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: $ecogy-white;
  }

  td {
    color: $ecogy-white;
  }
}

.project-contacts {
  padding: 10px;
  background-color: $ecogy-dark;

  h2,
  h3,
  h4 {
    color: $ecogy-color-info;
    text-align: center;
  }

  h5 {
    text-align: center;
  }

  .site-contacts {
    margin: 10px;
    padding: 10px;
    background-color: $ecogy-darkgrey;

    .system-contacts {
      margin: 10px;
      padding: 10px;
      background-color: $ecogy-grey;
    }
  }
}

.no-contacts {
  text-align: center;
}

legend[id$="_ecogyContact__title"] {
  display: none;
}

.irradiance-input {
  display: inline-block;
  width: 50%;

  > * {
    float: left;
  }

  input {
    width: 70%;
  }

  .units {
    width: 30%;
    padding-left: 10px;
    padding-top: 10px;
  }
}

.verified-in-table {
  text-align: center;
}

.field-with-help {
  display: flex;
  align-items: center;
}
