@import "../../../../common/shared";

.event-line {
  background-color: $ecogy-purple;
  margin: 11px 10px;
  width: calc(100% - 20px);
  height: 2px;

  > div {
    position: relative;
  }

  .event-dot {
    position: absolute;
    width: 18px;
    height: 18px;
    background-color: $ecogy-purple;
    border-radius: 9px;
    text-align: center;
    font-size: small;
    top: -9px;
    margin-left: -9px;
    float: left;
  }

  .event-dot-empty {
    background: none;

    > div {
      background-color: $ecogy-purple;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      margin-top: 5px;
      margin-left: 5px;
    }
  }

  .event-dot-menu {
    background-color: $ecogy-darkgrey;
    color: $ecogy-white;

    .react-contextmenu-item--selected {
      background: none;
    }

    button {
      width: 100%;
    }
  }

  .unresolved {
    background-color: $ecogy-status-orange;
  }

  .selected {
    background-color: $ecogy-color-active;
  }

  .event-line-unresolved {
    position: absolute;
    background-color: $ecogy-status-orange;
    height: 2px;
    float: left;
  }

  .event-line-highlight {
    position: absolute;
    display: inline;
    background-color: $ecogy-color-info;
    top: 15px;

    .event-start {
      position: absolute;
      left: -5px;
      background-color: $ecogy-color-info;
      width: 10px;
      border-radius: 5px;
      height: 10px;
      margin-top: -5px;
    }

    .event-end {
      position: absolute;
      right: -5px;
      background-color: $ecogy-color-info;
      width: 10px;
      border-radius: 5px;
      height: 10px;
      margin-top: -5px;
    }
  }
}

.event-dot-tooltip {
  // background-color: $ecogy-darkgrey;
  color: $ecogy-white;
  max-width: 50%;
  max-height: 50%;
  pointer-events: none;

  .tooltip-inner {
    max-width: 800px;
    max-height: 50%;
  }

  p {
    margin: 0;
    padding: 0;
    color: $ecogy-white;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    strong {
      color: $ecogy-status-orange;
    }
  }

  .title {
    .path {
      color: $ecogy-color-info;
      margin-right: 100px;
    }

    .date {
      float: right;
    }
  }
}

.event-dot-popover {
  width: 50%;
  max-width: 800px;
  color: $ecogy-white;

  > .popover {
    // EP-833 fix for reactstrap not applying the className to the proper element
    width: 50%;
    max-width: 800px;
    color: $ecogy-white;
  }

  .popover-header {
    background-color: $ecogy-black;
    color: $ecogy-white;
    max-height: 100px;
    vertical-align: middle;
    padding: 20px;

    .event-actions {
      float: right;
      margin-top: -10px;

      a {
        float: left;
        padding-right: 20px;
      }

      .material-icons {
        color: $ecogy-white;
        font-size: 30pt;
      }
    }
  }

  .popover-body {
    padding: 0;
    background-color: $ecogy-darkgrey;
    color: $ecogy-white;
    max-height: calc(100vh - 400px);
    overflow: auto;

    > div {
      border-bottom: $ecogy-white solid 1px;
    }

    p {
      margin: 3px 0;
      max-height: 50px;
      text-overflow: ellipsis;

      strong {
        color: $ecogy-status-orange;
      }
    }

    .event {
      padding: 5px 10px;
    }

    .unresolved {
      // background-color: $ecogy-status-orange;
    }

    .resolved {
      // background-color: $ecogy-lightgrey;
    }

    .description {
      color: $ecogy-palegrey;
      overflow: auto;

      // overflow-y: scroll;
    }

    .event-actions {
      float: right;
    }

    .path {
      font-weight: bold;
      color: $ecogy-color-info;
    }
  }
}
