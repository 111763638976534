.twx-ka-table {
  @import "../../node_modules/ka-table/style.scss";

  margin-top: 4px !important;
  font-family: Inter, sans-serif;

  .ka-table-wrapper {
    border: 1px solid hsl(var(--border));
    border-radius: var(--radius);
    background-color: hsl(var(--background));
    color: hsl(var(--foreground));

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: hsl(var(--secondary));
    }

    &::-webkit-scrollbar-thumb {
      background-color: hsl(var(--muted-foreground) / 0.3);
      border-radius: 3px;

      &:hover {
        background-color: hsl(var(--muted-foreground) / 0.5);
      }
    }
  }

  .ka-thead-cell-content {
    display: flex;
    align-items: center;
    font-weight: 600;
    color: hsl(var(--muted-foreground));
  }

  .ka-group-text {
    color: hsl(var(--foreground));
  }

  .ka-group-panel {
    border: 1px solid hsl(var(--border));
    background-color: hsl(var(--muted / 0.5));
  }

  .ka-tr {
    transition: background-color 0.2s ease;

    &:hover {
      background-color: hsl(var(--accent) / 0.8) !important;
    }
  }

  .ka-tr:nth-child(even) {
    background-color: hsl(var(--muted) / 0.5);
  }

  .ka-cell {
    border-bottom: 1px solid hsl(var(--border));
  }

  .ka-thead-cell {
    background-color: hsl(var(--muted / 0.5));
    border-bottom: 1px solid hsl(var(--border));
  }

  .ka-cell-text {
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .ka-no-data-cell {
    color: hsl(var(--muted-foreground));
  }

  .ka-row-selected {
    background-color: rgba(119, 221, 119, 0.5) !important;
  }


}

.dark .twx-ka-table {
  .ka-table-wrapper {
    background-color: hsl(var(--background));
    color: hsl(var(--foreground));
    border-color: hsl(var(--border));
    border-radius: 0;
  }

  .ka-row {
    border-bottom: 0px solid hsl(var(--foreground));
    border-top: 0px solid hsl(var(--foreground));
  }

  .ka-cell,
  .ka-thead-cell-content,
  .ka-cell-text {
    color: hsl(var(--foreground));
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .ka-group-text {

    color: hsl(var(--foreground));
  }



  .ka-no-data-cell {

    color: hsl(var(--foreground));
  }

  .ka-tr:hover {
    background-color: hsl(var(--accent)) !important;
  }

  .ka-group-row  {

    background-color: hsl(var(--muted));
  }

.ka-empty-cell {

    background-color: hsl(var(--muted));
}

  .ka-tr:nth-child(even) {
    background-color: hsl(var(--muted));
  }

  .ka-thead-cell {
    background-color: hsl(var(--muted));
  }

  .ka-group-panel {
    border: 1px solid hsl(var(--border));
    background-color: hsl(var(--muted));
  }

  .ka-group-panel-cell {
    border: 1px solid hsl(var(--border));
  }

  .ka-row-selected {
    background-color: rgba(119, 221, 119, 0.5) !important;
  }
}