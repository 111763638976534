.public-view {
    * {
        font-family: Poppins !important;
    }
}

.public-view .sidebar {
   border-right: 1px solid hsl(var(--border));
}

.dark .public-view .sidebar {
    border-right: 1px solid hsl(var(--foreground));
}

.dark #pdf-renderer {
    background-color: hsl(var(--background));
}



#pdf-download {
    display: none !important;
}

#pdf-controls {
    width: calc(100vw - 20px);
    z-index: 9999;
}

.menu-items {
    font-family: Poppins !important;
}