@import "../../common/shared";

.image-carousel {
  height: 70vh;
  background: $background-content;
  display: flex;
  justify-content: center;
}

.image-carousel-image {
  max-height: 70vh;
}
