/**
 * Material icon styling.
 */
.material-icons {
  -webkit-user-select: none;
  user-select: none;
}

/**
 * Popover styling.
 */
.popover {
  background-color: #475055;
}

.popover-body {
  color: #fff;
}

.popover-header {
  background-color: #4bb3d8;
  color: #fff;
}

.popover-arrow::before {
  border-top-color: #475055 !important;
}

.popover-arrow::after {
  border-top-color: #475055 !important;
}

/**
 * Generic table styling.
 */
.center-table-td, .center-table-th {
  text-align: center !important;
}

.center-table-td {
  vertical-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.sf-loader-position {
  position: absolute;
  right: 1%;
  top: 90px;
}

.disable-none {
  display: none;
}

.loader-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.Module .module-content .content .finance-table {
  position: relative;
  border-collapse: collapse;
  overflow-y: auto;
}
.Module .module-content .content .finance-table tfoot {
  font-weight: bold;
}
.Module .module-content .content .finance-table tfoot td {
  position: sticky;
  bottom: 0; /* Don't forget this, required for the stickiness */
  background: #353c44;
  color: #4bb3d8;
}
.Module .module-content .content .finance-table thead th {
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  background: #353c44;
}
.Module .module-content .content .finance-table th .material-icons {
  position: absolute;
  right: 0;
  color: #fff;
  padding-top: 5px;
  z-index: 0;
}
.Module .module-content .content .finance-table td.code {
  width: 50px;
}
.Module .module-content .content .finance-table td.name {
  width: 200px;
}
.Module .module-content .content .finance-table td.ranged {
  width: 10%;
}
.Module .module-content .content .finance-table select {
  position: relative;
  background: none;
  color: #fff;
  border: none;
  border-radius: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  font-weight: bold;
  appearance: none;
  padding-right: 20px;
  z-index: 10;
}
.Module .module-content .content .finance-table select:focus {
  background: #4bb3d8;
  color: #fff;
  border: none;
  border-radius: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  font-weight: bold;
  appearance: none;
  padding-right: 20px;
  z-index: 10;
}
.Module .module-content .content .finance-table .out-of-date {
  color: orange;
}
.Module .module-content .content .finance-table .category-type-total {
  background-color: black;
}
.Module .module-content .content .finance-table .category-type-category {
  font-weight: bold;
}
.Module .module-content .content .finance-table .category-type-subCategory {
  color: #aaaeb3;
}
.Module .module-content .content .finance-table .icon-list-container > div {
  width: 100%;
  text-align: center;
}
.Module .module-content .content .finance-table .icon-list-container > div svg {
  cursor: pointer;
  width: 24px;
  height: 24px;
}
.Module .module-content .content .finance-table .icon-list-container > div img {
  cursor: pointer;
  width: 20px;
  height: 20px;
}
.Module .module-content .content .finance-table .cell-icon {
  position: relative;
}
.Module .module-content .content .pro-forma-inputs thead tr th {
  background-color: #4bb3d8;
  color: #fff;
}
.Module .module-content .content .pro-forma-inputs th {
  text-align: left;
}
.Module .module-content .content .pro-forma-inputs .account {
  font-weight: bold;
}
.Module .module-content .content .pro-forma-inputs .sub-account td:first-child {
  padding-left: 20px;
}
.Module .module-content .content .pro-forma-inputs .sub-account-title td:first-child {
  text-transform: capitalize;
  padding-left: 40px;
}
.Module .module-content .content .pro-forma-inputs .sub-account-detail td:first-child {
  padding-left: 60px;
}
.Module .module-content .content .pro-forma-inputs .sub-account-detail td:nth-child(2) {
  width: 30%;
}
.Module .module-content .content .pro-forma-inputs .total td {
  font-weight: bold;
}
.Module .module-content .content .pro-forma-inputs .system-type {
  text-transform: capitalize;
}
.Module .module-content .content .sources-uses tr.account td:first-child {
  padding-left: 20px;
}
.Module .module-content .content .sources-uses tr.sub-account td:first-child {
  padding-left: 40px;
}
.Module .module-content .content .sources-uses tr.sub-account td {
  color: #aaaeb3;
}
.Module .module-content .content .sources-uses tfoot {
  font-weight: bold;
}
.Module .module-content .content .sources-uses tfoot td {
  color: #4bb3d8;
}
.Module .module-content .content .Content .finance-project {
  overflow: auto;
  height: 100%;
}
.Module .module-content .content .Content .finance-project hr {
  border-color: #aaaeb3;
}
.Module .module-content .content .Content .finance-project .finance-project-info {
  overflow: auto;
  clear: both;
}
.Module .module-content .content .Content .finance-project .finance-project-info .finance-project-totals {
  width: 33.33%;
  float: left;
}
.Module .module-content .content .Content .finance-project .finance-project-info .finance-project-categories {
  width: 66.66%;
  float: left;
}
.Module .module-content .content .Content .finance-project .finance-project-info .finance-project-categories .finance-project-category {
  width: 50%;
  float: left;
}
.Module .module-content .content .Content .finance-project .IRRReport-content {
  padding: 0;
}
.Module .module-content .content .Finance-header label {
  padding-top: 10px;
}
.Module .module-content .content-view-controls .float-end.btn-toolbar .btn {
  border-radius: 10px;
}
.Module .module-content .content-view-controls .float-end.btn-toolbar .material-icons {
  padding: 0;
}
.Module .module-content .content-view-controls #pro-forma-toggle {
  max-width: 400px;
}

