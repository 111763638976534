@import "../../../../../common/shared";

.Module .module-content .content {
  .record-payments {
    .nav-tabs {
      border-bottom: 1px solid $ecogy-dark;

      .nav-item {
        cursor: pointer;

        a {
          color: $ecogy-white;
          background-color: $background-content;
          border-bottom: none;
        }

        a.active {
          color: $ecogy-color-active;
          font-weight: bold;
          background-color: $background-app;
          border: 1px solid $ecogy-color-active;
          border-bottom: none;
        }

        a:hover {
          border: 1px solid $ecogy-color-active;
          border-bottom: none;
        }
      }
    }
  }

  table {
    padding-right: 5px;

    th {
      text-align: center;
    }

    th.tartiff-title {
      text-align: center;

      // background-color: $ecogy-blue;
      // color: $ecogy-white;
    }
  }
}
