@import "../../common/shared";

$height-content-footer: 300px;
$width-sidebar-right: 330px;

.Module {
  height: calc(100vh - #{$height-header});

  .module-header {
    background-color: $background-content-header;
    min-height: $height-module-header;
    clear: both;

    .wrap {
      display: inline-flex;
      max-width: 100%;
      position: relative;
      top: 0px;
      left: 0px;
    }

    .module-header-info {
      height: $height-module-header;

      .module-header-banner {
        font-size: x-large;
        text-align: center;
      }

      .module-header-title {
        text-align: center;

        i,
        a {
          // $ecogy-blue and $ecogy-color-blue don't look good here in my opinion.
          color: #0df;
        }

        a {
          font-size: 1rem;
        }

        i {
          transform: scale(0.9);
        }
      }
    }

    .header-selected {
      border-radius: 0;
    }

    .view-exit {
      float: left;
      margin-top: 10px;
      margin-left: 10px;
    }

    .view-title {
      float: left;
      padding-top: 10px;
      padding-left: 10px;

      .material-icons {
        color: $ecogy-white;
        font-size: 24px;
        padding-left: 20px;
      }
    }

    .header-bar {
      float: left;
      margin-left: 10px;

      a .btn {
        margin-right: 10px;
      }

      .form-inline {
        float: left;
        padding-top: 11px;
        font-size: 1rem;

        .input-group {
          margin-right: 20px;
        }

        input {
          display: inline-block;
        }

        label {
          padding-right: 5px;
          text-transform: capitalize;
        }

        ::placeholder {
          color: $ecogy-palegrey;
        }
      }
    }

    .secondary-header-toolbar {
      float: right;
      margin-right: 10px;
    }

    .header-menu {
      background-color: $ecogy-darkgrey;
    }

    .header-selector {
      padding: 10px;

      label {
        padding-top: 10px;
        padding-right: 10px;
        margin: 0;
      }

      .material-icons {
        padding-top: 5px;
      }

      .dropdown-menu-dark {
        button {
          color: $ecogy-lightgrey;
          background-color: $ecogy-darkgrey;
        }
      }
    }

    .header-disabled {
      visibility: hidden;
    }

    .btn-toolbar {

      // margin-top: 10px;
      .header-button-group {
        .material-icons {
          position: relative;
          top: 2px;
          color: $ecogy-lightgrey;
          padding-right: 5px;
          font-size: 16px;
        }

        .selected {
          .material-icons {
            color: $ecogy-color-active;
          }
        }
      }

      .btn-group {
        margin-right: 10px;

        .btn-secondary {
          outline: none;
        }

        .btn-secondary:focus {
          outline: none;
          box-shadow: none;
        }

        .btn {
          margin-right: 0;
        }
      }

      .date-range-group {
        margin-right: 0;

        .selected {
          color: $ecogy-white;
          background-color: $ecogy-color-active;

          .material-icons {
            color: $ecogy-white;
          }
        }
      }
    }
  }


  .module-content {
    margin-left: $content-margin;
    margin-right: $content-margin;
    background-color: $background-app;
    height: calc(100% - #{$height-module-header});


    .header-id {
      font-size: small;
      color: $ecogy-lightgrey;
      padding-left: 10px;
    }

    .content-view {
      height: 100%;
    }

    //Some veiws have quirks like horizonal scrolling, ignore parent dimensions or dont fill the veiw
    //so for those we can just position the toolbar manually
    .force {
      position: fixed;
      bottom: 0;
      margin-right: 20px;
      width: calc(100vw - #{$menu-width} - #{$content-margin})
    }

    .content-view-controls {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 0;
      padding-left: 0;
      height: $height-view-controls;
      background-color: $background-content-header;

      a .btn:last-child {
        margin-right: 10px;
      }

      .header-button-group {
        .btn {
          margin: 0;
        }
      }

      label {
        margin-top: 5px;
        margin-right: 5px;
      }

      form {
        margin-right: 10px;

        .form-check-label-color {
          border-radius: 10px;
          padding: 0.25rem 0.5rem;
          margin-top: 0;
        }
      }

      .form-check-inline {
        margin-right: 0;
      }
    }

    .search-bar {
      position: fixed;
      width: calc(100% - #{$menu-width});
      padding-right: 20px;

      .input-group {
        padding-right: 5px;

        .input-group-text {
          background-color: $ecogy-darkgrey;
          color: $ecogy-white;
        }

        button,
        input {
          background-color: $ecogy-dark;
          color: $ecogy-white;
          border-color: $ecogy-white;
        }
      }

      .input-group-sm {
        padding-right: 5px;

        .input-group-text {
          background-color: $ecogy-darkgrey;
          color: $ecogy-white;
          border-radius: 10px;
          padding-inline: 0.5rem;
          padding-block: 0.25rem;
          font-size: 14px;
        }

        button,
        input {
          background-color: $ecogy-dark;
          color: $ecogy-white;
          border-color: $ecogy-white;
          border-radius: 10px;
          padding-inline: 0.5rem;
          padding-block: 0.25rem;
          font-size: 14px;
        }
      }

      .form-check-label {
        padding: 0 5px;
        margin-right: 10px;
        border-radius: 5px;
      }
    }

    .content {
      float: left;
      width: calc(100% - #{$width-sidebar-right} - 10px - 2px);
      height: 100%;
      background-color: $background-content;

      .content-with-title {
        height: 100%;
        border-bottom: solid 1px $background-app;
        overflow: auto;
      }

      .content-main {
        height: calc(100% - #{$height-content-footer});
        border-bottom: solid 1px $background-app;
        overflow: auto;
      }

      .content-main-full {
        height: 100%;
      }

      .content-footer {
        height: $height-content-footer;
        width: 100%;
      }

      .ok {
        background-color: $ecogy-status-green;
      }

      .warning {
        background-color: $ecogy-status-orange;
      }

      .error {
        background-color: $ecogy-status-red;
      }

      .no-status {
        background-color: $ecogy-darkgrey;
      }

      .no-match {
        text-align: center;
      }

      .content-title {
        text-align: center;
        padding: 10px;
        margin-bottom: 0;
      }

      .content-subtitle {
        text-align: center;
        padding: 10px;
        margin: 0;
      }

      .site-content {
        padding-bottom: 50px;
        background-color: $ecogy-dark;

        td.ok {
          color: $ecogy-status-green;
          background-color: $ecogy-dark;
        }

        td.warning {
          color: $ecogy-status-orange;
          background-color: $ecogy-dark;
        }

        td.error {
          color: $ecogy-status-red;
          background-color: $ecogy-dark;
        }

        td.no-status {
          color: $ecogy-white;

          // background-color: $ecogy-dark;
        }

        td.ranged {
          background-color: $ecogy-darkgrey;
        }
      }

      .system-content {
        clear: both;
      }

      table {
        width: 100%;
        border-collapse: separate;
        /* Don't collapse as this makes sticky headers see through */
        border-spacing: 0;
        overflow-y: auto;
        overflow-x: hidden;

        th {
          top: 0;
          border-bottom: 2px solid $ecogy-darkgrey;
          background-color: $ecogy-mediumgrey;
          z-index: 1;
          color: $ecogy-color-info;
          text-align: left;
        }

        th.ranged {
          color: $ecogy-white;
          background-color: $ecogy-color-info;
        }

        td {
          // background-color: $background-content;
          border: solid 1px $background-app;

          a {
            color: $ecogy-white;
          }

          .btn-link {
            padding: 0;
            padding-left: 5px;

            .material-icons {
              color: $ecogy-color-active;
            }
          }
        }

        td.danger {
          background-color: $ecogy-status-red;
        }

        td.ranged {
          background-color: $background-content;
        }

        th.edit {
          width: 20px;
        }
      }

      .content-map {
        height: 100%;
        width: 100%;
      }

      nav {
        background-color: $ecogy-darkgrey;

        // padding: 10px;
        z-index: 100;
        color: $ecogy-white;

        .react-contextmenu-item {
          color: $ecogy-white;
        }

        .ok>.react-contextmenu-item {
          background-color: $ecogy-status-green;

          // .react-contextmenu-item--selected {
          //   background: none;
          // }
        }

        .warning>.react-contextmenu-item {
          background-color: $ecogy-status-orange;

          // .react-contextmenu-item--selected {
          //   background: none;
          // }
        }

        .error>.react-contextmenu-item {
          background-color: $ecogy-status-red;

          // .react-contextmenu-item--selected {
          //   background: none;
          // }
        }

        .no-nodes>.react-contextmenu-item {
          padding: 0 10px;
        }
      }

      .content-graph-menu {
        text-align: center;
        background-color: $ecogy-darkgrey;
        clear: both;

        .content-graph-menu-left {
          font-weight: bold;
          float: left;
          margin-left: 10px;

          // display: inline;
        }

        .content-graph-menu-right {
          float: right;

          // display: inline;
          margin-right: 10px;
        }

        .dropdown {
          display: inline;
          z-index: 10;

          .dropdown-menu {
            background-color: $ecogy-dark;
          }

          button {
            color: $ecogy-white;
            background-color: $ecogy-darkgrey;
            border: none;
          }

          .dropdown-item:hover {
            color: $ecogy-white;
            background-color: $ecogy-color-active;
          }

          .dropdown-toggle {
            color: $ecogy-white;
            box-shadow: none;
          }

          .selected {
            color: $ecogy-color-active;
          }
        }
      }

      .content-graph-empty {
        text-align: center;
        font-size: large;
        padding-top: 100px;
      }

      .content-graph,
      .content-source-graph {
        width: 100%;

        .recharts-tooltip-wrapper {
          pointer-events: auto;
          margin-left: -5px;

          .recharts-default-tooltip {
            overflow-y: auto;
            overflow-x: hidden;

            .recharts-tooltip-item-list {
              max-height: 165px;
            }
          }
        }
      }

      .content-graph {
        height: calc(#{$height-content-footer} - 30px);
      }

      .content-source-graph {
        height: calc(#{$height-content-footer} + 230px);
      }
    }

    .content-full {
      width: 100%;
      overflow-y: auto;
    }

    .content-full-with-title {
      width: 100%;
      height: calc(100% - #{$height-form-header});
      overflow-y: auto;
    }

    .close-button {
      top: 0;
      font-size: 48px;
    }

    .item-details {
      height: 100%;

      .item-details-title {
        padding: 10px 20px;
        font-size: large;

        h2 {
          padding: 0;

          .right-title {
            float: right;
            font-size: medium;
            padding-top: 14px;
          }
        }
      }

      .item-create-title {
        background-color: $ecogy-color-info;
      }

      .item-details-content {
        overflow: auto;
        height: calc(100% - $height-form-header);
        background-color: $ecogy-darkgrey;
        padding: 20px;

        label {
          color: $ecogy-color-info;
          margin-right: 5px;
        }

        .ecogy-form label {
          margin-right: 0;
        }

        .type {
          text-transform: capitalize;
        }

        hr {
          margin: 2px;
          border-top-color: $ecogy-palegrey;
        }

        .remove-button {
          margin-bottom: 20px;
        }

        form>.form-group fieldset>.form-group {
          width: 50%;
          padding-right: 10px;
        }

        form>.form-group>div>div>fieldset>.form-group {
          width: 50%;
          padding-right: 10px;
        }

        .right-column {
          float: right;
          clear: both;
          padding-right: 0;
          padding-left: 10px;
        }
      }
    }

    .content-sidebar {
      margin-left: $content-margin;
      float: left;
      width: $width-sidebar-right;
      height: 100%;
      overflow: auto;

      .sidebar-section {
        margin-top: $content-margin;
        background-color: $background-content;
        width: 100%;
        padding: 10px;

        .sidebar-title-wrapper {
          display: flex;
          justify-content: space-between;
        }

        .sidebar-title {
          // text-align: center;
          width: 100%;
          color: $ecogy-color-title;
          text-transform: uppercase;
        }

        .sidebar-subtitle {
          color: $ecogy-color-subtitle;
          float: right;
          margin: 0;
        }

        .sidebar-range {
          color: $ecogy-color-subtitle;
          margin: 0;
          font-size: small;
        }

        .sidebar-content {
          width: 100%;

          .sidebar-heading {
            font-size: xx-large;
            font-weight: bold;
            color: $ecogy-color-info;
            text-align: center;
          }

          .positive {
            color: $ecogy-color-positive;
          }

          .negative {
            color: $ecogy-color-negative;
          }


          .sidebar-text {
            // color: $ecogy-palegrey;
            text-align: center;

            .material-icons {
              float: right;
              padding-top: 10px;
            }
          }

          .siderbar-reading {
            color: $ecogy-color-info;
            font-size: x-large;
          }

          .warning {
            color: $ecogy-status-orange
          }

          .siderbar-error {
            color: $ecogy-color-info;
            font-size: large;
            text-align: center;
            padding: 10px 0;
          }
        }
      }

      :first-child {
        margin: 0;
      }

      .date-range {
        color: $ecogy-lightgrey;

        .start-date {
          color: $ecogy-white;
        }

        .end-date {
          color: $ecogy-white;
        }

        .date-label {
          width: 50px;
        }
      }
    }

    .recharts-tooltip-cursor {
      fill-opacity: 25%;
    }

    .content-with-controls {
      height: calc(100% - #{$height-view-controls});
    }
  }


  .collapsed {
    height: calc(100% - #{$height-module-header} - #{$height-module-tabs});
  }
}

.App-portfolio {
  .module-header {
    background-color: $ecogy-color-active;
    min-height: $height-module-header - 1;
    border-bottom: 1px solid black;
  }

  .module-content {
    margin-top: $content-margin;
    height: calc(100% - #{$height-module-header} - #{$content-margin});
  }

  .collapsed {
    height: calc(100% - #{$height-module-header} - #{$height-module-tabs} - #{$content-margin});
  }
}

// The tooltip is rendered outside the 'Content' in the main body
.area-graph-tooltip {
  background: $ecogy-white;
  padding: 10px;
  color: $ecogy-darkgrey;
  text-transform: capitalize;

  p {
    margin: 0;
  }
}

.econode-tooltip .area-graph-tooltip,
.ecogy-tooltip .area-graph-tooltip {
  text-transform: none;
}

.modal {
  color: $ecogy-black;

  .error {
    color: $ecogy-status-red;
  }
}

.custom-range-button {
  padding-top: 0;
  padding-bottom: 0;
}

.custom-range-name {

  >* {
    float: left;
  }

  .range {

    .range-start {
      font-size: small;
    }

    .range-end {
      font-size: small;
    }

    .range-divider {
      display: none;
    }
  }
}

.date-range-menu {
  width: 700px;
  height: 370px;
  left: auto;
  right: 0;

  .custom-date-range {
    float: left;
    width: 510px;

    .custom-date {
      float: left;
      width: 250px;
      margin-left: 5px;
      height: 310px;
    }
  }

  .precanned-date-ranges {
    float: left;
    margin-left: 10px;
    height: 100%;
    width: 170px;
    background-color: $background-content;
    border-radius: 5px;
    padding: 10px;

    .dropdown-item {
      padding: 0.25rem;
      border: none;
    }

    .selected {
      color: $ecogy-white;
      background-color: $ecogy-color-active;
    }
  }

  .date-range-buttons {
    clear: both;

    .date-range-button {
      border-radius: 10px;
      float: right;
    }
  }

  .date-range-message {
    width: 100%;
    color: $ecogy-color-info;
    clear: both;
    padding: 8px 8px 0;

    .material-icons {
      color: $ecogy-color-info;
    }
  }

  .date-range-warning {
    width: 100%;
    color: $ecogy-status-red;
    clear: both;
    padding: 8px 8px 0;

    .material-icons {
      color: $ecogy-status-red;
    }
  }
}

.react-select__option {
  color: rgb(29, 33, 39) !important;
}

.battery-view-header {
  text-align: center;
}

.battery-box {
  height: 150px;
  width: 200px;
  background-color: rgb(29, 33, 39);
  border-radius: 20px;
  display: grid;
  grid-template-columns: 75px 75px;
  color: grey;
}

.battery-container {
  text-align: center;
  align-items: center;
  justify-items: center;
  height: 350px;
  width: 250px;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 80px 230px;
  grid-template-rows: 80px 150px 80px;

  color: rgb(207, 205, 205);

  p {
    font-size: medium;
  }

  .state-of-health {
    margin-top: 8px;
    margin-bottom: 40px;
    background-color: #193138;
    border-radius: 5px;
    font-size: small;
  }
}

.graph-tail-end {
  height: 8px;
  width: 8px;
  background-color: #00C49F;
  bottom: 180px;
  left: 0px;
  position: absolute;
  border-radius: 5px;
  z-index: 100;
}

.battery-cell {
  $w: 70;
  $h: 100;

  margin-right: 10px;
  margin-left: 10px;
  background-color: $ecogy-navy;
  height: unquote($h+"px");
  width: unquote($w+"px");
  position: relative;

  .logo {
    position: relative;
    top: 40px;
    left: -14px;
    max-width: 100px;
    filter: opacity(0.6) drop-shadow(0 0 0 rgb(0, 0, 0));
    transform: rotate(270deg)
  }

  .charge-bar {
    margin: 5px;
    position: absolute;
    width: unquote($w - 10 + "px");
    bottom: 0px;
    background-color: $ecogy-status-green;
  }

  .empty {
    visibility: hidden;
  }

  .low {
    background-color: $ecogy-status-red;
  }

  .high {
    background-color: $ecogy-status-green;
  }

  .charge-bar-wrapper {
    position: absolute;
    height: unquote($h - 10 + "px");
    width: unquote($w - 10 + "px");
    bottom: 0px;
  }

  .charge-bar-top {
    clip-path: ellipse(unquote(($w - 10)/ 2 + "px") 10px);
    height: 20px;
    position: absolute;
    width: unquote($w - 10 + "px");
    top: -10px;
    box-shadow: inset 0px -6px 12px 0px hsla(0, 0%, 0%, .2);
  }

  .charge-bar-bottom {
    clip-path: ellipse(unquote(($w - 10)/ 2 + "px") 10px);
    height: 20px;
    position: absolute;
    width: unquote($w - 10 + "px");
    bottom: -10px;
  }

  .battery-cell-top {
    clip-path: ellipse(unquote($w / 2 + "px") 10px);
    height: 20px;
    position: absolute;
    width: unquote($w+"px");
    top: -10px;
    background-color: #193138;
    z-index: 100;
  }

  .battery-cell-top2 {
    clip-path: ellipse(unquote($w / 2 + "px") 10px);
    height: 20px;
    position: absolute;
    width: unquote($w+"px");
    top: -5px;
    background-color: $ecogy-navy;
    z-index: 99;
  }

  .battery-cell-bottom {
    clip-path: ellipse(unquote($w / 2 + "px") 10px);
    height: 20px;
    position: absolute;
    width: unquote($w+"px");
    bottom: -10px;
    background-color: $ecogy-navy;
  }
}

.side-by-side {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}

[class|="confetti"] {
  position: absolute;
  z-index: 3000;
}

$colors: (
  #42f212,
  #f6c226e9,
  #0483f2
);

.papers-origin {
  position: absolute;
  overflow: visible;
  top: 50%;
  left: 50%;
}


@for $i from 0 through 75 {
  $w: random(8);

  .confetti-#{$i} {
    width: #{$w}px;
    height: #{$w*0.4}px;
    background-color: nth($colors, random(3));
    top: 0px;
    left: unquote(random(30) - 15 + "px");
    opacity: 0;
    transform: rotate(#{random()*360}deg);
    animation: drop-#{$i} unquote(3+random()+"s") unquote(random()+"s") 3;
  }

  @keyframes drop-#{$i} {
    $lr: random(30) - 15;

    25% {
      top: unquote("-" +random(25)+"vh");
      left: unquote($lr+"vw");
      opacity: random() + 0.5;
    }

    100% {
      top: 50vh;
      left: unquote($lr+(random(30) - 15)+"vw");
      opacity: 0;
    }
  }
}