.funnel-view {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;

  @media (max-width: 1280px) {
    flex-direction: column;
  }
}

.funnel-view__table {
  width: 100%;
  height: 100%;

  th {
    font-weight: bold;
  }

  th,
  td {
    font-size: 1rem;
  }

  .hoverable:hover {
    opacity: 0.7;
  }

  .align-right {
    text-align: right;
  }

  .align-center {
    text-align: center;
  }

  .gray-400 {
    background-color: #444;
  }

  .sub-table-padding {
    padding-left: 2rem;
  }

  @media (max-width: 1280px) {
    // For some reason this margin doesn't get applied?
    // margin-right: 1rem;
    width: 99%;
  }

  .material-icons {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.5rem;
  }
}

.funnel-view__charts {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 0.5rem;

  @media (max-width: 1280px) {
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: -1rem;
  }

  @media (max-width: 1080px) {
    flex-direction: column;
  }

  .recharts-wrapper {
    margin: 0;
    margin-bottom: 10px;
  }
}
