@import "../../../common/shared";

.Econode {
  .module-content {
    .content-sidebar {
      .sidebar-section {
        .sidebar-content {
          .demand {
            color: $ecogy-purple;
          }

          .cost {
            color: $ecogy-yellow;
          }
        }
      }

      .disregard-range {
        background-color: $ecogy-grey;
      }
    }
  }

  .EconomicsView {
    height: 100%;
  }

  .content {
    .econode-monitors {
      padding-left: 10px;

      > div > .row {
        margin-top: 10px;
      }

      > div > .row:last-child {
        margin-bottom: 10px;
      }

      .econode-monitor-container {
        padding: 0;

        .econode-monitor {
          h4 {
            text-align: center;
          }

          min-height: 300px;
          height: 100%;
          width: calc(100% - 10px);
          float: left;
          background-color: $background-app;
          border-radius: 10px;
          padding: 10px;

          // margin-top: 10px;
        }

        .ok {
          background-color: $ecogy-status-green;
        }

        .warning {
          background-color: $ecogy-status-orange;
        }

        .error {
          background-color: $ecogy-status-red;
        }

        .cause {
          background-color: white;
          font-weight: bold;
          padding-top: 10px;
          border-radius: 10px;
          margin-bottom: 10px;

          .cause-message {
            font-weight: normal;
            margin-bottom: 10px;
          }
        }

        .ok .cause {
          color: $ecogy-status-green;
        }

        .warning .cause {
          color: $ecogy-status-orange;
        }

        .error .cause {
          color: $ecogy-status-red;
        }
      }
    }

    .econode-power-dashboard {
      .econode-toggles {
        margin-top: 10px;
        margin-left: 10px;
      }

      .econode-power-dashboard-filters {
        text-transform: capitalize;
      }

      .econode-power-dashboard-graph {
        overflow: hidden;
        width: 100%;
        height: calc(100vh - 250px);

        .phased-graph {
          height: calc((100vh - 250px) / 3);
        }
      }
    }
  }
}
