/**
 * Material icon styling.
 */
.material-icons {
  -webkit-user-select: none;
  user-select: none;
}

/**
 * Popover styling.
 */
.popover {
  background-color: #475055;
}

.popover-body {
  color: #fff;
}

.popover-header {
  background-color: #4bb3d8;
  color: #fff;
}

.popover-arrow::before {
  border-top-color: #475055 !important;
}

.popover-arrow::after {
  border-top-color: #475055 !important;
}

/**
 * Generic table styling.
 */
.center-table-td, .center-table-th {
  text-align: center !important;
}

.center-table-td {
  vertical-align: center;
}

.display-linebreak {
  white-space: pre-line;
}

.sf-loader-position {
  position: absolute;
  right: 1%;
  top: 90px;
}

.disable-none {
  display: none;
}

.loader-spinner {
  border: 16px solid #f3f3f3;
  border-radius: 50% !important;
  border-top: 16px solid #3498db;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.Admin {
  background-color: #272e34;
  height: calc(100vh - 60px);
}
.Admin .list-group-item.active {
  background-color: #4bb3d8;
  border-color: #4bb3d8;
}
.Admin h1 {
  color: #4bb3d8;
  text-align: center;
}
.Admin .Admin-footer {
  position: absolute;
  bottom: 0;
  padding-bottom: 10px;
}
.Admin .Admin-footer .footer-button {
  float: left;
  background-color: #4bb3d8;
  border-color: #4bb3d8;
  margin-top: 10px;
}
.Admin .Admin-content {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 60px);
}
.Admin .Admin-content .admin-footer {
  background-color: #272e34;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  z-index: 10;
}
.Admin .Admin-content .admin-footer .user-audit-view-controls__toggle {
  text-transform: capitalize;
}
.Admin .Admin-content .admin-footer .user-audit-view-controls__toggle i {
  padding-left: 0.25rem;
}
.Admin .Admin-content .admin-footer .user-audit-view-controls__dropdown-item {
  display: flex;
  justify-content: space-between;
}
.Admin .Admin-content .admin-footer__form-buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.Admin .Admin-content .float-end {
  right: 0;
}
.Admin .Admin-content .admin-menu {
  overflow: auto;
  height: calc(100vh - 60px - 56px);
  padding-bottom: 20px;
}
.Admin .Admin-content .admin-menu .list-group-item {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 10px;
  background-color: #1f1f1f;
  color: #fff;
}
.Admin .Admin-content .admin-menu .list-group-item strong {
  color: #4bb3d8;
}
.Admin .Admin-content .admin-menu .active {
  background-color: #4bb3d8;
}
.Admin .Admin-content .admin-menu .active strong {
  color: #fff;
}
.Admin .Admin-content .ecogy-form .ecogy-form__portfolios__dropdown-toggle {
  margin: 0;
  padding: 0 1rem 0 0.75rem;
  background-color: #272e34;
  box-shadow: none;
  border: 1px solid #aaaeb3;
  border-radius: 0.2rem;
}
.Admin .Admin-content .ecogy-form .ecogy-form__portfolios__dropdown-toggle:hover, .Admin .Admin-content .ecogy-form .ecogy-form__portfolios__dropdown-toggle:focus {
  border: 1px solid #fff;
}
.Admin .Admin-content .ecogy-form .ecogy-form__portfolios__list-group {
  position: absolute;
  z-index: 1;
}
.Admin .Admin-content .ecogy-form .ecogy-form__portfolios__list-group .ecogy-form__portfolios__list-group-item {
  border-radius: 0.25rem;
}
.Admin .Admin-content .ecogy-form .ecogy-form__portfolios__list-group .ecogy-form__portfolios__list-group-item:hover {
  color: #fff;
  background-color: #4bb3d8;
}
.Admin .Admin-content .ecogy-form .ecogy-form__user-type__dropdown-toggle {
  margin: 0 0 0.25rem;
  padding: 0 0.5rem 0 0.25rem;
  background-color: #272e34;
  box-shadow: none;
  border-radius: 0;
}
.Admin .Admin-content .ecogy-form #root_modules_ams {
  display: grid;
}
.Admin .Admin-content .ecogy-form #root_modules_ams_finance_irrControls,
.Admin .Admin-content .ecogy-form #root_modules_econode_finance_irrControls,
.Admin .Admin-content .ecogy-form #root_modules_ams_data_recordTypes,
.Admin .Admin-content .ecogy-form #root_modules_ams_process_checklistControls {
  padding-left: 30px;
}
.Admin .Admin-content .ecogy-form #root_modules_ams_finance_irrControls #root_modules_ams_finance_irrControls__title,
.Admin .Admin-content .ecogy-form #root_modules_ams_finance_irrControls #root_modules_econode_finance_irrControls__title,
.Admin .Admin-content .ecogy-form #root_modules_ams_finance_irrControls #root_modules_ams_data_recordTypes__title,
.Admin .Admin-content .ecogy-form #root_modules_econode_finance_irrControls #root_modules_ams_finance_irrControls__title,
.Admin .Admin-content .ecogy-form #root_modules_econode_finance_irrControls #root_modules_econode_finance_irrControls__title,
.Admin .Admin-content .ecogy-form #root_modules_econode_finance_irrControls #root_modules_ams_data_recordTypes__title,
.Admin .Admin-content .ecogy-form #root_modules_ams_data_recordTypes #root_modules_ams_finance_irrControls__title,
.Admin .Admin-content .ecogy-form #root_modules_ams_data_recordTypes #root_modules_econode_finance_irrControls__title,
.Admin .Admin-content .ecogy-form #root_modules_ams_data_recordTypes #root_modules_ams_data_recordTypes__title,
.Admin .Admin-content .ecogy-form #root_modules_ams_process_checklistControls #root_modules_ams_finance_irrControls__title,
.Admin .Admin-content .ecogy-form #root_modules_ams_process_checklistControls #root_modules_econode_finance_irrControls__title,
.Admin .Admin-content .ecogy-form #root_modules_ams_process_checklistControls #root_modules_ams_data_recordTypes__title {
  font-weight: bold;
  font-size: large;
}
.Admin .Admin-content .ecogy-form #root_modules_ams_finance_irrControls #root_modules_ams_finance_irrControls__title #root_modules_ams_data_recordTypes__title,
.Admin .Admin-content .ecogy-form #root_modules_ams_finance_irrControls #root_modules_ams_finance_irrControls__title #root_modules_ams_process_checklistControls__title,
.Admin .Admin-content .ecogy-form #root_modules_ams_finance_irrControls #root_modules_econode_finance_irrControls__title #root_modules_ams_data_recordTypes__title,
.Admin .Admin-content .ecogy-form #root_modules_ams_finance_irrControls #root_modules_econode_finance_irrControls__title #root_modules_ams_process_checklistControls__title,
.Admin .Admin-content .ecogy-form #root_modules_ams_finance_irrControls #root_modules_ams_data_recordTypes__title #root_modules_ams_data_recordTypes__title,
.Admin .Admin-content .ecogy-form #root_modules_ams_finance_irrControls #root_modules_ams_data_recordTypes__title #root_modules_ams_process_checklistControls__title,
.Admin .Admin-content .ecogy-form #root_modules_econode_finance_irrControls #root_modules_ams_finance_irrControls__title #root_modules_ams_data_recordTypes__title,
.Admin .Admin-content .ecogy-form #root_modules_econode_finance_irrControls #root_modules_ams_finance_irrControls__title #root_modules_ams_process_checklistControls__title,
.Admin .Admin-content .ecogy-form #root_modules_econode_finance_irrControls #root_modules_econode_finance_irrControls__title #root_modules_ams_data_recordTypes__title,
.Admin .Admin-content .ecogy-form #root_modules_econode_finance_irrControls #root_modules_econode_finance_irrControls__title #root_modules_ams_process_checklistControls__title,
.Admin .Admin-content .ecogy-form #root_modules_econode_finance_irrControls #root_modules_ams_data_recordTypes__title #root_modules_ams_data_recordTypes__title,
.Admin .Admin-content .ecogy-form #root_modules_econode_finance_irrControls #root_modules_ams_data_recordTypes__title #root_modules_ams_process_checklistControls__title,
.Admin .Admin-content .ecogy-form #root_modules_ams_data_recordTypes #root_modules_ams_finance_irrControls__title #root_modules_ams_data_recordTypes__title,
.Admin .Admin-content .ecogy-form #root_modules_ams_data_recordTypes #root_modules_ams_finance_irrControls__title #root_modules_ams_process_checklistControls__title,
.Admin .Admin-content .ecogy-form #root_modules_ams_data_recordTypes #root_modules_econode_finance_irrControls__title #root_modules_ams_data_recordTypes__title,
.Admin .Admin-content .ecogy-form #root_modules_ams_data_recordTypes #root_modules_econode_finance_irrControls__title #root_modules_ams_process_checklistControls__title,
.Admin .Admin-content .ecogy-form #root_modules_ams_data_recordTypes #root_modules_ams_data_recordTypes__title #root_modules_ams_data_recordTypes__title,
.Admin .Admin-content .ecogy-form #root_modules_ams_data_recordTypes #root_modules_ams_data_recordTypes__title #root_modules_ams_process_checklistControls__title,
.Admin .Admin-content .ecogy-form #root_modules_ams_process_checklistControls #root_modules_ams_finance_irrControls__title #root_modules_ams_data_recordTypes__title,
.Admin .Admin-content .ecogy-form #root_modules_ams_process_checklistControls #root_modules_ams_finance_irrControls__title #root_modules_ams_process_checklistControls__title,
.Admin .Admin-content .ecogy-form #root_modules_ams_process_checklistControls #root_modules_econode_finance_irrControls__title #root_modules_ams_data_recordTypes__title,
.Admin .Admin-content .ecogy-form #root_modules_ams_process_checklistControls #root_modules_econode_finance_irrControls__title #root_modules_ams_process_checklistControls__title,
.Admin .Admin-content .ecogy-form #root_modules_ams_process_checklistControls #root_modules_ams_data_recordTypes__title #root_modules_ams_data_recordTypes__title,
.Admin .Admin-content .ecogy-form #root_modules_ams_process_checklistControls #root_modules_ams_data_recordTypes__title #root_modules_ams_process_checklistControls__title {
  font-size: 1.25rem;
  margin: 5px 0 0 -12px;
}
.Admin .Admin-content .ecogy-form #root_modules_ams_finance_irrControls #root_modules_ams_finance_irrControls__description,
.Admin .Admin-content .ecogy-form #root_modules_ams_finance_irrControls #root_modules_econode_finance_irrControls__description,
.Admin .Admin-content .ecogy-form #root_modules_ams_finance_irrControls #root_modules_ams_data_recordTypes__description #root_modules_ams_process_checklistControls__description,
.Admin .Admin-content .ecogy-form #root_modules_econode_finance_irrControls #root_modules_ams_finance_irrControls__description,
.Admin .Admin-content .ecogy-form #root_modules_econode_finance_irrControls #root_modules_econode_finance_irrControls__description,
.Admin .Admin-content .ecogy-form #root_modules_econode_finance_irrControls #root_modules_ams_data_recordTypes__description #root_modules_ams_process_checklistControls__description,
.Admin .Admin-content .ecogy-form #root_modules_ams_data_recordTypes #root_modules_ams_finance_irrControls__description,
.Admin .Admin-content .ecogy-form #root_modules_ams_data_recordTypes #root_modules_econode_finance_irrControls__description,
.Admin .Admin-content .ecogy-form #root_modules_ams_data_recordTypes #root_modules_ams_data_recordTypes__description #root_modules_ams_process_checklistControls__description,
.Admin .Admin-content .ecogy-form #root_modules_ams_process_checklistControls #root_modules_ams_finance_irrControls__description,
.Admin .Admin-content .ecogy-form #root_modules_ams_process_checklistControls #root_modules_econode_finance_irrControls__description,
.Admin .Admin-content .ecogy-form #root_modules_ams_process_checklistControls #root_modules_ams_data_recordTypes__description #root_modules_ams_process_checklistControls__description {
  font-size: 0.9rem;
  margin: 0 0 10px -10px;
}
.Admin .Admin-content .ecogy-form #root_modules_ams__title, .Admin .Admin-content .ecogy-form #root_modules_econode__title, .Admin .Admin-content .ecogy-form #root_modules__title {
  margin-top: 25px;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: xx-large;
}
.Admin .Admin-content .ecogy-form #root_modules_ams_event__title, .Admin .Admin-content .ecogy-form #root_modules_ams_connectivity__title,
.Admin .Admin-content .ecogy-form #root_modules_ams_energy__title, .Admin .Admin-content .ecogy-form #root_modules_ams_demandresponse__title,
.Admin .Admin-content .ecogy-form #root_modules_ams_finance__title, .Admin .Admin-content .ecogy-form #root_modules_ams_data__title, .Admin .Admin-content .ecogy-form #root_modules_ams_process__title,
.Admin .Admin-content .ecogy-form #root_modules_econode_event__title, .Admin .Admin-content .ecogy-form #root_modules_econode_energy__title, .Admin .Admin-content .ecogy-form #root_modules_econode_finance__title {
  margin-top: 25px;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: x-large;
}
.Admin .Admin-content .ecogy-form #root_modules__description, .Admin .Admin-content .ecogy-form #root_modules_ams__description, .Admin .Admin-content .ecogy-form #root_modules_econode__description {
  font-size: medium;
  margin-left: 10px;
}
.Admin .Admin-content .ecogy-form .ecogy-form__default-permissions__info {
  margin-top: 0;
  white-space: pre-wrap;
}
.Admin .Admin-content .ecogy-form .ecogy-form__default-permissions__info i {
  top: 27px;
  scale: 1.5;
}
.Admin .Admin-content .ecogy-form .field-array {
  margin-left: 10px;
}
.Admin .Admin-content .ecogy-form-buttons {
  width: 100%;
}
.Admin .Admin-content .header-id {
  font-size: small;
  color: #aaaeb3;
  padding-left: 10px;
}
.Admin .Admin-content .close-button {
  top: 0;
  font-size: 48px;
}
.Admin .Admin-content table {
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
  margin-bottom: 20px;
}
.Admin .Admin-content table thead.sticky th {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #fff;
}
.Admin .Admin-content table th {
  color: #4bb3d8;
  text-align: left;
}
.Admin .Admin-content table th.ranged {
  color: #fff;
  background-color: #4bb3d8;
}
.Admin .Admin-content table td {
  border: solid 1px #272e34;
  color: #fff;
}
.Admin .Admin-content table td a {
  color: #fff;
}
.Admin .Admin-content table td .btn-link {
  padding: 0;
  padding-left: 5px;
}
.Admin .Admin-content table td .btn-link .material-icons {
  color: #4bb3d8;
}
.Admin .Admin-content table button.user-button {
  width: 100%;
  text-align: left;
  padding: 0 5px;
}
.Admin .Admin-content table td.active {
  background-color: #4bb3d8;
}
.Admin .Admin-content table td.active button.user-button {
  background-color: #4bb3d8;
}
.Admin .Admin-content .audit-log {
  overflow-y: auto;
  background-color: #353c44;
}
.Admin .Admin-content .audit-log th {
  top: 0;
  position: sticky;
  z-index: 1;
  background-color: #353c44;
  box-shadow: 0 4px 2px -2px #272e34;
}
.Admin .Admin-content .audit-log th:first-child,
.Admin .Admin-content .audit-log td:first-child {
  padding-left: 5px;
}
.Admin .Admin-content .audit-log th:last-child {
  text-align: center;
}
.Admin .Admin-content .audit-log td {
  border: solid 2px #272e34;
}
.Admin .Admin-content .audit-log .audit-changes {
  width: 50%;
}
.Admin .Admin-content .audit-diff {
  table-layout: fixed;
  overflow-wrap: break-word;
  margin-bottom: 0;
}
.Admin .Admin-content .audit-diff th {
  font-weight: normal;
  text-align: center;
}
.Admin .Admin-content .audit-diff td {
  border-left: none;
  border-bottom: none;
  padding: 0 0.25rem;
}
.Admin .Admin-content .audit-diff pre {
  white-space: pre-wrap;
}
.Admin .Admin-content .audit-diff .original {
  width: 40%;
  color: #e22006;
  font-size: 1rem;
}
.Admin .Admin-content .audit-diff .new {
  width: 40%;
  color: #4caf50;
  font-size: 1rem;
}
.Admin .Admin-content .user-table th.user-name {
  width: 25%;
}
.Admin .Admin-content .user-table td.user-name {
  padding-right: 20px;
}
.Admin .Admin-content .user-registration-expired {
  color: #e22006;
}
.Admin .Admin-content .card {
  color: #fff;
  background-color: #272e34;
}
.Admin .Admin-content .admin-side-panel {
  margin-left: -15px;
  margin-right: 0;
  padding-right: 0;
  margin-bottom: 60px;
}
.Admin .Admin-content .admin-side-panel .admin-side-panel-content {
  background-color: #4bb3d8;
  color: #fff;
  border-radius: 10px;
  padding: 20px;
  height: 100%;
}
.Admin .Admin-content .admin-side-panel .admin-side-panel-content table {
  width: 100%;
}
.Admin .Admin-content .admin-side-panel .admin-side-panel-content a {
  color: #fff;
}
.Admin .Admin-content .admin-side-panel .admin-side-panel-content > div {
  padding-bottom: 20px;
}
.Admin .Admin-content .admin-side-panel .admin-side-panel-content .download {
  border-color: #fff;
  background-color: #fff;
  color: #4bb3d8;
  margin-bottom: 2px;
  width: 100%;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.Admin .Admin-content .admin-side-panel .admin-side-panel-content .download .material-icons {
  color: #4bb3d8;
}
.Admin .Admin-content .admin-side-panel .admin-side-panel-content .missing {
  color: #e22006;
}
.Admin .Admin-content .admin-side-panel .admin-side-panel-content .payments {
  width: 100%;
}
.Admin .Admin-content .admin-side-panel .admin-side-panel-content .payments .past {
  color: #dae0e7;
}
.Admin .Admin-content .date-range-menu {
  width: auto;
  height: auto;
}
.Admin .Admin-content .date-range-menu .custom-date-range {
  width: 325px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Admin .Admin-content .date-range-menu .custom-date-range .custom-date {
  width: 100%;
  height: 275px;
  padding-right: 5px;
}
.Admin .Admin-content .date-range-menu .custom-date-range .custom-date th {
  text-align: center;
}
.Admin .Admin-content .date-range-menu .custom-date-range .custom-date .rdt .rdtPicker {
  width: 100%;
}
.Admin .Admin-content .report .document {
  min-height: 20px;
  line-height: 42.5px;
}
.Admin .Admin-content .admin-content-area {
  height: calc(100vh - 60px - 56px);
  overflow: auto;
}
.Admin .Admin-content .admin-tabs .new-button {
  background-color: #4bb3d8;
  border-color: #4bb3d8;
  float: right;
}
.Admin .Admin-content .admin-tabs .nav-tabs {
  border-bottom: 1px solid #1f1f1f;
  margin-bottom: 20px;
}
.Admin .Admin-content .admin-tabs .nav-tabs .nav-item {
  cursor: pointer;
}
.Admin .Admin-content .admin-tabs .nav-tabs .nav-item a {
  color: #fff;
  background-color: #272e34;
  border: 1px solid #1f1f1f;
}
.Admin .Admin-content .admin-tabs .nav-tabs .nav-item a.active {
  background-color: #1f1f1f;
  border: none;
}
.Admin .Admin-content .admin-tabs .nav-tabs .new a {
  color: #fff;
  background-color: #4bb3d8;
}
.Admin .Admin-content .admin-tabs .nav-tabs .new a.active {
  color: #4bb3d8;
  background-color: #fff;
}
.Admin .Admin-content .admin-tabs .new-card {
  color: #fff;
  background-color: #4bb3d8;
}
.Admin .Admin-content .admin-tabs .new-card .loading {
  background-color: #fff;
}
.Admin .Admin-content .admin-tabs .new-card .icon-button {
  border-color: #fff;
}
.Admin .Admin-content .admin-tabs .new-card .icon-button .material-icons {
  color: #fff;
}
.Admin .Admin-content .admin-tabs .new-card .icon-button.btn-danger {
  border-color: #e22006;
}
.Admin .Admin-content .admin-tabs .new-card .icon-button.btn-danger .material-icons {
  color: #e22006;
}
.Admin .Admin-content .admin-tabs .address-line {
  display: block;
}
.Admin .Admin-content .admin-tabs .admin-tab__conflict-warning {
  padding-left: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Admin .Admin-content .admin-tabs .admin-tab__conflict-warning .warning-wrapper {
  display: flex;
  align-items: center;
}
.Admin .Admin-content .admin-tabs .admin-tab__conflict-warning i {
  color: #ff8042;
  position: absolute;
  top: 10px;
  left: 10px;
  margin-top: 0.5rem;
}
.Admin .Admin-content .admin-tabs .admin-tab-content-area {
  height: calc(100vh - 60px - 56px - 100px);
  overflow-x: hidden;
  overflow-y: auto;
}
.Admin .Admin-content .admin-tabs .admin-tab-content-area hr {
  margin-top: 0;
}
.Admin .Admin-content .admin-tabs .admin-tab-content-area .info {
  margin-top: 0;
  margin-bottom: 1rem;
}
.Admin .Admin-content .admin-tabs .admin-tab-content-area .info i {
  margin-top: 0.5rem;
}
.Admin .Admin-content .admin-tabs .field-group .card {
  padding: 20px;
}
.Admin .Admin-content .admin-tabs .field-group .field-group-title {
  font-weight: bold;
}
.Admin .Admin-content .admin-tabs .field-group .field-group-toggle {
  text-align: right;
  float: right;
}
.Admin .Admin-content .admin-tabs .card {
  margin-bottom: 10px;
}
.Admin .Admin-content .admin-tabs .project-finance-category {
  margin-bottom: 20px;
}
.Admin .Admin-content .user-groups {
  margin-bottom: 20px;
}
.Admin .Admin-content .user-groups .user-group-name {
  width: 200px;
}
.Admin .Admin-content .user-groups h4 {
  font-weight: 500;
  margin-right: 5px;
  text-transform: capitalize;
  font-size: 1.5rem;
  display: inline;
  vertical-align: middle;
}
.Admin .loading {
  text-align: center;
  margin-top: 200px;
  color: #4bb3d8;
  font-size: xx-large;
}
.Admin .Admin-error {
  text-align: center;
  margin-top: 200px;
  color: #e22006;
  font-size: xx-large;
}
.Admin .Admin__parameter-store {
  height: calc(100vh - 56px);
  padding: 0 1rem;
  padding-top: 1rem;
  overflow-y: scroll;
}
.Admin .Admin__parameter-store .Admin__parameter-store__param-container .Admin__parameter-store__param-fields {
  width: 100%;
  display: grid;
  gap: 1rem;
  margin-top: 20px;
}
.Admin .Admin__parameter-store .Admin__parameter-store__param-container .Admin__parameter-store__param-fields .Admin__parameter-store__param-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 0.5rem;
}
.Admin .Admin__parameter-store .Admin__parameter-store__param-container .Admin__parameter-store__param-fields .Admin__parameter-store__param-field input:read-only {
  background-color: #272e34;
  color: #fff;
}
.Admin .Admin__parameter-store .Admin__parameter-store__param-container .Admin__parameter-store__param-fields .Admin__parameter-store__param-field span {
  position: absolute;
  font-size: 1rem;
  top: -20px;
  left: 5px;
}
.Admin .Admin__parameter-store .Admin__parameter-store__param-container .Admin__parameter-store__param-fields .Admin__parameter-store__param-field li {
  color: #e22006;
  margin-left: 1rem;
}
.Admin .Admin__parameter-store .Admin__parameter-store__param-container .Admin__parameter-store__param-controls {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.Admin .Admin__parameter-store .Admin__parameter-store__param-container .Admin__parameter-store__param-controls span {
  font-size: 1rem;
  padding-left: 1rem;
}

