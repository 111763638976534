@import "../../../common/shared";

.download-modal {
  .customRange {
    width: 100%;

    label {
      display: block;
    }

    .btn-group {
      width: 100%;
    }

    button {
      padding-top: 10px;
      padding-bottom: 10px;
      border-color: $ecogy-color-controls;
    }

    .custom-range-name {
      // width: 100%;
      > * {
        float: none;
      }

      .range {
        // height: 27px;
        .range-start {
          display: inline;
          font-size: medium;
        }

        .range-end {
          display: inline;
          font-size: medium;
        }

        .range-divider {
          display: inline;
        }
      }
    }
  }
}

.EnergyView {
  height: 100%;

  h5 {
    text-align: center;
    background-color: $ecogy-darkgrey;
    padding: 5px;
  }

  .cumulative-graph-controls {
    position: relative;

    .inline-graph-controls {
      position: absolute;
      right: 20px;
      top: -50px;

      // top: calc(#{$height-header} + #{$height-content-header} + 10px);
      clear: both;

      > * {
        float: left;
        width: auto;
        vertical-align: center;
      }

      label {
        padding: 5px;
      }
    }
  }

  .content-main {
    .projects-table {
      td.projects-table__averages {
        font-weight: bold;
        text-align: right;
        color: $ecogy-white;
        background-color: $ecogy-color-info;
      }
    }
  }

  .content-view-controls {
    .ok {
      background-color: $ecogy-status-green;
    }

    .warning {
      background-color: $ecogy-status-orange;
    }

    .error {
      background-color: $ecogy-status-red;
    }
  }

  .project-details {
    h3 {
      text-align: center;
    }

    .project-details-info {
      font-size: large;
      padding: 10px 0;

      label {
        color: $ecogy-color-info;
      }
    }

    .project-details-info > :last-child {
      float: none;
      clear: both;
    }
  }

  .details-info {
    padding-top: 5px;

    label {
      color: $ecogy-color-info;
      vertical-align: top;
    }
  }

  .site-content th {
    background-color: $ecogy-darkgrey;
  }

  .asset-info {
    float: left;

    label {
      width: 120px;
    }

    .size-warning {
      padding: 0 5px 10px;
      transform: scale(1.3);
      color: orange;
      cursor: default;
    }

    .warning-info {
      float: right;
      margin-right: 10px;

      .material-icons {
        color: $ecogy-yellow;
      }
    }
  }

  .links {
    clear: both;

    ul {
      display: inline-block;
      list-style: none;
      padding: 0;
    }
  }

  .generation {
    width: 50%;
  }

  .consumption {
    width: 50%;
  }

  .size {
    width: 25%;
  }

  .address {
    label {
      width: 120px;
    }
  }

  .accessNotes {
    label {
      width: 120px;
    }
  }

  .source-connect {
    min-width: 170px;
  }
}

.source-details-tooltip {
  background-color: $ecogy-black;

  td {
    vertical-align: top;
    padding-left: 5px;
  }

  .meta-data {
    min-width: 100px;
  }
}

.menu-error {
  color: $ecogy-status-red;
}

.menu-warning {
  color: $ecogy-status-orange;
}

.tooltip {
  pointer-events: none; // EP-1606
}

.tooltip-error {
  .tooltip-inner {
    color: $ecogy-white;
    background-color: $ecogy-status-red;
    max-width: 400px;
  }
}

.tooltip-warning {
  .tooltip-inner {
    color: $ecogy-white;
    background-color: $ecogy-status-orange;
    max-width: 400px;
  }
}

p.tooltip-detail {
  margin: 10px 0 0;
}

.text-error {
  color: $ecogy-status-red;
}

.text-warning {
  color: $ecogy-status-orange;
}

.text-ok {
  color: $ecogy-status-green;
}

.timeseries-legend {
  float: right;

  li {
    float: left;
    width: auto;
    padding-right: 30px;
  }
}

.timeseries-counter {
  position: relative;
  text-align: center;

  .timeseries-buttons {
    position: absolute;
    right: 5px;
    top: 5px;
  }
}

.node-photo {
  display: flex;
  justify-content: center;

  .close {
    position: fixed;
    top: 0;
    right: 0;
  }
}

.node-photo-thumb {
  width: 25%;
  float: left;
}
