@import "../../../../../../common/shared";

.loading-spinner {
  border-width: 0.2rem;
  vertical-align: 0;
  margin-right: 0.5rem;
}

.popover__styles {
  .popover {
    min-width: 600px;
  }

  .table {
    margin-bottom: 0;

    .table__row-header {
      border-bottom: 1px solid white;
    }

    .gray-400 {
      background-color: #444;
    }

    .align-right {
      text-align: right;
    }

    .notes-pointer {
      cursor: help;
    }

    .capitalize {
      text-transform: capitalize;
    }

    .hoverable {
      padding-bottom: 0.25rem;
      padding-left: 0;
      cursor: pointer;

      &:hover {
        user-select: none;
        opacity: 0.7;
      }
    }
  }

  .popover__inner-table {
    margin-bottom: 0;

    td {
      background-color: #444;
      border-bottom: 1px solid #212529;
    }

    .warning {
      margin-right: 0.25rem;
      color: $ecogy-status-orange;
    }
  }
}

.notes_popover {
  .popover {
    min-width: 300px;
  }
}
